<script lang="ts">
  import { getTheme } from "@okcontract/uic";
  import { navigate } from "@scv/routing";

  export let link: string = undefined;
  export let size: "xs" | "sm" | "md" = "md";
  export let style: "default" | "inline" | "tile" = "inline";

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const click_if_link = (node: HTMLElement) => {
    if (link) {
      const nav = () => navigate({ to: link });
      node.addEventListener("click", nav);
      return {
        destroy() {
          node.removeEventListener("click", nav);
        }
      };
    }
  };

  const sizes = {
    xs: "p-1 px-2",
    sm: "py-2 px-3",
    md: "py-3 px-4"
  };
  const styles = {
    default: "flex rounded-box",
    inline: "stat",
    tile: "flex bg-base-200 rounded-box"
  };
</script>

<li
  class="{sizes[size]} {styles[style]} cursor-pointer
    {theme.dark(
    $compiledTheme,
    'bg-white-alpha',
    'bg-black-alpha',
    'bg-base-100'
  )}"
  use:click_if_link
>
  <slot />
</li>
