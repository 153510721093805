<script lang="ts">
  import type { TokenQueryType } from "@okcontract/coredata";

  import { newOKPage } from "./core";

  export let address: TokenQueryType | `0x${string}`;

  const instance = newOKPage();
  const proxy = instance.proxy;

  const balance = instance._formatBalance(
    // @ts-expect-error walletIDType
    instance.core.WalletID,
    instance.core.CurrentChain,
    proxy.new(address)
  );
</script>

{#if !($balance instanceof Error)}
  {$balance}
{/if}
