// @todo move to new file depending on Network
import { type Abi, type GetContractReturnType, parseAbi } from "viem";

import type {
  AnyCell,
  MapCell,
  SheetProxy,
  ValueCell
} from "@okcontract/cells";
import {
  type ABI,
  type ABIExtra,
  type AnonContractQueryType,
  type ContractQueryType,
  type NFTQueryType,
  type OKToken,
  type SmartContract,
  type TokenQueryType,
  is_anon_contract
} from "@okcontract/coredata";
import {
  type Environment,
  type ValueDefinition,
  newTypeScheme,
  newTypeVar,
  typeAny
} from "@okcontract/lambdascript";
import type {
  ChainAddress,
  ChainType,
  LocalRPCSubscriber,
  Network
} from "@okcontract/multichain";

import { abiFunctionToMonoType, abiParameterToMonoType } from "./abi.convert";
import { getABIXFromRefs } from "./abix";
import type { OKCore } from "./coreExecution";
import { ERC20, abix_erc20 } from "./erc20";
import { ERC721, abix_erc721 } from "./erc721";
import type { OKPage } from "./instance";
import { EnvKeyABI, EnvKeyOKPage } from "./keys";
import { type PivotFunction, type PivotView, getPivotView } from "./pivot";
import {
  type ContractFunctionValues,
  extractFunctionName,
  splitABI
} from "./vrw";

export type ViemContract = GetContractReturnType<
  Abi | unknown[],
  { public?: never; wallet: never },
  `0x${string}`,
  string
> & {
  read: { [key: string]: (...args: unknown[]) => unknown };
};

export type OKContractInitArgs = {
  addr: ChainAddress;
  dcToken?: OKToken;
  dcContract?: SmartContract;
};

// @todo move AnyContract to coredata?
export type AnyContractQuery =
  | ContractQueryType
  | TokenQueryType
  | NFTQueryType
  | AnonContractQueryType<string>;

export type DataOfContractQuery<Query extends AnyContractQuery> =
  Query extends ContractQueryType
    ? SmartContract
    : Query extends TokenQueryType
      ? OKToken
      : Query extends NFTQueryType
        ? OKToken
        : SmartContract; // @todo Anon type var? SmartContract<true>

export class OKContract<Query extends AnyContractQuery> {
  private _instance: OKPage;
  private _proxy: SheetProxy;
  private _localRPC: LocalRPCSubscriber;
  private _core: OKCore;

  readonly query: AnyCell<Query>;
  readonly address: AnyCell<ChainAddress>;
  readonly wantedChain: AnyCell<ChainType>;
  readonly chain: AnyCell<ChainType>;
  readonly chains: AnyCell<Map<ChainType, ChainAddress<"evm">>>;
  readonly data: AnyCell<DataOfContractQuery<Query>>;
  readonly isAnon: MapCell<boolean, true>;
  readonly abi: AnyCell<ABI>;
  readonly parsedAbi: AnyCell<Abi>;
  readonly abix: AnyCell<ABIExtra> | undefined;
  readonly VRW: AnyCell<ContractFunctionValues>;
  // env is the full merge of contracts env
  readonly env: AnyCell<Environment>;
  readonly pivot: AnyCell<PivotView>;
  readonly P: AnyCell<PivotFunction[]>;
  readonly pEnv: AnyCell<ValueDefinition[]>;
  readonly values: AnyCell<ValueDefinition[]>;
  readonly pArgs: ValueCell<unknown>;

  constructor(
    instance: OKPage,
    q: AnyCell<Query>,
    wantedChain: AnyCell<ChainType>,
    options: {
      dcToken?: AnyCell<OKToken>;
      dcContract?: AnyCell<SmartContract>;
      abi?: Abi;
      // @todo implement to minimize computations
      restrictVRW?: string[];
    } = {}
  ) {
    // console.log(`NEW OKContract: ${q?.value}`);
    this._instance = instance;
    this._localRPC = instance.rpc;
    this.wantedChain = wantedChain;
    this._proxy = instance.proxy;
    this._core = instance.core;

    this.query = q;
    this.data = instance.local.unwrappedCell(q, "okC.data"); // as AnyCell<SmartContract>;
    this.isAnon = this._proxy.map(
      [this.data],
      (data) => is_anon_contract(data),
      "okC.isAnon",
      true
    );
    // There can't be two addresses for the same chain for a given contract/token.
    this.chains = this._proxy.map(
      [this.data],
      (data) =>
        data === null
          ? new Map()
          : data.addr.reduce(
              (acc, addr) => acc.set(addr.chain as ChainType, addr),
              new Map() as Map<ChainType, ChainAddress>
            ),
      "okC.chains"
    );
    this.chain = this._proxy.map(
      [wantedChain, this.chains],
      (w, ch) => (ch.get(w) !== undefined ? w : ch.keys().next().value || null) // undefined if Map is empty
    );
    // @todo rename addr
    this.address = this._proxy.map(
      [this.data, this.chain],
      (data, chain) => data?.addr?.find((addr) => addr.chain === chain) || null
    );
    this.abi = instance._getABI(this.address, options?.abi);
    this.parsedAbi = this.abi.map(
      (abi) => abi?.parsed || null,
      "okC._parsedAbi"
    );
    this.abix = this._proxy.map(
      [this.abi, this.data],
      (abi, data) =>
        abi === null
          ? null
          : data && "xr" in data && data?.xr
            ? getABIXFromRefs(this._core, data.xr)
            : abi?.erc
              ? abi.erc.includes(ERC721)
                ? abix_erc721(abi)
                : abi.erc.includes(ERC20)
                  ? abix_erc20(abi)
                  : {
                      id: "@null",
                      name: "empty ABIX"
                    }
              : null,
      "okC.abix"
    );

    this.pivot = getPivotView(
      this._localRPC,
      this.address,
      this.parsedAbi,
      this.abix.map((abix) => abix?.pivot || null, "okC.pivot.p")
    );
    this.P = this.pivot.map(
      (pivot) =>
        pivot?.funcs?.filter(
          (pf) =>
            pf?.fn?.stateMutability === "view" &&
            pf.pos !== undefined &&
            pf.rem.length === 0
        ) || null,
      "okC.P"
    );
    this.pArgs = this._proxy.new(0, "okC.pArgs");
    const pivotFunctions = this.P.map((P) => {
      if (!P) return null;
      const fns: string[] = [];
      for (const fn of P) {
        if (!fn?.name) continue;
        fns.push(fn.name);
      }
      return fns;
    }, "computePivotEnv.pivotFunctions");

    // builds pivot env with pivot name and data
    const pArgsList = this.pArgs.map((_pArg) => [this.pArgs]);
    this.pEnv = this._proxy.map(
      [pivotFunctions],
      async (fns) =>
        (fns || []).map((fn, i) => {
          const fnCell = this._proxy.new(fn, `pivotFunction.${i}`);
          const res = this._localRPC.call(
            this.address,
            this.parsedAbi,
            fnCell,
            pArgsList
          );
          const converted = res.map((output) => {
            if (output instanceof Error) throw output;
            if (Array.isArray(output))
              return Object.fromEntries(output.map((out, i) => [i, out]));
            return { 0: res };
          });
          return [
            extractFunctionName(fn),
            converted,
            newTypeScheme(newTypeVar("i"), ["i"])
          ] as ValueDefinition;
        }),
      "computePivotEnv.pivots"
    );

    // Values

    const applyPivotEnv = (
      VRW: ContractFunctionValues,
      pEnv: ValueDefinition[]
    ): ContractFunctionValues => {
      if (!pEnv) return VRW;
      const pivotsKeys = pEnv.map(([k, _v_, _t]) => k);
      // remove from readers function already computed pivot keys
      const R = VRW?.[1].filter((fn) => !pivotsKeys.includes(fn.name));
      return [VRW?.[0], R, VRW?.[2]];
    };

    this.VRW = this._proxy.map(
      [this.abi, this.pEnv],
      (abi, pEnv) =>
        !abi?.parsed ? null : applyPivotEnv(splitABI(abi.parsed), pEnv),
      "okC.VRW"
    );

    // Environments

    const emptyList = this._proxy.new([], "emptyList");
    this.values = this.VRW.map(
      (vrw) =>
        (vrw?.[0] || []).map((fn) => {
          const name = fn?.name;
          // @todo we should take RPC validity in account and have cells that
          // are reactive to onchain events, etc.
          const value = this._localRPC.call(
            this.address,
            this.parsedAbi,
            this._proxy.new(name),
            emptyList
          );
          // @todo fn.outputs[0]...
          const type = abiParameterToMonoType(fn.outputs[0]);
          return [name, value, newTypeScheme(type)] as ValueDefinition;
        }),
      "createEnv.values"
    );

    const readers = this.VRW.map((vrw) =>
      (vrw?.[1] || []).map((fn) => {
        const name = fn?.name;
        const reader = (env: Environment, ...args: AnyCell<unknown>[]) => {
          // console.log("reader-call", { name, args, addr, parsedAbi });
          const instance = env.value(EnvKeyOKPage) as unknown as OKPage;
          const proxy = instance.proxy;
          const call = this._localRPC.call<AnyCell<unknown>[], Network>(
            this.address,
            this.parsedAbi,
            proxy.new(name, `readers:${name}`),
            // @todo should we change the args format in call
            proxy.new(args, `args:${name}`)
          );
          // wrap multiple return values in Object
          if (fn.outputs.length > 1)
            return call.map(
              (arr: unknown[]) =>
                Object.fromEntries(
                  arr.map((v, i) => [fn.outputs[i]?.name || `${i}`, v])
                ),
              "wrappedValues"
            );
          return call;
        };
        const type = abiFunctionToMonoType(fn);
        // console.log("readers", { name, type });
        return [
          name,
          this._proxy.new(reader),
          newTypeScheme(type)
        ] as ValueDefinition;
      })
    );

    this.env = this._proxy.map(
      [this.values, readers, this.pEnv],
      (_values, _readers, _pEnv) => {
        const env = this._instance.newEnvironment({ id: "okC" });
        return env.withValueTypes(..._values, ..._readers, ..._pEnv, [
          EnvKeyABI,
          this.abi,
          newTypeScheme(typeAny)
        ]);
      },
      "okC.env"
    );
  }

  // // @deprecated
  // public isErc = (proxy: SheetProxy, erc: ERCType) =>
  //   proxy.map([this.abi], (_abi) => _abi?.erc?.includes(erc), "isErc");

  /**
   * Prints the ABI as string.
   */
  public ABIString = async () => {
    const abi = await this.abi.get();
    if (abi instanceof Error) return null;
    if (abi?.abi) return abi.abi;
    if (abi?.human) return parseAbi(abi.human).toString();
    return "ABI Unknown";
  };

  public error = async () => {
    const abi = await this.abi.get();
    if (abi instanceof Error) return null;
    return abi?.err;
  };
}

let MANAGER_COUNT = 0;

/**
 * The OKContractManager class is responsible for managing and caching
 * instances of OKContract objects.
 * It provides a method to retrieve or create an OKContract instance
 * based on the provided ChainAddress and optional contract or token data.
 */
export class OKContractManager {
  private _instance: OKPage;
  // @todo single chain for the whole manager?
  private _cache: { [key: string]: OKContract<AnyContractQuery> } = {};
  private _id: number;
  private _count: ValueCell<number>;
  readonly stats: AnyCell<string>;
  readonly chain: AnyCell<ChainType>;

  constructor(
    instance: OKPage
    // chain: AnyCell<ChainType> = instance.proxy.new(
    //   instance.core.CurrentChain.get()
    // )
  ) {
    this._id = MANAGER_COUNT++;
    this._instance = instance;
    this._count = instance.proxy.new(0, `OKM.${this._id}:count`);
    this.stats = this._count.map(
      (n) => `${this._id}:${n}`,
      `OKM.${this._id}:stats`
    );
    this.chain = instance.wantedChain;
  }

  get<Q extends AnyContractQuery>(
    q: AnyCell<Q>,
    options: {
      abi?: Abi;
    } = {}
  ): AnyCell<OKContract<Q> | null> {
    return this._instance.proxy.map([q, this.chain], (_q, _ch) => {
      // Experimental: keep multiple okC for each chain?
      // We probably shouldn't but we should use memoize in the OKContract class
      // implementation. And also replace the manager by a call to memoize?
      const key = `${_ch}:${_q}`;
      // const key = _q;
      if (this._cache[key]) return this._cache[key];
      const okc = new OKContract(this._instance, q, this.chain, {
        // @todo do we still need this?
        abi: options?.abi
      });
      this._cache[key] = okc;
      this._count.update((v) => v + 1);
      return okc;
    });
  }
}
