import {
  type AccountInterface,
  type Provider,
  RpcProvider,
  type Signature,
  type TypedData,
  hash
} from "starknet";
// import { type StarknetWindowObject, connect, disconnect } from "starknetkit";
// import { InjectedConnector } from "starknetkit/injected";

import type { AnyCell, SheetProxy, ValueCell } from "@okcontract/cells";
import { Rational } from "@okcontract/lambdascript";
import {
  type Chain,
  type ChainType,
  type StarkNetType,
  Starknet,
  type StringAddress
} from "@okcontract/multichain";
import type { SignedMessage } from "@scv/auth";

import { type StarknetWindowObject, connect, disconnect } from "./starknet";
import { ARGENT, type OKConnector, type Transaction } from "./wallet";

type ArgentOptions = {
  debug: boolean;
};

// const defaultConnectors = {
//   connectors: [
//     new InjectedConnector({
//       options: { id: "argentX" }
//     }),
//     new InjectedConnector({
//       options: { id: "braavos" }
//     })
//   ]
// };

function starknetSignatureToHex(signatureArray) {
  if (signatureArray.length < 4) {
    throw new Error("Invalid signature format: expected at least 4 elements");
  }

  // Parse `r` and `s` values from the array (indices 2 and 3)
  const r = BigInt(signatureArray[2]); // r value
  const s = BigInt(signatureArray[3]); // s value

  // Convert `r` and `s` to hex (64 characters each, padded)
  const rHex = r.toString(16).padStart(64, "0");
  const sHex = s.toString(16).padStart(64, "0");

  // Concatenate r and s as a single hex string
  const signatureHex = rHex + sHex;

  return `0x${signatureHex}` as const;
}

export class ArgentConnector implements OKConnector<StarkNetType> {
  readonly ID = ARGENT;
  readonly Network = Starknet;
  readonly Account: AnyCell<StringAddress<StarkNetType>>;
  readonly ChainId: ValueCell<bigint | null>; // ValueCell is exported as AnyCell when using OKConnector
  readonly Chain: AnyCell<Chain>;
  readonly Chains: AnyCell<Record<ChainType, Chain>>;
  readonly Addresses: AnyCell<StringAddress<StarkNetType>[]>;
  readonly id = "argent";
  readonly name = "Argent";
  readonly ready = true;

  private _prov: Provider;
  private readonly _wallet: ValueCell<StarknetWindowObject>; // & { account?: AccountInterface }

  // @todo export filtered chains?
  constructor(proxy: SheetProxy, chains: AnyCell<Record<ChainType, Chain>>) {
    this.Chains = chains.map(
      (all) =>
        Object.fromEntries(
          Object.entries(all).filter(([key, ch]) => ch.net === Starknet)
        ),
      "argent:chains"
    );
    // logger(this._chains);
    this._wallet = proxy.new(null);
    this.Account = proxy.new(null, "ar:acc");
    // @todo proper implementation
    this.Addresses = this.Account.map((acc) => (acc ? [acc] : []));
    this.ChainId = proxy.new(null, "argent:ChainId");
    // logger(this.ChainId);
    this.Chain = proxy.map(
      [this.Chains, this.ChainId], // @todo this._chains
      (all, id) => {
        // console.log({ all, id });
        if (!id) return null;
        const rid = new Rational(id);
        return (
          Object.values(all).find((ch) =>
            (ch.numid as unknown as Rational).equals(rid)
          ) || null
        );
      },
      "argent:Chain"
    );
    // logger(this.Chain);
    // super({ chains, options });
    this._prov = new RpcProvider();
  }

  async connect() {
    const res = await connect(); // defaultConnectors);
    console.log({ res });
    this._wallet.set(res.wallet);
    // @todo on.disconnect()?
    // @ts-expect-error ValueCell
    this.Account.set(res.connectorData.account as `0x${string}`);
    this.ChainId.set(res.connectorData.chainId);
    return res.connectorData as { account: StringAddress<StarkNetType> };
  }

  async disconnect(): Promise<void> {
    await disconnect();
    // @ts-expect-error ValueCell
    this.Account.set(null);
    this.ChainId.set(null);
  }

  async signMessage(input: string): Promise<`0x${string}`> {
    const message = hash.starknetKeccak(input);
    const wallet = await this._wallet.get(); // StarknetWindowObject
    const chainId = await this.ChainId.get();
    const typedData: TypedData = {
      types: {
        StarkNetDomain: [
          { name: "name", type: "felt" },
          { name: "chainId", type: "felt" },
          { name: "version", type: "felt" }
        ],
        Message: [{ name: "content", type: "felt" }]
      },
      primaryType: "Message",
      domain: {
        name: "OKcontract",
        version: "1",
        chainId: `0x${chainId.toString(16)}`
      },
      // @todo long string messages don't work
      message: {
        content: `0x${message.toString(16)}` // .padStart(64, "0")
      }
    };
    console.log({ typedData });
    // @todo required API version?
    const apiVersion = {
      apiVersion: "v1.0"
    };
    try {
      const params = { ...typedData, ...apiVersion };
      console.log("params", params);
      // Call the wallet_signTypedData method
      const signature = (await wallet.request({
        type: "wallet_signTypedData",
        params
      })) as unknown as Signature;
      return starknetSignatureToHex(signature);
    } catch (error) {
      // Handle errors based on the documented error types
      if (error.code === "INVALID_REQUEST_PAYLOAD") {
        console.error("Invalid request payload:", error.message);
      } else if (error.code === "USER_REFUSED_OP") {
        console.error("User refused operation:", error.message);
      } else if (error.code === "API_VERSION_NOT_SUPPORTED") {
        console.error("API version not supported:", error.message);
      } else {
        console.error("Unknown error:", error.message);
      }
      throw error;
    }
  }

  // cf. https://github.com/argentlabs/starknetkit-example-dapp/blob/develop/src/app/starknetkitLatest/page.tsx
  async switchChain?(id: ChainType): Promise<ChainType> {
    const wallet = await this._wallet.get();
    if (!wallet) throw new Error("no wallet");
    const chains = await this.Chains.get();
    if (chains instanceof Error) throw chains;
    const wanted = Object.values(chains).find((ch) => ch.id === id);
    if (!wanted) throw new Error("chain not found");
    const bid = (wanted.numid as unknown as Rational).toBigInt();
    // @todo big to string identifier?
    const ok = await wallet.request({
      type: "wallet_switchStarknetChain",
      params: {
        chainId: "SN_DAPP_TEST"
      }
    });
    if (ok) return id;
  }

  async signedMessage(Msg: string): Promise<SignedMessage> {
    const Sig = await this.signMessage(Msg);
    return { Msg, Sig, N: this.Network };
  }

  async sendTransaction(tx: Transaction<StarkNetType>): Promise<`0x${string}`> {
    throw new Error("Method not implemented.");
  }

  // deployContract?(
  //   comp: CompiledContract,
  //   fn: AbiFunction,
  //   env: Environment
  // ): Promise<`0x${string}`> {
  //   throw new Error("Method not implemented.");
  // }

  addChain?(id: ChainType): Promise<ChainType> {
    throw new Error("Method not implemented.");
  }

  signTransaction?(tx: Transaction<StarkNetType>): Promise<`0x${string}`> {
    throw new Error("Method not implemented.");
  }

  // // @todo unused in okcontract?
  // // getWalletClient(): Promise<WalletClient> {}

  // isAuthorized(): Promise<boolean> {
  //   return delayed(false, 1);
  // }

  // // watchAsset(asset: {
  // //   address: string;
  // //   decimals?: number;
  // //   image?: string;
  // //   symbol: string;
  // // }): Promise<boolean> {
  // //   return delayed(false, 1);
  // // }

  // protected onAccountsChanged: (accounts: string[]) => void;
  // protected onChainChanged: (chainId: number | string) => void;
  // protected onDisconnect: () => void;

  // // toJSON(): string {
  // //   return "";
  // // }
}
