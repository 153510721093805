<script lang="ts">
  import type { ValueCell } from "@okcontract/cells";
  import { ContractQuery, type MethodQueryType } from "@okcontract/coredata";
  import { WalletAddress, newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";

  import Upvote from "./Upvote.svelte";

  export let instance = newOKPage("MethodSnippet");
  export let q: MethodQueryType; // "met:sushi/router_v2:swapExactETHforTokens"
  export let small: boolean = false;
  export let upvote: boolean = true;

  const local = instance.local;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const qCell: ValueCell<typeof q> = proxy.new(q, `MethodSnipper.qCell:${q}`);
  $: qCell.set(q);

  const cql = qCell.map((_qCell) => _qCell.split(":"), "MethodSnipper.cql");
  const cq = cql.map(
    (_cql) => (_cql.length >= 3 && ContractQuery(_cql[1])) || undefined,
    "MethodSnipper.cq"
  );
  const con = local.unwrappedCell(cq, "con");
</script>

{#if !($con instanceof Error) && $con}
  <div class="flex flex-row items-center justify-between">
    <div class="flex items-center">
      <div class="flex-none">
        <div class="flex flex-row items-center">
          <div
            class=" cursor-pointer text-base font-semibold
            {theme.dark(
              $compiledTheme,
              'text-white',
              'text-black',
              'text-base-content'
            )}"
          >
            <!--style={theme.apply($compiledTheme, [ThemeText])}-->
            {$con.name}
          </div>
          <div class="ml-3">
            <WalletAddress {instance} address={$con.addr} style={true} />
          </div>
        </div>

        <div
          class="leading-4 {theme.dark(
            $compiledTheme,
            'text-white',
            'text-black',
            'text-base-content'
          )}"
        >
          <!--style={theme.apply($compiledTheme, [ThemeText])}-->
          {cql && cql[2]}
        </div>
      </div>
    </div>
    {#if upvote}
      <Upvote {q} style="sm" action={["Pin", "Pinned", "pinned", "pin"]} />
    {/if}
  </div>
{/if}
{#if !small}
  <!-- <AbiFunction /> -->
  FIXME: Show the ABI method
{/if}
