<script lang="ts">
  import * as Config from "../../config.json";
  const DEV = Config.dev;

  import type { ValueCell } from "@okcontract/cells";
  import { ALL_CHAINS, type OKPage, type WalletDetail } from "@okcontract/sdk";
  import { WalletAddress } from "@okcontract/svelte-sdk";
  import { Button, Icon, InlineHelpText, getTheme } from "@okcontract/uic";
  import { errors } from "@scv/app";
  import { LogoMap } from "@scv/dcuic";
  import { truncated_addr } from "@scv/utils";

  export let instance: OKPage;
  export let account: WalletDetail;
  export let open: ValueCell<boolean>;
  export let small = false;
  export let closeOnSelect = false;
  export let roles: boolean;

  const core = instance.core;
  const wantedWalletAccount = core.WantedWalletAccount;
  // const auth = core.Auth;

  const addr = account.addr;
  const isVerified = account.isVerified;
  const anotherForeign = account.aF;
  // const role = account.role;
  const name = account.name;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const switchAddr = () => {
    if (closeOnSelect) open.set(false);
    core.WalletChange(account);
  };

  const addForeign = async () => {
    try {
      core.AddForeign(addr, { connectorID: account.conn });
    } catch (err) {
      errors.set(err);
    }
  };
</script>

<div class="stat p-3">
  <button
    class="flex items-center gap-2 justify-between {$addr.toString() ===
    $wantedWalletAccount
      ? '{roles ? "bg-base-200" : "font-semibold" }'
      : ''}"
    on:click={switchAddr}
  >
    <div class="flex items-center gap-2 justify-start text-sm">
      <div
        class="w-8 {theme.dark(
          $compiledTheme,
          'bg-white-alpha',
          'bg-black-alpha',
          'bg-base-200'
        )} rounded-full avatar"
      >
        <LogoMap name={account.conn} size="lg" />
      </div>
      {#if $addr instanceof Error}
        <InlineHelpText style="warning" icon="warning"
          >Wallet address error</InlineHelpText
        >
      {:else if $name}
        {$name}
      {:else if small}
        {truncated_addr($addr.toString(), 10)}
      {:else}
        <WalletAddress
          {instance}
          address={{ addr: $addr, chain: ALL_CHAINS, ty: "w" }}
          style={false}
          my={false}
          truncate={true}
        />
      {/if}
    </div>
    {#if roles}
      {#if $isVerified}
        <!-- <Icon name="shield-o" size="xs" fill="warning" /> -->
        <Icon name="shield-o" size="xs" fill="success" />
      {:else if $anotherForeign === true}
        <span title={"This account is associated to another OUI"}>
          <Button label="Reassign" size="xs" action={addForeign} />
        </span>
      {:else}
        <span title={"Add this account to your user profile"}>
          <Button label="Verify" size="xs" action={addForeign} />
        </span>
      {/if}
    {/if}
  </button>
</div>
