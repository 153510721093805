import {
  type AnyCell,
  type MapCell,
  type SheetProxy,
  type ValueCell,
  logger
} from "@okcontract/cells";

// @todo extends ValueCell<T> but requires super
// @todo SyncCells that reuse the channel for syncing multiple cells: Record<string, unknown> for {key:initialValue, ...}
export class SyncCell<T> {
  readonly cell: ValueCell<T>;
  readonly first: MapCell<boolean, true>;

  private readonly _channel: BroadcastChannel;
  private readonly _instanceId: number;
  private readonly _idSet: ValueCell<number[]>;
  private readonly _key: string;

  constructor(
    proxy: SheetProxy,
    id: string,
    key: string,
    initialValue: T,
    name?: string
  ) {
    this._instanceId = Date.now();
    this.cell = proxy.new(initialValue, name);
    this._key = key;

    // @todo use Immutable.js List (and add immutable List equality to isEqual)
    this._idSet = proxy.new([this._instanceId], "sync:set");
    logger(this._idSet);
    this.first = this._idSet.map(
      (set) => set.sort((a, b) => a - b)[0] === this._instanceId,
      "sync:ifi"
    );
    // Note: channel is never closed, but OKCore is instantiated only
    // once in the application.
    this._channel = new BroadcastChannel(id);

    this._channel.onmessage = (event) => {
      const { type, id, v } = event.data as {
        type: string;
        id: number;
        v: T;
      };
      switch (type) {
        case "open":
          this._idSet.update((set) => [...set, id]);
          this._channel.postMessage({ type: "ack", id: this._instanceId });
          break;
        case "ack":
          this._idSet.update((set) => (set.includes(id) ? set : [...set, id]));
          break;
        case "close":
          this._idSet.update((set) => set.filter((v) => v !== id));
          break;
        default:
          if (type === key)
            // e.g. "token"
            this.cell.set(v);
      }
    };
    // Announce this instance to other tabs
    this._channel.postMessage({ type: "open", id: this._instanceId });
    // When the tab closes, notify others
    window.addEventListener("beforeunload", () => {
      this._channel.postMessage({ type: "close", id: this._instanceId });
    });
  }

  /**
   * Updates the internal cell value and broadcasts the change to other instances.
   */
  set(
    v: T | Promise<T> | AnyCell<T> | Promise<AnyCell<T>>
  ): void | Promise<void> {
    console.log("userAuth", { v });
    this._channel.postMessage({ type: this._key, id: this._instanceId, v });
    return this.cell.set(v);
  }

  async setFirst(
    v: T | Promise<T> | AnyCell<T> | Promise<AnyCell<T>>
  ): Promise<void> {
    const ifi = await this.first.get();
    if (ifi) return this.set(v);
  }

  /**
   * Subscribe to another cell.
   * - We don't propagate errors.
   */
  async subscribeTo(cell: AnyCell<T>) {
    const ifi = await this.first.get();
    if (ifi) {
      cell.subscribe((v) => {
        if (!(v instanceof Error)) this.set(v);
      });
    }
  }

  /**
   * Retrieves the current value of the cell.
   */
  get(): Promise<T> {
    return this.cell.get();
  }
}
