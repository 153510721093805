import { EVMNetwork, Starknet, TON } from "@okcontract/multichain";

/**
 * ChannelType types used in organizations as "chans": ["Tw:id", "Gh:id"]
 *  Website   ChannelType = "Web" // URL
    Email     ChannelType = "Em"  // address
    Other     ChannelType = "O"   // URL
    Twitter   ChannelType = "Tw"  // id
    GitHub    ChannelType = "Gh"  // id
    Discord   ChannelType = "Dis" // URL
    Telegram  ChannelType = "Tg"  // id
    Reddit    ChannelType = "Rdt" // id
    Medium    ChannelType = "Med" // id
    Instagram ChannelType = "Ins" // id
    ENSAddress ChannelType = "ENS" // id
 */
export const allChannels = {
  Website: "Web",
  Twitter: "Tw",
  Discord: "Dis",
  Other: "O",
  GitHub: "Gh",
  Telegram: "Tg",
  Reddit: "Rdt",
  Medium: "Med",
  Instagram: "Ins",
  LinkedIn: "Lin",
  "ENS Domain": "ENS",
  "EVM Address": EVMNetwork,
  "Starknet Address": Starknet,
  "TON Address": TON
} as const;

type ChannelLabel = keyof typeof allChannels;

export type ChannelType = (typeof allChannels)[ChannelLabel];

export type ChannelValue = `${ChannelType}:${string}`;
