import type { Address, Network } from "@okcontract/multichain";

import type { JWTUser } from "./jwt";

export const ADMIN = "admin" as const;
export const MANAGER = "manager" as const;
export const GUEST = "guest" as const;
// export const PUBLIC = "public" as const;
export const USER = "user" as const;

export type Role =
  | typeof GUEST // NO-ROLE
  | typeof ADMIN
  | typeof MANAGER
  // | typeof PUBLIC
  | typeof USER;

export const jwtRole = (jwt: JWTUser | "loading") =>
  jwt === "loading" || jwt === null
    ? null // undefined
    : jwt?.admin
      ? ADMIN
      : jwt?.role === "user"
        ? USER
        : GUEST;

export const is_allowed = (role: Role, whitelists: Role[]) =>
  whitelists?.includes(role);

export interface UserRole {
  role: Role;
  /** username */
  u: string;
  /** granter */
  from: Address<Network>;
  /** optional value */
  v?: string;
}
