<script lang="ts">
  import {
    OKTransaction,
    type AnyContractQuery,
    type OKPage
  } from "@okcontract/sdk";
  import { getTheme } from "@okcontract/uic";

  import SwitchChains from "./SwitchChains.svelte";
  import WalletAccounts from "./WalletAccounts.svelte";
  import WidgetSettings from "./WidgetSettings.svelte";

  export let instance: OKPage;
  const proxy = instance.proxy;

  export let okTX: OKTransaction<AnyContractQuery>;
  const org = okTX.org;
  const chains = proxy.map(
    [okTX.chains],
    (all) => Array.from(all.keys()),
    "chains"
  );
  // $: okTX && console.log({ $chains, title: okTX.title.value, okTX });

  export let theme = getTheme();

  const open = proxy.new(false, "WidgetHeader.open");
</script>

<div class="grid h-14 z-10 w-full">
  <div class="flex gap-1 items-center justify-end px-3 py-2">
    <div class="flex gap-1 items-center">
      <SwitchChains
        {instance}
        {chains}
        displayCurrent={true}
        size="sm"
        small={true}
      />
      <WalletAccounts {instance} {open} />
      <WidgetSettings {theme} {instance} small={true} />
    </div>
  </div>
</div>
