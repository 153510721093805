import { getContext, onDestroy } from "svelte";

import {
  type OKCore,
  OKCoreKey,
  OKPage,
  type OKPageOptions
} from "@okcontract/sdk";

export const useCore = (): OKCore => getContext(OKCoreKey);

/**
 * instance creates a local instance for a Svelte component of the OKcontract SDK.
 * @returns instance
 * @description instance is automatically destroyed on component shutdown.
 */
export const newOKPage = (
  name?: string,
  options: OKPageOptions = {}
): OKPage => {
  // console.warn("create Instance", name);
  const core = useCore();
  const instance = new OKPage(core, {
    local: true,
    rpc: true,
    name,
    ...options
  });
  onDestroy(() => {
    // console.warn("destroy Instance", name);
    // window.setTimeout(() => instance.destroy(), 10);
    instance.destroy();
  });
  return instance;
};
