<script lang="ts">
  import type { AnyCell } from "@okcontract/cells";
  import type { DataEditor, EditorNode } from "@okcontract/fred";
  import type { OKPage } from "@okcontract/sdk";

  import type { EditorOptions } from "../types";

  import AlertCellError from "@scv/dcuic/AlertCellError.svelte";
  import ObjectFieldEdit from "./ObjectFieldEdit.svelte";

  export let instance: OKPage;
  export let editor: DataEditor;
  export let node: AnyCell<EditorNode>;
  export let options: EditorOptions = {};

  // $: console.log("editornode", { $node });
</script>

{#if $node instanceof Error}
  <AlertCellError cell={node} where="EditorNode" />
{:else if $node}
  <ObjectFieldEdit {instance} {editor} node={$node} {options}>
    <slot />
  </ObjectFieldEdit>
{/if}
