<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { type ValueCell } from "@okcontract/cells";
  import type { OKPage } from "@okcontract/sdk";
  import {
    Icon,
    getTheme,
    inputSizes,
    inputStyles,
    type InputSize,
    type InputStyle
  } from "@okcontract/uic";

  export let instance: OKPage;
  const proxy = instance.proxy;

  /** the search input */
  export let input: ValueCell<string> = proxy.new("");
  export let placeholder: string;
  export let style: InputStyle = "default";
  export let size: InputSize = "md";
  export let type: string = "text";
  export let searching: ValueCell<boolean> | undefined = proxy.new(false);
  export let disabled = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
  let searchInputRef: HTMLInputElement;

  const onEnter = (event: KeyboardEvent) => {
    if (event.code === "Enter") {
      if (searchInputRef.value.trim() !== "") {
        event.preventDefault();
        dispatch("submit");
      }
      searchInputRef.blur();
    }
  };
  const focus = (node: HTMLInputElement) => {
    if (!$input) return;
    node.focus();
  };
</script>

<label
  class="input {disabled
    ? 'input-disabled'
    : theme.dark(
        $compiledTheme,
        'input-white-alpha',
        'input-black-alpha',
        `${inputStyles[style]}`
      )} flex items-center"
>
  <!--style={theme.apply($compiledTheme, [ThemeText])}-->
  <Icon name="search" size="xs" opacity="70" />
  <input
    class="grow {inputSizes[size]} flex"
    {type}
    {placeholder}
    value={$input}
    {disabled}
    bind:this={searchInputRef}
    use:focus
    on:click={() => dispatch("click")}
    on:input={() => {
      const newInput = searchInputRef.value.trim();
      input.set(newInput);
      dispatch("input", newInput);
    }}
    on:keyup={onEnter}
    on:focus={() => dispatch("focus")}
    on:keydown={(ev) => dispatch("keydown", ev)}
    on:mousedown={(ev) => ev.stopPropagation}
    on:focus
  />
  <!--style={theme.apply($compiledTheme, [ThemeText])}-->
  <!-- FIXME: stopPropagation not ok -->
  {#if $searching}
    <span class="loading loading-spinner loading-xs"></span>
  {/if}
  {#if searchInputRef?.value?.length}
    <button
      class="btn btn-sm btn-circle btn-ghost"
      on:click={(ev) => {
        ev.stopPropagation();
        searchInputRef.value = "";
        dispatch("delete");
      }}
    >
      <Icon name="close-o" size="sm" />
    </button>
  {/if}
</label>
