<script lang="ts">
  import {
    OKWidgetCallStep,
    OKWidgetSigStep,
    OrgQuery,
    type WidgetQueryType
  } from "@okcontract/coredata";
  import { contractQuery, get_widget_step } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { OrgSnippet, SmartContractSnippet } from "@scv/dcuic";

  export let instance = newOKPage("WidgetSnippet");
  export let q: WidgetQueryType;
  export let inline: boolean;

  const local = instance.local;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const qCell = proxy.new(q, `WidgetSnippet.qCell:${q}`);
  $: qCell.set(q);

  const widget = local.unwrappedCell(qCell, "widget");
</script>

{#if $widget && !($widget instanceof Error)}
  {@const step = get_widget_step($widget, 0)}
  <div
    class={inline
      ? "grid items-center gap-x-4 sm:grid-cols-2 md:grid-cols-3"
      : "flex flex-col"}
  >
    <div
      class="text-left text-sm font-semibold capitalize {inline ? '' : 'mb-1'}
  {theme.dark(
        $compiledTheme,
        'text-white',
        'text-gray-900',
        'text-base-content'
      )}"
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      {#if $widget.name}{$widget.name}{:else}Widget{/if}
    </div>
    <!-- @todo multi step -->

    {#if step?.sty === OKWidgetCallStep}
      <SmartContractSnippet {instance} q={contractQuery(step)} small={true} />
    {:else if step.sty === OKWidgetSigStep}
      <OrgSnippet {instance} q={OrgQuery($widget.org)} small={true} />
    {/if}
    <slot name="tag" />
  </div>
{/if}
