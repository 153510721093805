<script lang="ts">
  import type { ValueCell } from "@okcontract/cells";
  import type { LabelledTypeDefinition } from "@okcontract/fred";
  import { getTheme } from "@okcontract/uic";
  import { FieldHelpText } from "@scv/dcuic";

  import type { EditorOptions } from "../types";

  export let display: boolean = true;
  export let showLabel: boolean = true;
  export let showHint: boolean = false;
  export let highlightOnHover: boolean = true;

  export let itemHover: ValueCell<boolean> = undefined;

  // export let node: EditorNode;
  export let options: EditorOptions = {};

  // @todo use def from node?
  // const definition = node.definition;
  export let def: LabelledTypeDefinition = undefined;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  $: hint = "hint" in def && def.hint;
</script>

{#if display && showLabel && def?.label}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="label {options?.view ? 'mt-1' : ''} {('labelCss' in def &&
      def?.labelCss) ||
      ''}"
  >
    <span
      class="label-text opacity-70 flex gap-1 items-center {theme.dark(
        $compiledTheme,
        'text-white',
        'text-black',
        ''
      )}"
      on:mouseenter={(ev) => {
        if (!highlightOnHover) return;
        $itemHover = true;
      }}
      on:mouseleave={(ev) => {
        if (!highlightOnHover) return;
        $itemHover = false;
      }}
    >
      {#if showLabel && def?.label}
        {def.label}
        {#if showHint && hint}
          <FieldHelpText hintText={hint} />
        {/if}
      {/if}
    </span>
    <!--style={theme.apply($compiledTheme, [ThemeText])}-->
    <slot />
  </div>
{/if}
