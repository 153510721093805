import { createWalletClient, http, type SignMessageReturnType } from "viem";
import type { PrivateKeyAccount } from "viem/accounts";
import { mainnet } from "viem/chains";

import type { Network, StringAddress } from "@okcontract/multichain";
import { hash_passwd } from "@scv/libcrypto";
import { hash_to_base64 } from "@scv/utils";

import type { SignedMessage, UserAuth, UserCredentials } from "./types";

export const publicToken = "public";

const addressSalt = "addr";

// move to @scv/auth?
// @todo extend beyond EVM
export type SignerFunction = (msg: string) => Promise<`0x${string}`>;
export enum SignerSource {
  Real = 0,
  Generated = 1
}
export type Signer<N extends Network> = {
  acc: StringAddress<N>;
  fn: SignerFunction;
  src: SignerSource;
};
export const newSigner = <N extends Network>(
  acc: StringAddress<N>,
  fn: SignerFunction,
  src: SignerSource
): Signer<N> => ({ acc, fn, src });

export class APIUser {
  private _endpoint: string;
  private _app: string;

  constructor(endpoint: string, app: string) {
    this._endpoint = endpoint;
    this._app = app;
  }

  /**
   * New implementation of a simplified authentication workflow.
   */
  Authenticate = async (signer: Signer<Network>): Promise<UserAuth> => {
    const ch = 1;
    // Fetch the message from the backend
    let r = await fetch(`${this._endpoint}/api/nonce`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ addr: signer.acc, ch })
    });
    if (!r.ok) {
      throw new Error(`request failed: ${await r.text()}`);
    }
    const { msg } = await r.json(); // @todo also use nonce?
    // Prompt the user to sign the message
    const Sig = await signer.fn(msg);
    // Send the signed message back to the backend
    r = await fetch(`${this._endpoint}/api/verify`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Msg: msg, Sig })
    });
    if (!r.ok) throw new Error(`request failed: ${await r.text()}`);
    return r.json();
  };

  /**
   * KeyPair retrieves the keypair associated with a user address.
   * @deprecated
   */
  AuthMessage = // @todo: implement cache?
    async (w: UserCredentials): Promise<SignedMessage> => {
      // console.table({ addressSalt, w })
      const hash = hash_passwd(addressSalt, w.username);
      const url = `${this._endpoint}/preflight`;
      const r = await fetch(url, {
        headers: { "Content-Type": "application/json; charset=utf-8" },
        method: "POST",
        body: JSON.stringify({ ID: hash })
      });
      return r.json();
    };

  /**
   * Wallet retrieves a user authentication token using a wallet signature.
   * @deprecated
   */
  Wallet = async (w: SignedMessage): Promise<UserAuth> => {
    const url = `${this._endpoint}/wallet`;
    const r = await fetch(url, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: "POST",
      body: JSON.stringify(w)
    });
    if (r.ok) {
      return r.json();
    }
    throw new Error(`request failed: ${await r.text()}`);
  };

  /**
   * VerifyDiscord verify a discord user id
   */
  VerifyDiscord = async (
    token: string,
    da: { ID: string; Key: string; ESM: SignedMessage } // id is the datacache id of the user / key is discordID
  ): Promise<UserAuth> => {
    const url = `${this._endpoint}/auth/discord/verify`;
    const r = await fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer: ${token}`
      },
      method: "POST",
      body: JSON.stringify(da)
    });
    if (r.ok) {
      return r.json();
    }
    throw new Error(`request failed: ${await r.text()}`);
  };

  /**
   * Only used in tests.
   */
  walletAuthentication = async (
    account: PrivateKeyAccount
  ): Promise<string | null> => {
    // Create wallet client.
    const client = createWalletClient({
      account,
      chain: mainnet,
      transport: http()
    });
    const msg = <SignedMessage>(
      await this.AuthMessage({ username: account.address })
    );
    // Sign message with wallet.
    msg.Sig = await client.signMessage({
      account,
      message: msg.Msg
    });
    // Retrieve auth token.
    msg.code = await hash_to_base64(`.${this._app}`);
    const auth = await this.Wallet(msg);
    // Return the token.
    return auth.token?.length ? auth.token : null;
  };
}
