<script lang="ts">
  import type { TransactionReceipt } from "viem";

  import type { AnyCell } from "@okcontract/cells";
  import type { Chain } from "@okcontract/multichain";
  import type { SentTransaction } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { InlineDescription } from "@okcontract/uic";

  const instance = newOKPage("Receipt");

  export let name: string;
  export let receipt: TransactionReceipt;
  export let tx: SentTransaction;
  export let chain: AnyCell<Chain>;

  export let currency: string;
  export let service: number = 0;
</script>

{#if !receipt}
  Receipt not found
{:else if $chain instanceof Error}
  Chain error
{:else}
  <InlineDescription title={name} size="md" />
  <div class="ml-2 mt-1">
    <InlineDescription title="Gas:" size="sm">
      <div>{receipt.gasUsed}</div>
    </InlineDescription>
    <InlineDescription title="Price:" size="sm">
      {receipt.effectiveGasPrice
        ? Number(receipt.effectiveGasPrice) / 1e9
        : $chain.defaultGas || 0} gwei
    </InlineDescription>
    {#if tx}
      <InlineDescription title="Currency:" size="sm">
        1 {currency} =
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(tx.gasCurPrice)}
        USD
      </InlineDescription>
    {/if}
    <InlineDescription title="Service:" size="sm"
      >{service} USD</InlineDescription
    >
  </div>
{/if}
