import {
  type AnyCell,
  Cell,
  type Path,
  type SheetProxy,
  type Uncellified,
  cellify,
  collector,
  isObject
} from "@okcontract/cells";

type PathValue = { path: Path; cell?: AnyCell<unknown>; raw?: unknown };

/**
 * Finds all cells and their paths in the given structure.
 * @param value - The structure to search.
 * @param root - The root path (default is an empty array).
 * @returns An array of { path, cell } pairs.
 * @todo annotate empty arrays to differentiate with empty objects
 */
const findCells = (value: unknown, root: Path = []): PathValue[] => {
  const paths: Array<PathValue> = [];
  const aux = (value: unknown, currentPath: Path) => {
    if (value instanceof Cell) {
      paths.push({ path: currentPath, cell: value });
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => aux(item, [...currentPath, index]));
    } else if (isObject(value)) {
      for (const [key, item] of Object.entries(value))
        aux(item, [...currentPath, key]);
    } else paths.push({ path: currentPath, raw: value });
  };
  aux(value, root);
  return paths;
};

// Recursively resolve cells until all are unwrapped
const recurse = (proxy: SheetProxy, pvs: PathValue[]): AnyCell<PathValue[]> => {
  // Keep track of which indices had cells
  const indexed = pvs.map((v, i) => ({ v, i })).filter(({ v }) => v.cell);
  // @todo collect
  const coll = collector<AnyCell<PathValue[]>>(proxy);
  return proxy.mapNoPrevious(
    indexed.map(({ v }) => v.cell),
    (...resolvedValues) => {
      // Expand newly resolved values
      const fresh = resolvedValues.flatMap((resolved, idx) => {
        const originalIndex = indexed[idx].i;
        return findCells(resolved, pvs[originalIndex].path);
      });
      // We merge with the previous list (removing the previous cells that were transformed)
      const merged = [...pvs.filter((v) => !v.cell), ...fresh];
      // We compute remaining cells to decide if we stop the recursion
      const cells = fresh.map((v) => v.cell).filter((c) => c !== undefined);
      return cells.length ? coll(recurse(proxy, merged)) : merged;
    }
  );
};

// @todo empty array
const buildResult = (finalPathValues: PathValue[]): unknown => {
  // If there are no path values, return an empty object
  if (finalPathValues.length === 0) {
    return {};
  }

  // If there's exactly one entry with an empty path, return its raw directly.
  if (finalPathValues.length === 1 && finalPathValues[0].path.length === 0) {
    return finalPathValues[0].raw;
  }

  // Decide whether the top-level should be an array or object:
  // If *all* top-level path[0] keys are numeric, we treat it as an array root.
  const allTopKeysNumeric = finalPathValues.every(
    (pv) => pv.path.length > 0 && typeof pv.path[0] === "number"
  );
  const root: unknown = allTopKeysNumeric ? [] : {};

  // Build the nested structure by iterating through finalPathValues
  for (const { path, raw } of finalPathValues) {
    let current = root;
    for (let i = 0; i < path.length - 1; i++) {
      const key = path[i];
      // If nothing is present, create either [] or {} depending on next path
      if (current[key] === undefined) {
        current[key] = typeof path[i + 1] === "number" ? [] : {};
      }
      current = current[key];
    }
    current[path[path.length - 1]] = raw;
  }

  return root;
};

export const reactiveUncellify = <T>(
  proxy: SheetProxy,
  v: T | AnyCell<T>,
  name = "reactiveUncellify"
): AnyCell<Uncellified<T>> => {
  const first = findCells(v);
  const finalPaths = recurse(proxy, first);
  return proxy.mapNoPrevious(
    [finalPaths],
    // By now, list should have no cells
    (list) => buildResult(list) as Uncellified<T>,
    name
  );
};

/**
 * deepCopy recreates a deep structure with ValueCells from any cell.
 */
export const deepCopy = async (proxy: SheetProxy, cell: AnyCell<unknown>) => {
  const value = reactiveUncellify(proxy, cell, "dCU");
  const copy = cellify(proxy, await value.consolidatedValue, "dC"); // @todo fail?
  proxy._sheet.collect(value);
  return copy;
};
