<script lang="ts">
  import { debouncer } from "@okcontract/cells";
  import { type ABIExtra } from "@okcontract/coredata";
  import { OKTransaction, formatError, human_error } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { AlertBanner, getTheme } from "@okcontract/uic";

  import CellValue from "./CellValue.svelte";

  export let instance = newOKPage("TXError");
  const core = instance.core;
  const proxy = instance.proxy;

  export let okTX: OKTransaction;
  $: canSendTX = okTX.canSendTX;
  $: tx = okTX.tx;
  $: sendValue = okTX.$value;
  $: chain = okTX.chain;

  const tokenQuery = proxy.new(null, "tokenQuery");
  $: {
    chain &&
      $chain &&
      !($chain instanceof Error) &&
      tokenQuery.set($chain.currency);
  }
  // @todo collect cells when okTX changes...
  $: nativeBalance = instance.nativeBalance(okTX.contractChain, core.WalletID);

  // @todo should be a cell
  export let abix: ABIExtra = undefined;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
  // $: herr = human_error(abix, err);

  let show_errors = true;
  const errorDelay = 2500;

  // @todo external function
  const deb = debouncer(errorDelay);
  let debError: { type: "error" | "info"; message: string };
  // Clear error when working.
  instance.proxy.working.subscribe((v) => {
    if (v) debError = null;
  });
  $: error =
    // @todo merge with human_error
    $tx instanceof Error && $canSendTX === true ? formatError($tx) : null;
  // Immediately stop displaying errors.
  $: error === null && (debError = null);
  $: deb(() => {
    debError = error;
  }, null);
</script>

{#if debError}
  <!-- {check_params} / {fn.inputs.length}
          <small>{$param_values.length}</small>
          {$param_values.reduce((acc, p) => acc + (p !== undefined ? 1 : 0), 0)} -->
  <!-- FIXME: add custom matching expressions for admin functions in ABIExtra -->
  <div
    class="absolute bottom-0 left-0 right-0 z-30 max-h-12 rounded-b-box
    {theme.dark(
      $compiledTheme,
      'bg-white-alpha text-white',
      'bg-black-alpha text-black',
      'bg-black-alpha text-black'
    )}"
  >
    <!--     HERE
    {#if !err?.message}
      <AlertError>An error occurred!</AlertError>
 -->
    <!-- {:else if err.message.includes("caller is not the owner") || err.message.includes("contract owner") || err.message.includes("Governable: forbidden")}
  <AlertBanner style="warning" icon="warning"
    >This function is reserved for the smart contract admin.</AlertBanner
  > -->
    <!-- {:else if err.message.includes("balance too low") || err.message.includes("exceeds balance")}
    <AlertBanner style="warning" icon="warning"
      >Insufficient balance: You need to acquire enough corresponding tokens
      first.</AlertBanner
    > -->
    {#if error.message.includes("insufficient funds for gas")}
      {#if show_errors && !($tokenQuery instanceof Error)}
        {@const want = instance._formatAmount(
          sendValue,
          tokenQuery,
          okTX.contractChain
        )}
        <!-- @todo rational for nativeBalance -->
        {@const has = instance._formatAmount(
          nativeBalance,
          tokenQuery,
          // @todo fix chain
          okTX.contractChain
        )}
        {@const _ = console.log("herr", {
          $tokenQuery,
          want,
          has,
          $nativeBalance,
          $sendValue
        })}
        <AlertBanner
          style="warning"
          icon="warning"
          size="sm"
          close={true}
          on:cancel={() => {
            show_errors = false;
          }}
        >
          <!-- @todo generalize, this message should be generated in errors.ts -->
          <span>
            <b>Top up!</b> You need
            <CellValue proxy={instance.proxy} value={want} />
            but only have <CellValue proxy={instance.proxy} value={has} />
          </span>
        </AlertBanner>
      {/if}
    {:else}
      {@const herr = human_error(abix, $tx)}
      {@const _ = console.log({ herr, $tx })}
      {#if herr?.dis}
        <AlertBanner style="error" icon="error" size="sm"
          >{herr.msg}</AlertBanner
        >
      {:else}
        <AlertBanner style="error" icon="error" size="sm">
          <!-- $tx?.shortMessage || $tx?.message -->
          {error?.message}</AlertBanner
        >
      {/if}
    {/if}
  </div>
{/if}
