<script lang="ts">
  import { getTheme, Icon, type IconName } from "@okcontract/uic";
  import { IconStyles } from "@scv/dcuic";

  export let name: IconName;
  export let style: keyof typeof IconStyles = "default";
  export let size: "xl" | "xxl" = "xl";

  let sizer = size === "xl" ? 12 : 20;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
</script>

<div
  class="mx-auto sm:mx-0 shrink-0 flex items-center justify-center h-{sizer} w-{sizer}
    rounded-full {theme.dark(
    $compiledTheme,
    'btn-white-alpha',
    'btn-black-alpha',
    `${IconStyles[style]}`
  )}"
>
  <Icon {name} size={size === "xl" ? "md" : "lg"} />
</div>
