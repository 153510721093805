import type { Address, ChainType, Network } from "@okcontract/multichain";

import type { AnonContractQueryType, ContractQueryType } from "./contract";
import type { TokenQueryType } from "./token";
import type { ABIMethod, RawThemeDefinition } from "./types";
import type { OUI } from "./user";

export type WidgetQueryType = `wid:${string}`;

/**
 * OKWidget defines a Widget for OKcontract.
 * @todo add Overrides
 */
export type OKWidget = {
  /** widget ID (for owner) */
  id: string;
  /** widget definition version */
  ver: number;
  /** display name */
  name: string;
  /** active */
  act: boolean;
  /** address of owner account */
  own: OUI;
  /** eas query refID */
  qri?: string;
  /** authorized domains (all if empty) */
  dom?: string[];
  /** theme */
  // `${WidgetThemeProperty}:${WidgetThemeValue}`[];
  th?: RawThemeDefinition;
  /** common definitions */
  com?: OKWidgetStep<OKWidgetStepType>;
  /** steps @todo USE */
  st: OKWidgetStep<OKWidgetStepType>[];
  /** org */
  org?: string;
  /** text before connection */
  tbc?: string[];
  /** image before connection*/
  ibc?: string;
};

export const OKWidgetCallStep = "call";
export const OKWidgetSigStep = "sig";
export const OKWidgetAftStep = "aft";
export const OKWidgetWaitStep = "wait";
export const OKWidgetApproveStep = "app";
export const OKWidgetNetworkTXStep = "ntx";
/**
 * OKWidgetStepTypes defines types of action steps for widgets.
 */
export const OKWidgetStepTypes = [
  OKWidgetCallStep,
  OKWidgetSigStep,
  OKWidgetAftStep,
  OKWidgetWaitStep,
  OKWidgetApproveStep,
  OKWidgetNetworkTXStep
] as const;

export type OKWidgetStepType = (typeof OKWidgetStepTypes)[number];

/**
 * OKWidgetStep defines an widget action step.
 */
export interface OKWidgetStep<T extends OKWidgetStepType> {
  /** WidgetStepType */
  sty: T;
  /** optional org (lambdascript expression) */
  org?: string;
  /** Contract query ("con:xxx", "sign:"). RENAME: Action? */
  q?: ContractQueryType | TokenQueryType | AnonContractQueryType<ChainType>;
  /** Chain for token */
  chain?: ChainType;
  /** Method (optional, for "sign:") */
  m?: string;
  /** Sign expression (instead of contract interaction) */
  sig?: string;
  /** Datacache query for network-managed transaction */
  ntx?: string;
  /** Force chains */
  fch?: ChainType[];
  /** ABIMethod definition, overrides prior definitions */
  xm?: ABIMethod;
  /** λscript expression determining if we should skip the step */
  skip?: string;
  /** @deprecated Information text */
  // i?: string[];
  /** @deprecated Image URL (possibly ipfs://, possible expression) */
  // img?: string;
  /** @deprecated List of actions to run after widget interaction ("action:params") */
  // aft?: string[];
}

/**
 * NetworkTX defines a network-defined transactions. Such transactions
 * will be generated and signed by `datacache` nodes, enabling light clients.
 */
export interface NetworkTX {
  /**  query id (e.g. "ods" for OKX DEX Swap) */
  id: string;
  /**  query parameters (e.g. {chain:"arbitrum",tokenA:"tok:weth"}) */
  p: { [key: string]: string };
  /** destination contract: address as string */
  to: Address<Network>;
  /** 0x hex-encoded tx */
  tx: Address<Network>;
  /** optional sender: address as string */
  from?: Address<Network>;
  /** optional native chain value (unparsed bigint) */
  value?: string;
  /** approvals */
  apv?: NetworkApproval[];
  /** metadata */
  data?: { [key: string]: unknown };
}

export interface NetworkApproval {
  /** token address */
  t: Address<Network>;
  /** spender address */
  s: Address<Network>;
  /** optional amount (or "infinite" if undefined) as stringified number */
  a?: string;
}
