<script lang="ts">
  // import * as Config from "../../config.json";
  // const DEV = Config.dev;

  import type { ValueCell } from "@okcontract/cells";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Button, Icon, ThemeBackground, getTheme } from "@okcontract/uic";
  import { AlertCellError } from "@scv/dcuic";
  import { truncated_addr } from "@scv/utils";

  import ConnectorButton from "./ConnectorButton.svelte";
  import NoWalletWidget from "./NoWalletWidget.svelte";
  import UserMenuWalletRow from "./UserMenuWalletRow.svelte";

  export let instance = newOKPage("WalletAccounts");
  export let open: ValueCell<boolean>;

  export let closeOnSelect = true;
  export let size: "xs" | "sm" | "md" = "sm";
  export let roles = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const core = instance.core;
  const detected = core.DetectedConnectors;
  const name = core.WalletName;
  const connectedWallets = core.ConnectedWallets;
  const wantedWalletAccount = core.WantedWalletAccount;
  const isVerified = core.IsVerified;
  const loading = core.IsSwitchingAccount;
  const noWallet = core.IsNoWallet;

  const proxy = instance.proxy;
  const showNoWallet = proxy.new(false, "showNoWallet");

  // small full-screen dropdown
  let menuOpen = false;
  const toggleMenuOpen = () => {
    menuOpen = !menuOpen;
  };
</script>

<!-- There is a connected wallet -->
{#if $wantedWalletAccount}
  {#if $connectedWallets instanceof Error}
    <AlertCellError cell={connectedWallets} />
  {:else}
    <button
      on:click={toggleMenuOpen}
      class="btn btn-{size} rounded-box {theme.dark(
        $compiledTheme,
        'btn-white-alpha',
        'btn-black-alpha',
        'btn-ghost'
      )}"
    >
      {#if $loading === undefined || $loading === true}
        <span class="loading loading-spinner loading-xs"></span>
      {:else if $isVerified}
        <Icon name="wallet-o" size="sm" opacity="50" />
      {/if}
      {#if $name}
        <span class="hidden font-medium lg:block opacity-80">{$name}</span>
      {:else}
        <span class="hidden font-medium lg:block opacity-80"
          >{truncated_addr($wantedWalletAccount.toString(), 10)}</span
        >
        <span class="font-medium lg:hidden opacity-80"
          >{truncated_addr($wantedWalletAccount.toString(), 4)}</span
        >
      {/if}
    </button>

    {#if menuOpen}
      <div
        class="absolute top-0 bottom-0 right-0 left-0 z-30 flex flex-col p-4 rounded-box {theme.dark(
          $compiledTheme,
          'bg-black text-white',
          'bg-white text-black',
          'bg-base-100 text-base-content'
        )}"
        style={theme.apply($compiledTheme, [ThemeBackground])}
      >
        <header class="mb-2 flex justify-between items-center">
          <div class="flex gap-2 items-center">
            <Icon name="wallet-o" size="sm" opacity="50" />
            <div class="text-lg font-semibold">Wallets</div>
          </div>
          <button
            class="btn btn-sm btn-circle {theme.dark(
              $compiledTheme,
              'btn-white-alpha',
              'btn-black-alpha',
              'btn-ghost'
            )}"
            on:click={toggleMenuOpen}
            ><Icon name={"close"} size="sm" opacity="70" /></button
          >
        </header>

        <section>
          <h6 class="divider">Accounts</h6>
          <div
            class="stats stats-vertical rounded-btn w-full {theme.dark(
              $compiledTheme,
              'bg-white-alpha text-white',
              'bg-black-alpha text-black',
              'border border-base-200'
            )} {roles ? '' : 'overflow-auto'}"
          >
            {#each Object.entries($connectedWallets || {}) || [] as [_, w] (w.addr.value)}
              <UserMenuWalletRow
                {instance}
                account={w}
                {open}
                {closeOnSelect}
                {roles}
              />
            {/each}
          </div>
        </section>

        <section>
          <h6 class="divider">Connectors</h6>
          <div
            class="stats stats-vertical rounded-btn w-full {theme.dark(
              $compiledTheme,
              'bg-white-alpha text-white',
              'bg-black-alpha text-black',
              'border border-base-200'
            )}"
          >
            <div class="stat p-3 flex items-center justify-between">
              <ul class="flex gap-3">
                {#if !($detected instanceof Error)}
                  {#each Object.entries($detected || {}) as [_, conn] (conn.ID)}
                    <ConnectorButton {core} connector={conn} />
                  {/each}
                {/if}
              </ul>
            </div>
          </div>
        </section>
      </div>
    {/if}
  {/if}

  <!-- The wallet to install choice menu is displayed (after a click when there is no wallet) -->
{:else if $showNoWallet}
  <NoWalletWidget {theme} {compiledTheme} {showNoWallet} />

  <!-- Either there is no wallet or the wallet is not connected -->
{:else}
  <Button
    style="neutral"
    size="sm"
    label="Connect Wallet"
    rounded={true}
    action={() => {
      if ($noWallet === true) {
        showNoWallet.set(true);
        return;
      }
      core.Connect();
    }}
  />
{/if}
