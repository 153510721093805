<script lang="ts">
  import { type ChainAddress } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";

  import { Icon } from "@okcontract/uic";

  const instance = newOKPage("ExternalLink");

  export let addr: ChainAddress;
  export let isToken: boolean = false;

  const explorerURL = instance.proxy.map(
    [instance.core.Chains],
    (_chains) => {
      const chain = _chains[`§${addr.chain}`];
      return chain.map((_ch) => new URL(_ch?.explorer?.[0]) || null);
    }
  );
</script>

{#if $explorerURL instanceof Error}
  URL: {$explorerURL}
{:else if $explorerURL}
  <a
    href={`${$explorerURL.href}/${isToken ? "token" : "address"}/${addr?.addr}`}
    target="_blank"
    rel="noreferrer"
    class="link link-success link-hover text-sm flex gap-1 items-center"
  >
    {$explorerURL.host}<Icon name="external" size="xs" />
  </a>
{/if}
