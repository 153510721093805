// @todo cycle?
import type {
  CachedData,
  DataCacheType,
  OKToken,
  OKWidget,
  OKWidgetPage,
  Organization,
  SmartContract
} from "@okcontract/coredata";
import { type Chain, getStringAddress, nullAddr } from "@okcontract/multichain";

// @todo we should deploy a contract on Polygon to write that data, and read through RPC
export const signers = {
  "0x66881F603f71268b1bEc29D492FE9BC13B45eab2": "node #1",
  "0x7c7F4b9F2fa3b0617e9F40720a9B7b545af95c7d": "node #H",
  "0x886d5BE42a77FC4f960384F6F25B56294174c6ae": "node #C"
};

// authorized is the list of authorized address to sign
export const authorized = Object.keys(signers).map(getStringAddress);

// @todo move to coredata
export const signerOf = <T extends DataCacheType>(
  ty: T,
  data: CachedData<T>["data"]
) => {
  switch (ty) {
    case "widget":
      // @todo type annotation should not be required
      return (data as OKWidget)?.own;
    case "contract":
      return (data as SmartContract)?.from;
    case "token":
      return (data as OKToken)?.from;
    case "org":
      return (data as Organization)?.from;
    case "page":
      return (data as OKWidgetPage)?.own;
    case "chain":
      return (data as Chain)?.from;
  }
  return nullAddr;
};
