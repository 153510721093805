<script lang="ts">
  import {
    DataEditor,
    type EditorGroup,
    type EditorNode,
    type NodeObject
  } from "@okcontract/fred";
  import type { OKPage } from "@okcontract/sdk";

  import type { EditorOptions } from "../types";
  import EditorSection from "./EditorSection.svelte";
  import ObjectEditItem from "./ObjectEditItem.svelte";
  import ObjectUndefinedPaths from "./ObjectUndefinedPaths.svelte";
  // import { sort } from "@okcontract/cells";

  // @todo use editor proxy?
  export let page: OKPage;
  const proxy = page.proxy;

  export let editor: DataEditor;
  export let node: EditorNode & NodeObject;
  export let options: EditorOptions = {};
  export let group: EditorGroup;
  export let groups: number;

  // const definition = node.definition;
  // const value = proxy.get(node.value)
  // $: console.log("EditorGroup", {$definition})
  // $: console.log("EditorGroup", {$value})
</script>

<!-- @todo manage cell in `@okcontract/fred`, do not recreate -->
<!-- {@const isOpen = proxy.new(!(group[0].col || false), "collapsed")} -->
<!-- GROUP:{node.path.length}-->
<EditorSection
  {proxy}
  label={group[0].l}
  showSection={node.path.length < 2 && groups > 1}
  {options}
>
  <!-- defined nodes -->
  <!-- {#each group[1].sort( (a, b) => ((a?.rank || 0) > (b?.rank || 0) ? 1 : -1) ) || [] as child (child.id)}  -->
  {#each group[1] || [] as child (child.id)}
    <!-- {child.id} -->
    <ObjectEditItem
      instance={page}
      {editor}
      node={child}
      parent={node}
      {options}
    />
  {/each}

  <!-- undefined nodes -->
  {#if !options?.view}
    <!-- even if #each $object is not keyed, this is not recomputed by Svelte by default -->
    {#key group[2]}
      <ObjectUndefinedPaths {editor} parent={node} nodes={group[2] || []}
      ></ObjectUndefinedPaths>
    {/key}
  {/if}
</EditorSection>
