<script lang="ts">
  import { type AnyCell } from "@okcontract/cells";
  import type { Chain } from "@okcontract/multichain";
  import { Icon, getTheme } from "@okcontract/uic";
  import { truncated_addr } from "@scv/utils";
  import { InlineHelpText } from "../../packages/uic/src";

  // @todo should be a cell?
  export let hash: string;
  export let ch: AnyCell<Chain>;

  export let showHash = true;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const url = ch.map((_ch) => new URL(_ch.explorer[0]));
</script>

<div class="mb-2 flex flex-col items-center justify-between">
  {#if showHash}
    <div
      class={theme.dark(
        $compiledTheme,
        "text-white",
        "text-black",
        "text-base-content"
      )}
      title={hash}
    >
      {truncated_addr(hash)}
    </div>
  {/if}
  {#if $url instanceof Error}
    <InlineHelpText style="error">Error url</InlineHelpText>
  {:else}
    <a
      href={`${$url.href}/tx/${hash}`}
      target="_blank"
      rel="noreferrer"
      class="inline-flex items-center text-xs gap-1 {theme.dark(
        $compiledTheme,
        'text-white',
        'text-black',
        'link link-primary'
      )}"
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      <Icon name="external" size="sm" />
      {$url.hostname}
    </a>
  {/if}
</div>
