import type { ButtonStyle, IconName } from "@okcontract/uic";
import type { Tuple } from "@scv/utils";

export type ObjectPath = (string | number)[];

export type Colors = (typeof AvailableColors)[number];

export interface UINav {
  link: string;
  color: Colors;
  icon?: IconName;
}

/**
 * UIDefinition regroups all UI definitions for a given app.
 *
 * FIXME: There should be a single store containing the current app state.
 */
export interface UIDefinition {
  /**
   * Modes: display_name => link
   */
  Modes: {
    [name: string]: UINav;
  };
}

export type CurrentModeType<T extends UIDefinition> = keyof T;

export const AvailableColors = [
  "gray",
  "red",
  "orange",
  "yellow",
  "amber",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose"
] as const;

export type AvailableColorsType = (typeof AvailableColors)[number];
/**
 * makePath constructs a human-readable path from the current URL.
 *
 * used by `Header.svelte`
 *
 * FIXME: generalize
 */
export const makePath = (uri: string) => {
  // const uri: string = $url();
  let acc = "";
  const res = [];
  uri.split("/").forEach((x, i) => {
    if (x) {
      acc += `/${x}`;
      if (i === 1 && x.toLowerCase() === "template") {
        res.push({ url: "/templates", label: "Templates" });
      } else if (i === 1 && x.toLowerCase() === "document") {
        res.push({ url: "/documents", label: "Documents" });
      } else if (i === 1 && x.toLowerCase() === "user") {
        res.push({ url: "/users", label: "Users" });
      } else if (i === 1 && x.toLowerCase() === "strategy") {
        res.push({ url: "/strategies", label: "Strategies" });
      } else {
        // Try to set label name.
        const label = x;
        if (x !== "index") {
          res.push({ url: acc, label: label });
        }
      }
    }
  });
  if (res.length === 0) {
    return [{ url: "/", label: "Dashboard" }];
  }
  if (res[0].label === "Documents" && res.length > 1) {
    res[1].rename = true;
  }
  if (res[2] && res[2].label.toLowerCase() === "edit") {
    res[1].rename = true;
  }
  // console.log("PATH", res);
  return res;
};

/**
 * ViewMetaData defines additional data for view generation.
 */
export interface ViewMetaData {
  /** labels maps a field name to a display name */
  labels: { [key: string]: string };
  /** views maps a field name to a view option (optional) */
  views?: { [key: string]: string };
  /** fields is the ordered list of fields to display */
  fields: string[];
}

/**
 * ButtonType represents configuration data for a button.
 */
export interface ButtonType {
  label: string;
  action?: () => void;
  asyncAction?: () => Promise<void>;
  link?: string;
  style?: ButtonStyle;
  icon?: IconName;
  iconPrepend?: boolean;
  iconAppend?: boolean;
  disabled?: boolean;
}

/**
 * IconStyle represents the circle background of icons.
 */
export const IconStyles = {
  default: "bg-info text-info-content",
  primary: "bg-primary text-primary-content",
  warning: "bg-warning text-warning-content",
  success: "bg-success text-success-content",
  error: "bg-error text-info-content",
  base: "bg-base-100 text-base-content",
  neutral: "bg-neutral text-neutral-content",
  ghost: "bg-transparent text-base-content"
};

/**
 * Metadata is a subset of Common.
 */
export interface Metadata {
  modified: string;
  modifiedBy?: string;
  status?: string;
}

/**
 * iconOf returns the icon name from a type.
 * @param ty type definition
 */
export const iconOf = (ty: string) => {
  switch (ty) {
    case "string":
      return "string";
    case "number":
      return "number";
    case "boolean":
      return "checkbox";
    case "date":
      return "date";
    case "enum":
      return "menu-o";
    case "array":
      return "BulletList";
    case "object":
      return "grid-o";
    default:
      return "field";
  }
};

type ValueOf<T> = T[keyof T];
/**
 * themeColor generates a color from a base, p.e. `text-${base}-500 ${active ? `bg-${base}-50` : ""}`.
 */
export const themeColor = (base: Colors, active: boolean) =>
  `${active ? `text-${base}-400` : ""}`;

export const bgStyles: Tuple<string, 16> = [
  "bg-pink-400",
  "bg-cyan-400",
  "bg-orange-400",
  "bg-rose-400",
  "bg-violet-400",
  "bg-emerald-400",
  "bg-teal-400",
  "bg-sky-400",
  "bg-lime-400",
  "bg-fuchsia-400",
  "bg-amber-400",
  "bg-green-400",
  "bg-blue-400",
  "bg-yellow-500",
  "bg-purple-400",
  "bg-red-400"
];

export const borderStyles: Tuple<string, 16> = [
  "border-pink-400",
  "border-cyan-400",
  "border-orange-400",
  "border-rose-400",
  "border-violet-400",
  "border-emerald-400",
  "border-teal-400",
  "border-sky-400",
  "border-lime-400",
  "border-fuchsia-400",
  "border-amber-400",
  "border-green-400",
  "border-blue-400",
  "border-yellow-500",
  "border-purple-400",
  "border-red-400"
];
