import type { Abi as StarkAbi } from "starknet";
import { type Abi as ViemAbi, parseAbi } from "viem";

import type { AnyCell, SheetProxy } from "@okcontract/cells";
import {
  type CacheQuery,
  ContractQuery,
  type SmartContract
} from "@okcontract/coredata";
import {
  AddressEVM,
  type ChainAddress,
  ContractType
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";

import { erc20AbiEVM, erc20AbiStarknet } from "./erc20";
import { universalResolverReverseAbi } from "./name";

export class DefaultContracts {
  readonly proxy: SheetProxy;
  private _local: LocalSubscriber<CacheQuery>;

  readonly erc20AbiEVM: AnyCell<ViemAbi>;
  readonly erc20AbiStarknet: AnyCell<StarkAbi>;

  readonly ensReverseAbi: AnyCell<ViemAbi>;

  readonly polygonUNS: AnyCell<ChainAddress>;
  readonly abiUNS: AnyCell<ViemAbi>;
  readonly reverseNameOf: AnyCell<string>;

  constructor(proxy: SheetProxy, local: LocalSubscriber<CacheQuery>) {
    this.proxy = proxy;
    this._local = local;

    this.erc20AbiEVM = proxy.new(erc20AbiEVM, "defaultContract.erc20Abi");
    this.erc20AbiStarknet = proxy.new(erc20AbiStarknet);

    // UNS
    this.polygonUNS = proxy.new(
      {
        chain: "polygon",
        addr: new AddressEVM("0xa9a6A3626993D487d2Dbda3173cf58cA1a9D9e9f"),
        ty: ContractType
      },
      "defaultContract.polygonUNS"
    );
    this.abiUNS = proxy.new(
      parseAbi(["function reverseNameOf(address) view returns (string)"]),
      "defaultContract.abiUNS"
    );
    this.reverseNameOf = proxy.new(
      "reverseNameOf",
      "defaultContract.reverseNameOf"
    );

    // ENS
    this.ensReverseAbi = proxy.new(
      universalResolverReverseAbi,
      "defaultContract.ensReverseAbi"
    );
  }

  getENSContract = (chainID: AnyCell<string>): AnyCell<ChainAddress | null> => {
    const ensContract = this._local.unwrappedCell(
      this.proxy.new(ContractQuery("ens/resolver"))
    ) as AnyCell<SmartContract>;
    return this.proxy.map([ensContract, chainID], (_contract, _chain) => {
      return _contract.addr.find((_addr) => _addr.chain === _chain) || null;
    });
  };
}
