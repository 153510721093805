import { validateAndParseAddress } from "starknet";
import { getAddress, isAddress as isAddressEVM } from "viem";

import {
  type AlphaIdentifier,
  EVM,
  type EVMType,
  type HexString,
  type Network,
  type StarkNetType,
  Starknet,
  TON,
  type TONType
} from "./network";
import {
  type TONAddr,
  isEqualTON,
  parseFriendlyAddress,
  stringifyFriendlyAddress
} from "./ton";

/**
 * ChainID is the type of chains.
 */
export type ChainID = string;

export const WalletType = "w" as const;
export const ContractType = "c" as const;
export const SmartAccount = "sa" as const;

/**
 * AddressType defines different types of addresses.
 *
 * If not stored, this information can be queried from the chain (unless, a contract is deleted
 * via a `self destruct`).
 */
export type AddressType =
  | typeof WalletType
  | typeof ContractType
  | typeof SmartAccount;

/**
 * ChainAddress represents an EVM address (either wallet or contract).
 */
export interface ChainAddress<N extends Network = EVMType> {
  /** name (optional) */
  n?: string;
  addr: Address<N>; // | typeof chain_balance; // FIXME: `0x[A-Fa-f0-9]{40}`
  chain: ChainID;
  ty: AddressType;
  /** version (optional) */
  v?: number;
  /** proxy to (optional) */
  px?: Address<N>;
  // sig: string;
}

export const newChainAddress = <N extends Network>(
  addr: Address<N>,
  chain: ChainID,
  ty: AddressType = "c",
  n?: string
): ChainAddress<N> => ({
  n,
  addr,
  chain,
  ty
});

// @todo extend
export type StringAddressTON = `UQ${string}` | `EQ${string}`;

export type StringAddress<N extends Network> = N extends EVMType
  ? `0x${string}`
  : N extends StarkNetType
    ? `0x${string}`
    : N extends TONType
      ? StringAddressTON
      : never;

export interface Address<N extends Network> {
  network: N;
  toString(): StringAddress<N>;
  equals(addr: Address<N>): boolean;
  isNull(): boolean;
  isNative(): boolean;
}

const addressKey = Symbol("addr");

/**
 * Checks if any value is an instance of Address.
 */
export const isAddress = (value: unknown): value is Address<Network> =>
  value !== null &&
  typeof value === "object" &&
  value.constructor &&
  typeof value.constructor === "function" &&
  value.constructor !== Object &&
  value[addressKey] !== undefined;

/**
 * Address is an on-chain address.
 * @todo NewAddress with auto-detection
 */
export class AddressEVM implements Address<EVMType> {
  /**
   * @example 0x9c7a3e3efd0cec787c432535f27de6c755fb4870
   */
  private _addr: HexString;
  readonly network = EVM;
  /**
   * @todo is the address secure
   * @todo include source/traceability?
   * - verified wallet, signed OKcontract data
   */
  readonly _security: boolean;

  // @todo source/security
  constructor(addr: string | bigint) {
    // @todo auto-detection
    this._addr = getAddress(
      typeof addr === "string"
        ? addr
        : `0x${addr.toString(16).padStart(40, "0")}`
    );
    this[addressKey] = this._addr;
  }
  toString() {
    return this._addr;
  }
  equals(other: Address<EVMType>) {
    return this._addr === other[addressKey];
  }
  isNull() {
    return this.equals(nullAddrEVM);
  }
  isNative() {
    return this.equals(nativeAddrEVM);
  }
  substring() {
    console.log({ SUBSTRING: this._addr });
  }
}

/**
 * Address is an on-chain address.
 */
export class AddressStarknet implements Address<StarkNetType> {
  /**
   * @example 0x5f7cd1fd465baff2ba9d2d1501ad0a2eb5337d9a885be319366b5205a414fdd starknet
   * @example 0x9c7a3e3efd0cec787c432535f27de6c755fb4870 evm
   * @example UQCZ39I6p732UjFGZTirzgnegw2pEEyoQXF8PT3WGRWzYZYK ton
   */
  private _addr: HexString;
  readonly network = Starknet;
  /**
   * @todo is the address secure
   * @todo include source/traceability?
   * - verified wallet, signed OKcontract data
   */
  readonly _security: boolean;

  // @todo source/security
  constructor(addr: string | bigint) {
    this._addr = validateAndParseAddress(addr) as HexString;
    this[addressKey] = this._addr;
  }
  toString() {
    return this._addr;
  }
  equals(other: Address<StarkNetType>) {
    return this._addr === other[addressKey];
  }
  // @todo implement
  isNull() {
    return false;
  }
  isNative() {
    return this.equals(nativeAddrStarknet);
  }
  substring() {
    console.log({ SUBSTRING: this._addr });
  }
}

/**
 * Address is an on-chain address.
 */
export class AddressTON implements Address<TONType> {
  /**
   * @example UQCZ39I6p732UjFGZTirzgnegw2pEEyoQXF8PT3WGRWzYZYK
   */
  private _addr: TONAddr;
  readonly network = TON;
  /**
   * @todo is the address secure
   * @todo include source/traceability?
   * - verified wallet, signed OKcontract data
   */
  readonly _security: boolean;

  // @todo source/security
  constructor(addr: string) {
    this._addr = parseFriendlyAddress(addr);
    this[addressKey] = this._addr;
  }
  toString() {
    return stringifyFriendlyAddress(this._addr);
  }
  equals(other: Address<TONType>) {
    return isEqualTON(this._addr, other[addressKey]);
  }
  // @todo implement
  isNull() {
    return false;
  }
  // @todo implement
  isNative() {
    return false;
  }
  substring() {
    console.log({ SUBSTRING: this._addr });
  }
}

/**
 * chain_balance is an older constant.
 * @todo replace with nativeAddr
 */
export const chain_balance = "chain" as const;

/**
 * null address
 */
export const nullAddrEVM: Address<EVMType> = new AddressEVM(
  "0x0000000000000000000000000000000000000000"
);

/**
 * virtual address for EVM native chain token
 */
export const nativeAddrEVM: Address<EVMType> = new AddressEVM(
  "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
);

/**
 * virtual address for starknet native chain token
 */
export const nativeAddrStarknet: Address<StarkNetType> = new AddressStarknet(
  "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"
);

/**
 * isNullAddr checks if an address is the null address.
 * @param addr
 * @returns
 * @description this function accepts null or undefined values as the null address.
 */
export const isNullAddrEVM = (addr: string) =>
  !addr || addr === nullAddrEVM.toString();

export const isNativeAddrEVM = (addr: string) =>
  getAddress(addr) === nativeAddrEVM.toString();

export const isEVMAddr = (
  addr: ChainAddress<Network>
): addr is ChainAddress<EVMType> => addr.addr.network === EVM;

export const isStarknetAddr = (
  addr: ChainAddress<Network>
): addr is ChainAddress<StarkNetType> => addr.addr.network === Starknet;

/**
 * isRealAddress checks if the address is defined and not the native virtual address.
 * @param addr
 * @returns
 */
export const isRealAddr = <N extends Network>(addr: ChainAddress<N>) =>
  isEVMAddr(addr)
    ? addr && !addr?.addr.equals(nativeAddrEVM)
    : isStarknetAddr(addr)
      ? addr && !addr?.addr.equals(nativeAddrStarknet)
      : false;

/**
 * Checks if a given string is a valid TON Wallet address.
 *
 * @param {string} addr - The address string to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
export const isValidTonAddress = (addr: string): addr is StringAddressTON => {
  try {
    parseFriendlyAddress(addr);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Checks if a given string is a valid StarkNet wallet address.
 *
 * @param {string} addr - The address string to validate.
 * @returns {boolean} - Returns true if valid, false otherwise.
 */
function isValidStarknetAddress(addr: string) {
  if (addr?.length !== 65 && addr?.length !== 66) return false;
  try {
    validateAndParseAddress(addr);
    return true;
  } catch (error) {
    return false;
  }
}

export const isStringAddress = (v: unknown): v is StringAddress<Network> =>
  typeof v === "string" &&
  (isAddressEVM(v) || isValidStarknetAddress(v) || isValidTonAddress(v));

// @todo other chains
export const getStringAddress = (v: string) => {
  if (isAddressEVM(v)) return getAddress(v);
  return v;
};

export const NewAddress = <N extends Network>(
  v: StringAddress<N>
): Address<N> | null =>
  isAddressEVM(v)
    ? (new AddressEVM(v) as unknown as Address<N>)
    : isValidStarknetAddress(v)
      ? (new AddressStarknet(v) as unknown as Address<N>)
      : isValidTonAddress(v)
        ? (new AddressTON(v) as unknown as Address<N>)
        : null;
