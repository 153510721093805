<script lang="ts">
  import { DataEditor } from "@okcontract/fred";
  import { newOKPage } from "@okcontract/svelte-sdk";

  import type { EditorOptions } from "../types";
  import EditorNodeCell from "./EditorNodeCell.svelte";

  // @todo create instance here?
  export let instance = newOKPage("DataEditor");
  export let editor: DataEditor;
  export let options: EditorOptions = {};
</script>

<EditorNodeCell {instance} {editor} node={editor.root} {options} />
