<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { ipfs_rewrite } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Button, ThemeAccent, getTheme } from "@okcontract/uic";
  import { ImageReady, ImageUpload } from "@scv/dcuic";

  export let key: string;
  export let disabled: boolean = false;

  let input_elt: HTMLInputElement;

  export let instance = newOKPage("LightHouseUpload");
  const core = instance.core;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const lightHouse = core.Lighthouse;
  const lightHouseData = lightHouse.get(key);
  const isAuth = lightHouse?.isAuth;
  const status = lightHouseData?.status;
  const uploading = proxy.new(false, "LightHouseUpload.uploading");
  const url = lightHouseData?.url;
  const metadata = lightHouseData?.meta;
  const data = lightHouseData?.data;
  const rewritten = ipfs_rewrite(
    proxy.map([url, metadata], (_url, _meta) =>
      _meta?.Hash ? `ipfs://${_meta.Hash}` : _url || null
    )
  );

  const onFileSelected = (
    e: Event & {
      currentTarget: EventTarget & HTMLInputElement;
      target: HTMLInputElement;
    }
  ) => {
    let image_data: ArrayBuffer;
    let image = e.target.files[0];
    let reader = new FileReader();

    reader.readAsArrayBuffer(image);
    reader.onload = async (e) => {
      uploading.set(true);
      image_data = e.target.result as ArrayBuffer;
      const response = await lightHouse.upload(key, image_data, image.type);
      uploading.set(false);
      if (response.success)
        dispatch(
          "update",
          `https://gateway.lighthouse.storage/ipfs/${response.meta.Hash}`
        );
    };
  };
</script>

<div
  class="sm:rounded-md p-1 border border-dashed {theme.dark(
    $compiledTheme,
    'border-white/30',
    'border-black/30',
    'border-base-300'
  )}"
>
  <div class="flex flex-row">
    {#if (isAuth && $isAuth) || $url || $data}
      <div class="flex flex-col justify-center">
        <ImageUpload {instance} {uploading} {url} {data} {input_elt} />
      </div>
    {/if}
    {#if isAuth && !$isAuth && !disabled}
      <div class="p-2">
        <div
          class="pb-2 px-1 text-xs {theme.dark(
            $compiledTheme,
            'text-white opacity-70',
            'text-gray-900 opacity-70',
            'text-base-content'
          )}"
        >
          To upload an image you can provide your IPFS link or <br />connect to
          Lighthouse decentralized storage
        </div>
        <Button
          label="Connect to Lighthouse"
          style="neutral"
          outline={true}
          size="sm"
          asyncAction={async () => {
            const walletID = await core.WalletID.get();
            if (walletID instanceof Error) return;
            return lightHouse.authenticate(walletID);
          }}
        />
      </div>
    {/if}
    {#if isAuth && $isAuth && ($metadata || $url)}
      <div class="flex flex-col items-start justify-between p-1">
        <div class="flex flex-row-reverse justify-start opacity-50">
          <Button
            style="ghost"
            size="sm"
            square={true}
            iconAppend={true}
            icon="remove"
            action={() => {
              lightHouse.remove(key);
              input_elt.value = "";
              dispatch("update", "");
            }}
          />
          <Button
            style="ghost"
            size="sm"
            square={true}
            iconAppend={true}
            icon="refresh"
            action={() => input_elt.click()}
          />
        </div>
        <div class="flex gap-2 items-center p-1">
          {#if $metadata}
            <div
              class={theme.dark(
                $compiledTheme,
                "text-white opacity-70",
                "text-gray-900 opacity-70",
                "text-base-content"
              )}
            >
              {$metadata?.Size} bytes
            </div>
          {/if}
          <a
            href={$rewritten instanceof Error ? undefined : $rewritten}
            target="_blank"
            rel="noreferrer"
            class="link link-hover {theme.dark(
              $compiledTheme,
              'text-white',
              'text-black',
              'link-primary'
            )}"
            style={theme.apply($compiledTheme, [ThemeAccent])}
          >
            View
          </a>
          {#if $metadata || !$url}
            <ImageReady
              url={$rewritten instanceof Error ? undefined : $rewritten}
              status={$status}
              on:update={(ev) => status.set(ev.detail)}
            />
          {/if}
        </div>
      </div>
    {/if}
  </div>
</div>

<input
  style="display:none"
  type="file"
  accept=".jpg,.jpeg,.png,.svg,video/*"
  on:change={onFileSelected}
  bind:this={input_elt}
/>
