import {
  type Address,
  type ChainType,
  type Network,
  getStringAddress,
  isStringAddress
} from "@okcontract/multichain";

import { OrgQuery } from "./org";
import type { OKToken } from "./token";
import type { SmartContract } from "./types";
import type { VirtualQueryType } from "./virtual";

export type ContractQueryType = `con:${string}`;

export const ContractQuery = (id: string): ContractQueryType => `con:${id}`;

export type AnonContractQueryType<Q extends ChainType> =
  | VirtualQueryType<`con.hash`, `${Q}|${string}`>
  | VirtualQueryType<`cd`, `con:@${Q}/${string}`>
  // @todo not Virtual?
  | `con:@${string}`;

export type AllAnonContractQueryType = VirtualQueryType<
  `con.anon`,
  `${string}`
>;

/**
 * AnonContractQuery returns an AnonContractQuery used to get a
 * specific anon contract or error
 * @param addr
 * @param ch
 * @returns
 */
export const AnonContractQuery = (
  addr: Address<Network> | string,
  ch: ChainType
): AnonContractQueryType<ChainType> =>
  addr ? `χcd:con:@${ch}/${addr.toString()}` : undefined;

/**
 * AllAnonContractsQuery returns an AllAnonContractQueryType used to get
 * the list of all the same contracts on every chains
 * @param addr
 * @returns
 */
export const AllAnonContractsQuery = (
  addr: string
): AllAnonContractQueryType =>
  isStringAddress(addr) ? `χcon.anon:${getStringAddress(addr)}` : undefined;

/**
 * HashAnonContractQuery returns an AnonContractQueryType<C> used to get
 * a given contract from a tx hash and a chain
 * @param addr
 * @returns
 */
export const HashAnonContractQuery = <C extends ChainType>(
  addr: string,
  ch: C
): AnonContractQueryType<C> =>
  typeof addr === "string" &&
  addr?.length === 66 &&
  addr?.startsWith("0x") &&
  ch
    ? `χcon.hash:${ch}|${addr}`
    : undefined;

/**
 * @deprecated
 * @param addr
 * @param ch
 * @returns
 */
// @todo retrieve from datacache (with bloom filter)
export const is_contract_addr = async (addr: string, ch: ChainType) => {
  if (!addr || !ch) return false;
  // const provider = await get_viem_public_provider(ch);
  // const bytecode = await provider.getBytecode({ address: addr as Address });
  // return bytecode !== "0x";
  return true;
};

/**
 * is_contract checks if the input is a contract query.
 *
 * @description This function does not resolve the contract from an address.
 * @param q
 * @returns
 */
export const isContract = (q: unknown): q is ContractQueryType =>
  typeof q === "string" &&
  (q.startsWith("con:") ||
    q.startsWith("nft:") ||
    q.startsWith("swap:") ||
    q.startsWith("stake:"));

export const isAnonQuery = (q: string): q is AnonContractQueryType<ChainType> =>
  typeof q === "string" && (q.startsWith("χcd:con:") || q.startsWith("con:@"));

/**
 * is_anon_contract verifies if the contract is anonymous
 * @param con
 * @returns
 */
export const is_anon_contract = (con: SmartContract | OKToken): boolean =>
  !con?.id;

export const contract_org_query = (q: ContractQueryType) => {
  if (!(typeof q === "string")) return;
  const org_name = q
    ?.replace("con:", "")
    .substring(0, q.replace("con:", "").indexOf("/"));
  if (org_name?.startsWith("@")) return;
  return OrgQuery(org_name);
};

export const contractQueryFromOrgID = (org: string, id: string) =>
  org.startsWith("@") && isStringAddress(id)
    ? AnonContractQuery(id, org.slice(1))
    : ContractQuery(`${org}/${id}`);

export const paramsFromContractQuery = (
  q: ContractQueryType
): { org: string; id: string; met?: string } => {
  const l = q.replace("con:", "").split("/");
  if (l.length < 2) throw new Error("query");
  return { org: l[0], id: l[1], met: l[2] };
};
