<script lang="ts">
  import { truncated_addr } from "@scv/utils";

  import { ThemeAccent, getTheme } from "@okcontract/uic";

  import CopyToClipboard from "./CopyToClipboard.svelte";

  export let text: string;
  export let style = false;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;
</script>

<div
  class="flex gap-1 items-center {style
    ? `${theme.dark(
        $compiledTheme,
        'badge badge-outline text-white opacity-70',
        'badge badge-outline text-black opacity-70',
        'badge badge-secondary'
      )}`
    : `${theme.dark($compiledTheme, 'text-white', 'text-black', '')}`}"
  style={theme.apply($compiledTheme, [ThemeAccent])}
>
  <div class="overflow-hidden" title={text}>
    {truncated_addr(text)}
  </div>
  <CopyToClipboard content={text} />
</div>
