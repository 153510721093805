import {
  type CacheQueryFromType,
  type CachedData,
  type WritableDataType,
  link_of_cache_query
} from "@okcontract/coredata";
import type { OKCore } from "@okcontract/sdk";
import { navigate } from "@scv/routing";

import { errors } from "./store";

/**
 * fail dispatches an error to the errors Store and returns it.
 * @param err
 * @returns
 */
export const fail = (err: Error) => {
  errors.set(err);
  // console.error(err);
  return err;
};

export const delete_data = async <T extends WritableDataType>(
  core: OKCore,
  ty: T,
  q: CacheQueryFromType<T>,
  other?: (cd: CachedData<T>) => void | Promise<void>
) => {
  const conn = await core.Connector.get();
  const sig = await conn.signMessage(`delete:${q}`);
  try {
    const cd = await core.Cache.delete(ty, q, sig);
    if (other !== undefined) other(cd);
    const url = link_of_cache_query(cd.q, "delete");
    if (url) setTimeout(() => navigate({ to: url }), 250);
  } catch (error) {
    return fail(error);
  }
};

/**
 * @todo this becomes more complex
 * @todo move to coredata, this is specific to the use case
 **/
export const remove_from_part_wid_query = (core: OKCore) => {
  const queryCache = core.Cache;
  const part_queries = queryCache.keys.filter((k) =>
    k?.startsWith("part:widget")
  );
  // @todo this create a new WrappedCell
  for (const q of part_queries)
    if (queryCache.cell(q)) {
      // @todo remove local cache
      // queryCache.delete("widget", query);
      // cache[query].q = all?.[query]?.data?.q.filter((v) => v !== wQuery);
    }
};
