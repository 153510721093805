// import { CoinbaseWalletConnector } from "@wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "@wagmi/connectors/injected";
import { MetaMaskConnector } from "@wagmi/connectors/metaMask";
import type { AbiFunction } from "viem";

import type {
  AnyCell,
  CellArray,
  MapCell,
  SheetProxy,
  ValueCell
} from "@okcontract/cells";
import type { CacheQuery } from "@okcontract/coredata";
import type { Environment } from "@okcontract/lambdascript";
import {
  type Address,
  type Chain,
  type ChainType,
  EVMNetwork,
  type Network,
  Starknet,
  type StringAddress,
  TON,
  isAddress
} from "@okcontract/multichain";
import type { Role, SignedMessage } from "@scv/auth";
import type { LocalSubscriber } from "@scv/cache";

import { ArgentConnector } from "./argent";
import { type AppID, DISCORD_APP } from "./constants";
import type { CompiledContract } from "./deploy";
import { balance_of, formatBig } from "./erc20";
import type { StarknetWindowObject } from "./starknet";
import { ViemConnector } from "./viem";

// export type Network = EVMType | StarkNetType | SolanaType | TONType;

export const METAMASK = "metamask";
export const INJECTED = "injected";
export const COINBASE = "coinbase";
export const LEDGER = "ledger";
export const SAFE = "safe";
export const MOCK = "mock";
export const WALLET_CONNECT = "walletConnect";
export const ARGENT = "argent";
export const RABBY = "rabby";
export const BRAVE = "brave";
export const TONW = "ton";

export const connectorChain = {
  [METAMASK]: EVMNetwork,
  [INJECTED]: EVMNetwork,
  [ARGENT]: Starknet,
  [RABBY]: EVMNetwork,
  [BRAVE]: EVMNetwork,
  [TONW]: TON,
  [SAFE]: EVMNetwork,
  [LEDGER]: EVMNetwork, // @todo multiple networks?
  [COINBASE]: EVMNetwork
};

export type WalletConnector = keyof typeof connectorChain;

export type connectorType<W extends WalletConnector> =
  (typeof connectorChain)[W];

export type WalletDetail = {
  conn: WalletConnector;
  name: AnyCell<string>;
  addr: MapCell<Address<Network>, true>;
  isVerified: AnyCell<boolean>;
  role?: AnyCell<Role>;
  /** anotherForeign */
  aF: AnyCell<boolean>;
};

// @todo merge with OKConnector?
export interface OKProvider {
  on(event: string, callback: (...args: unknown[]) => void): void;
  request(input: {
    method: string;
    params: unknown[];
  }): Promise<unknown>;
}

export type Transaction<N extends Network> = {
  chain: ChainType;
  value: bigint;
  to: StringAddress<N>;
  data: `0x${string}`;
};

// move to @scv/auth?
// @todo extend beyond EVM
export type SignerFunction = (msg: string) => Promise<`0x${string}`>;
export enum SignerSource {
  Real = 0,
  Generated = 1
}
export type Signer<N extends Network> = {
  acc: StringAddress<N>;
  fn: SignerFunction;
  src: SignerSource;
};
export const newSigner = <N extends Network>(
  acc: StringAddress<N>,
  fn: SignerFunction,
  src: SignerSource
): Signer<N> => ({ acc, fn, src });

export interface OKConnector<N extends Network> {
  readonly ID: WalletConnector;

  readonly Network: Network; // @todo list
  readonly Account: AnyCell<StringAddress<Network>>;
  // @todo string? @todo Cell
  readonly ChainId: AnyCell<number | bigint>;
  /** Current chain */
  readonly Chain: AnyCell<Chain>;
  /** supported chains */
  readonly Chains: AnyCell<Record<ChainType, Chain>>;
  readonly Addresses: AnyCell<StringAddress<N>[]>;

  // @todo do not expose?
  // on(event: string, callback: (...args: unknown[]) => void): void;
  // @todo or other provider, remove
  // getProvider(): Promise<OKProvider>;
  // @todo update

  // Connector Actions

  // @todo don't return anything since it updates cells
  connect(): Promise<{ account: StringAddress<N> }>;
  disconnect(): Promise<void>;
  // @todo harmonize chain inputs
  switchChain?(id: ChainType): Promise<ChainType>;

  // WalletClient

  signMessage: SignerFunction; // (msg: string): Promise<`0x${string}`>; // Signer
  signedMessage: (msg: string) => Promise<SignedMessage>;
  sendTransaction(tx: Transaction<N>): Promise<`0x${string}`>; // hash
  // WalletClient, optional
  deployContract?(
    comp: CompiledContract,
    fn: AbiFunction,
    env: Environment
  ): Promise<`0x${string}`>; // hash
  addChain?(id: ChainType): Promise<ChainType>;
  signTransaction?(tx: Transaction<N>): Promise<`0x${string}`>;
}

export const missingChain = (err: Error & { code?: number }) =>
  // Rabby
  err?.code === -32603 ||
  err?.code === 4902 || // MetaMask
  err.name === "ChainNotConfiguredForConnectorError";

export type ConnectorOptions = {
  local: LocalSubscriber<CacheQuery>;
  onConnect?: (account: StringAddress<Network>) => void;
  onDisconnect?: () => void;
};

export const connector = <W extends WalletConnector>(
  id: W,
  proxy: SheetProxy,
  chains: AnyCell<Record<ChainType, Chain>>,
  options?: ConnectorOptions
): OKConnector<connectorType<W>> => {
  console.log("connector", { id });
  switch (id) {
    case METAMASK:
      return new ViemConnector(
        proxy,
        chains,
        id,
        new MetaMaskConnector(),
        options
      ) as unknown as OKConnector<connectorType<W>>;
    case INJECTED:
    case RABBY:
    case BRAVE:
      return new ViemConnector(
        proxy,
        chains,
        id,
        new InjectedConnector(),
        options
      ) as unknown as OKConnector<connectorType<W>>;
    case ARGENT:
      return new ArgentConnector(proxy, chains) as unknown as OKConnector<
        connectorType<W>
      >;
  }
  return null;
};

//   id === METAMASK
//     ?
//     : id === INJECTED
//       ?
//       : // [COINBASE]: CoinbaseWalletConnector,
//         // @deprecated since Ledger SDK exploit
//         // [LEDGER]: LedgerConnector,
//         // @todo SafeConnector is crashing bun test
//         // [SAFE]: SafeConnector,
//         // [MOCK]: MockConnector,

//         // id === ARGENT
//         // ? ArgentConnector :
//         null;
// // [WALLET_CONNECT]: WalletConnectConnector

/**
 * value_of formats values, using decimals for totalSupply.
 * @todo other use of decimals?
 * @todo "MAX" if more than 10^40?
 * @todo 2 digits precision
 * @todo https://docs.ethers.io/v5/api/utils/display-logic/
 * @todo use formatBig
 */
export const value_of = (
  name: string,
  v: string | number | bigint,
  decimals = 18
) =>
  v &&
  (name === ".dec" || name === "totalSupply" || name === balance_of) &&
  decimals > 3
    ? formatBig(v, decimals)
    : v;

export const max_of = (v: number) => (v > 10e40 ? "MAX" : v);

export const isRealWalletID = (
  walletID: Address<Network> | "loading"
): walletID is Address<Network> => isAddress(walletID);

/**
 * walletID is the current wallet.
 * @todo should not init empty
 */
export const NO_WALLET = undefined;
export const LOADING_WALLET = "loading" as const;
// @todo use everywhere
export type WalletIDType = typeof LOADING_WALLET | Address<Network>;

// @todo remove
export const ALL_CHAINS = "ALL" as const;

// @todo return a list of WalletConnector
export const detectWallet = (appID?: AppID): WalletConnector => {
  if (appID === DISCORD_APP) return null;
  // if (typeof window.starknet !== "undefined") return ARGENT;
  if (typeof window.ethereum !== "undefined") {
    if (window.ethereum?.isBraveWallet || window.ethereum?.isRabby)
      return INJECTED;
    if (window.ethereum?.isMetaMask) return METAMASK;
    // if (window.ethereum?.isCoinbaseWallet) return COINBASE;
    return INJECTED;
  }
  return null;
};

export const detectWallets = (
  proxy: SheetProxy,
  appID?: AppID
): CellArray<WalletConnector> => {
  const l: ValueCell<WalletConnector>[] = [];
  const add = (c: WalletConnector) => {
    l.push(proxy.new(c, c));
  };
  if (appID === DISCORD_APP) return proxy.new(l, "dW.skip");
  if (typeof window.ethereum !== "undefined") {
    if (window.ethereum?.isBraveWallet) {
      add(BRAVE);
    } else if (window.ethereum?.isRabby) add(RABBY);
    else if (window.ethereum?.isMetaMask) add(METAMASK);
    // if (window.ethereum?.isCoinbaseWallet) return COINBASE;
    else add(INJECTED);
  }
  if (
    typeof (window as unknown as { starknet?: StarknetWindowObject })
      .starknet !== "undefined"
  )
    add(ARGENT);
  // @todo TON
  return proxy.new(l, "dW");
};

export const CODE_REQUIRED = "code" as const;
export const AUTHENTICATING = "loading" as const;
