import type { AnyCell, MapCell, SheetProxy } from "@okcontract/cells";
import type {
  CacheQuery,
  ContractQueryType,
  OKWidgetStep,
  OKWidgetStepType,
  SmartContract
} from "@okcontract/coredata";
import {
  type Address,
  type ChainAddress,
  type ChainType,
  ContractType,
  type LocalRPCSubscriber,
  type Network,
  isRealAddr
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";

import { getABI } from "./abi";
import { retrieveAddress } from "./address";
import type { AnyAddress } from "./types";

export const contractQuery = (step: OKWidgetStep<OKWidgetStepType>) =>
  step?.q as ContractQueryType;

// @todo retrieve from datacache (with bloom filter)
export const is_contract_addr = async (addr: string, ch: ChainType) => {
  if (!addr || !ch) return false;
  // const provider = await get_viem_public_provider(ch);
  // const bytecode = await provider.getBytecode({ address: addr as Address });
  // return bytecode !== "0x";
  return true;
};

// export const isAnonQuery = (q: string): q is AnonContractQueryType<ChainType> =>
//   typeof q === "string" && q.startsWith("χcd:con:");

// export const new_contract = async (
//   schema: TypeScheme,
//   wid: string,
//   con?: SmartContract
// ): Promise<SmartContract> => {
//   const empty = await emptyValueOfTypeDefinition(schema, {
//     object: {
//       contract: () => ({ label: "Contract", name: "Contract" }),
//     },
//   });
//   return {
//     ...empty.contract,
//     from: wid,
//     ...con,
//   };
// };

/**
 * contract_address returns the address on a given contract.
 * @param con
 * @returns
 * @todo check current chain matches?
 */
export const contractAddress = (
  proxy: SheetProxy,
  con: AnyCell<SmartContract>,
  ch: AnyCell<ChainType>
): MapCell<Address<Network>, true> =>
  proxy.map([con, ch], (_con, _ch) => {
    const addr = _con?.addr.find((_addr) => _addr.chain === _ch);
    if (!addr)
      throw new Error(
        `contractAddress: address not found for contract ${_con.id} on chain ${_ch} `
      );
    return isRealAddr(addr) ? addr.addr : null;
  });

// /**
//  * is_anon_contract verifies if the contract is anonymous
//  * @param con
//  * @returns
//  */
// export const is_anon_contract = (con: SmartContract): boolean => !con?.id;

// export const contract_org_query = (q: ContractQueryType) => {
//   const org_name = q
//     ?.replace("con:", "")
//     .substring(0, q.replace("con:", "").indexOf("/"));
//   if (org_name?.startsWith("@")) return;
//   return OrgQuery(org_name);
// };

// export const get_contract_query_type = (
//   org: string,
//   id: string,
//   chain?: ChainType
// ) =>
//   org.startsWith("@")
//     ? AnonContractQuery(id, chain)
//     : ContractQuery(`${org}/${id}`);

/**
 * call any smart contract method.
 * @param ch chain
 * @param con contract, or token or address
 * @param meth method (as string)
 * @param params
 * @todo move out from erc20
 */
export const callMethod = async <Args extends AnyCell<unknown>[]>(
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  chain: AnyCell<ChainType>,
  q: AnyCell<AnyAddress>,
  meth: AnyCell<string>,
  args: AnyCell<Args>
) => {
  const proxy = rpc._proxy;
  const addr = retrieveAddress(proxy, local, chain, q);
  const evmAddr = proxy.map(
    [chain, addr],
    (_ch, _addr) =>
      (addr
        ? {
            addr: _addr,
            chain: _ch,
            ty: ContractType
          }
        : null) as ChainAddress | null
  );
  const abi = getABI(proxy, local, evmAddr);
  return rpc.call(
    evmAddr,
    abi.map((_abi) => _abi.parsed),
    meth,
    args,
    // @todo noFail should be a separate cell, name not a cell
    proxy.new({ name: "call", noFail: true })
  );
};
