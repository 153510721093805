import { encodeAbiParameters, encodePacked } from "viem";

import {
  type AnyCell,
  type CellArray,
  type CellObject,
  type SheetProxy,
  cellify,
  jsonStringify,
  mapArray,
  reduceObject,
  uncellify
} from "@okcontract/cells";
import {
  type ABI,
  type CacheQuery,
  type NFTQueryType,
  type OKWidgetStepType,
  TokenQuery,
  isContract,
  isToken
} from "@okcontract/coredata";
import {
  type ConstantNode,
  type Environment,
  type FirstClassValue,
  NameConstant,
  NameLambda,
  NameObject,
  type ParserExtension,
  Rational,
  type StandardLibrary,
  type TypeScheme,
  newTypeConst,
  typeAny,
  typeBytes,
  typeList,
  typeNumber,
  typeString
} from "@okcontract/lambdascript";
import {
  type Address,
  AddressEVM,
  type ChainAddress,
  type ChainType,
  type Network,
  isAddress,
  isStringAddress,
  nativeAddr,
  nullAddr
} from "@okcontract/multichain";
import { getJSON } from "@scv/auth";

import { makeABI } from "./abi";
import type { AnalyzedLog } from "./analyze";
import {
  erc721,
  erc721Enumerable,
  getPropertyValue,
  get_owned_nfts
} from "./erc721";
import type { OKPage } from "./instance";
import {
  EnvKeyABI,
  EnvKeyAnalyzedLogs,
  EnvKeyChain,
  EnvKeyOKPage,
  EnvKeySelf,
  EnvKeySlippage,
  EnvKeyValidity,
  EnvKeyWalletID
} from "./keys";
import type { Step } from "./steps";
import type {
  AnyAddress,
  TokenQueryOrAddress,
  TokenQueryType,
  URLQueryType
} from "./types";

// @todo import from schema
// type LabelledTypeDefinition = any;

// const param_collection: LabelledTypeDefinition = {
//   label: "Collection",
//   help: "ID for the collection",
//   base: "string",
//   search: "nft",
// };

// /**
//  * @todo restrict to range?
//  */
// const param_id: LabelledTypeDefinition = {
//   label: "ID",
//   help: "ID of the asset within the collection",
//   base: "number",
// };

export const getEnvInstance = (env: Environment) =>
  env.value(EnvKeyOKPage) as unknown as OKPage;

// Address type
export const typeAddress = newTypeConst("address");

export const newAddress = (addr: string | bigint): ConstantNode<unknown> => ({
  type: NameConstant,
  value: new AddressEVM(addr)
});

export const BaseValuesTypeScheme: { [key: string]: TypeScheme } = {
  [EnvKeyChain]: { vars: [], type: typeString },
  [EnvKeyWalletID]: { vars: [], type: typeAddress },
  [EnvKeyAnalyzedLogs]: {
    vars: [],
    type: {
      kind: "lst",
      elementType: {
        kind: "lst",
        elementType: { kind: "var", type: "AnalyzedLog" } // @todo define...
      }
    }
  },
  [EnvKeyABI]: { vars: [], type: { kind: "var", type: "ABI" } } // @todo define...
};

/**
 * web3Library defines a λs Context using OKcontract parameters.
 * This defines access to coredata, etc.
 * @param g
 * @todo subscriber ?
 * @returns
 */
export const web3Library = (libproxy: SheetProxy): StandardLibrary => {
  return {
    $null: {
      f: libproxy.new(nullAddr),
      t: { vars: [], type: typeAddress },
      doc: "null address (0x00...)"
    },
    $native: {
      f: libproxy.new(nativeAddr),
      t: { vars: [], type: typeAddress },
      doc: "native chain address, a virtual address for the native token"
    },
    "@": {
      f: libproxy.new((env: Environment, q: AnyCell<CacheQuery>) => {
        const instance = env.value(EnvKeyOKPage) as unknown as OKPage;
        return instance.local.unwrappedCell(q);
      }),
      doc: "Evaluates query",
      t: {
        vars: ["@todo"],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: { kind: "var", type: "@todo" }
        }
      }
    },
    // @todo nft, contract
    "@token": {
      f: libproxy.new((env: Environment, q: AnyCell<CacheQuery>) => {
        const instance = env.value(EnvKeyOKPage) as unknown as OKPage;
        return instance.local.unwrappedCell(q.map((s) => TokenQuery(s), "tq"));
      }),
      doc: "Retrieves a token",
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: typeAddress // @todo structured type with implicit conversion
        }
      }
    },
    $amount: {
      // @todo maybe not the same proxy to reuse standard lib cells?
      f: libproxy.new(
        (
          env: Environment,
          v: AnyCell<string | number | bigint>,
          tok: AnyCell<TokenQueryOrAddress>,
          show_symbol: AnyCell<boolean>
        ) => {
          const instance = getEnvInstance(env);
          return instance._formatAmount(
            v,
            tok,
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            show_symbol
          );
        }
      ),
      doc: "Formats an amount value",
      // args: [
      //   { label: "Amount", base: "number" },
      //   { label: "Token", hint: "Address or ID", base: "string" },
      //   { label: "Show symbol", base: "boolean", optional: true },
      // ],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          // argTypes: [typeNumber, typeString, typeBoolean],
          argTypes: [typeNumber, typeAddress],
          returnType: typeString
        }
      }
    },
    // @todo changed from $BALANCE
    $formatBalance: {
      f: libproxy.new((env: Environment, tok: AnyCell<TokenQueryOrAddress>) => {
        const instance = getEnvInstance(env);
        return instance._formatBalance(
          env.value(EnvKeySelf) as AnyCell<Address<Network>>,
          env.value(EnvKeyChain) as AnyCell<ChainType>,
          tok
        );
      }),
      doc: "Get the user balance of a given token",
      // args: [{ label: "Token", base: "string", isAddress: true }],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: typeString
        }
      }
    },
    // @todo changed from $RAWBALANCE
    $balance: {
      f: libproxy.new((env: Environment, tok: AnyCell<TokenQueryOrAddress>) => {
        const instance = getEnvInstance(env);
        return instance
          ._rawBalance(
            env.value(EnvKeySelf) as AnyCell<Address<Network>>,
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            tok
          )
          .map((arr) => arr?.[0]);
      }),
      doc: "Get the raw user balance of a given token",
      // args: [{ label: "Token", base: "string", isAddress: true }],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: typeNumber
        }
      }
    },
    $symbol: {
      f: libproxy.new((env: Environment, tok: AnyCell<TokenQueryOrAddress>) => {
        const instance = getEnvInstance(env);
        return instance
          ._rawBalance(
            env.value(EnvKeySelf) as AnyCell<Address<Network>>,
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            tok
          )
          .map((arr) => arr?.[2]);
      }),
      doc: "Get the symbol of a given token",
      // args: [{ label: "Token", base: "string", isAddress: true }],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAddress],
          returnType: typeString
        }
      }
    },
    $decimals: {
      f: libproxy.new((env: Environment, tok: AnyCell<TokenQueryOrAddress>) => {
        const instance = getEnvInstance(env);
        return instance
          ._rawBalance(
            env.value(EnvKeySelf) as AnyCell<Address<Network>>,
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            tok
          )
          .map((arr) => arr?.[1]);
      }),
      doc: "Get the decimals of a given token",
      // args: [{ label: "Token", base: "string", isAddress: true }],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAddress],
          returnType: typeNumber
        }
      }
    },
    // @todo switch to native contract read
    $call: {
      f: libproxy.new(
        (
          env: Environment,
          addr: AnyCell<AnyAddress>,
          meth: AnyCell<string>,
          // @todo ...args ?
          args: AnyCell<AnyCell<unknown>[]>
        ) => {
          if (!meth) return;
          const instance = getEnvInstance(env);
          const chain = env.value(EnvKeyChain) as AnyCell<ChainType>;
          // @security: we should not accept raw addresses
          // const rArgs = instance._retrieveAllAddresses(chain, args);
          const rArgs = args;
          return instance._callMethod(chain, addr, meth, rArgs);
        }
      ),
      doc: "Call a contract method with optional arguments",
      // args: [
      //   { label: "Contract or Token", base: "string", isAddress: true },
      //   // @todo enum of contract or token methods
      //   { label: "Method name", base: "string" },
      // ],
      // xargs: { label: "Argument", any: true },
      t: {
        vars: ["res"],
        type: {
          kind: NameLambda,
          // @todo typeAny should be typeAddress but that requires facets
          argTypes: [typeAny, typeString, typeAny],
          returnType: { kind: "var", type: "res" }
        }
      }
    },
    // @todo
    // since the type depends on the method, we should have cells for types
    // $method: {
    // },
    $address: {
      f: libproxy.new((env: Environment, q: AnyCell<AnyAddress>) => {
        const instance = getEnvInstance(env);
        return instance._retrieveAddress(
          env.value(EnvKeyChain) as AnyCell<ChainType>,
          q
        );
      }),
      doc: "Retrieves the address of a token, contract",
      // args: [
      //   { label: "Token or contract query", base: "string", search: "token" },
      // ],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAny],
          returnType: typeAddress
        }
      }
    },
    $get: {
      // @todo subscribe?
      f: libproxy.new(
        async <Q extends CacheQuery>(env: Environment, q: AnyCell<Q>) => {
          const instance = getEnvInstance(env);
          const res = instance.local.unwrappedCell(q);
          return cellify(env.proxy, await res.consolidatedValue);
        }
      ),
      doc: "Get data from OKcontract",
      // args: [{ label: "Query", base: "string" }],
      t: {
        // vars: ["res"],
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          // returnType: { kind: "var", type: "res" },
          returnType: typeAny
        }
      }
    },
    $image: {
      f: libproxy.new(
        (
          env: Environment,
          coll: AnyCell<Address<Network>>,
          id: AnyCell<number>,
          px?: string
        ) => {
          const instance = getEnvInstance(env);

          return instance._tokenImage(
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            coll,
            id,
            px
          );
        }
      ),
      doc: "Retrieve the image URL for a NFT",
      // args: [
      //   param_collection,
      //   param_id,
      //   { label: "Proxy address", base: "string", optional: true },
      // ],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [
            typeAddress,
            typeNumber
            // { ...typeString, optional: true }, // @todo
          ],
          argVariadic: typeString, // @todo only one?
          returnType: typeString
        }
      }
    },
    $fetch: {
      f: libproxy.new(
        async (env: Environment, q: AnyCell<URLQueryType>, path: string) => {
          const instance = getEnvInstance(env);
          const url = instance.local.unwrappedCell(q);
          // @todo @security sending the cookie token!
          return url.map((url) =>
            url?.v ? getJSON(`${url.v}/${path}`) : null
          );
        }
      ),
      doc: "Fetch JSON response from a verified API",
      // args: [
      //   { label: "Service query", base: "string" },
      //   { label: "Path", base: "string" },
      // ],
      t: {
        vars: ["res"],
        type: {
          kind: NameLambda,
          argTypes: [typeString, typeString],
          returnType: { kind: "var", type: "res" } // @todo Object?
        }
      }
    },
    $logsearch: {
      f: libproxy.new(
        <
          Prop extends string | null,
          Emitter extends AnyAddress | null,
          Filter extends {
            [k: string]: string | Address<Network> | number | bigint;
          } | null
        >(
          env: Environment,
          step: AnyCell<number>,
          event: AnyCell<string>,
          prop: AnyCell<Prop> = libproxy.new(null),
          emitter: AnyCell<Emitter> = libproxy.new(null),
          filter: AnyCell<Filter> = libproxy.new(null)
        ) => {
          const instance = getEnvInstance(env);
          return instance.logSearch(
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            env.value(EnvKeyAnalyzedLogs) as AnyCell<AnalyzedLog[][]>,
            step,
            event,
            prop,
            emitter,
            filter
          );
        }
      ),
      t: {
        vars: ["res"],
        type: {
          kind: NameLambda,
          argTypes: [typeNumber, typeString, typeString, typeAddress],
          // @todo We cheat by having the 5th optional argument as variadic.
          argVariadic: { kind: "obj", fields: {}, open: true },
          returnType: { kind: "var", type: "res" } // @todo string|number?
        }
      },
      doc: "Search a value in $log"
    },
    $tokenURI: {
      f: libproxy.new(
        (
          env: Environment,
          coll: AnyCell<Address<Network>>,
          id: AnyCell<number>,
          proxy?: string
        ) => {
          const instance = getEnvInstance(env);
          return instance._tokenURI(
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            coll,
            id,
            proxy
          );
        }
      ),
      doc: "Returns the tokenURI as a JSON of a NFT",
      // args: [param_collection, param_id],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAddress, typeString],
          returnType: typeString
        }
      }
    },
    $ownerof: {
      f: libproxy.new(
        (
          env: Environment,
          coll: AnyCell<NFTQueryType | Address<Network>>,
          id: number
        ) => {
          const instance = getEnvInstance(env);
          return instance._ownerOf(
            env.value(EnvKeyChain) as AnyCell<ChainType>,
            coll,
            id
          );
        }
      ),
      doc: "Returns the owner address of a NFT",
      // args: [param_collection, param_id],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAddress, typeString],
          returnType: typeAddress
        }
      }
    },
    $prop: {
      f: libproxy.new(getPropertyValue),
      doc: "Retrieves a property from an ERC721 metadata",
      // args: [
      //   ERC721MetadataDefinition,
      //   { label: "Key", base: "string", hint: "Key in metadata" },
      // ],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: typeString // @todo check?
        }
      }
    },
    $abiaddr: {
      f: libproxy.new((env: Environment) =>
        env.value(EnvKeyABI)?.map((v) => (v as ABI)?.addr?.addr)
      ),
      doc: "Retrieves current ABI address",
      // args: [],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [],
          returnType: typeAddress
        }
      }
    },
    $tokenbyowner: {
      f: libproxy.new(
        (
          env: Environment,
          collection: AnyCell<Address<Network>>,
          owner: AnyCell<Address<Network>>
        ) => {
          const instance = getEnvInstance(env);
          const chain = env.value(EnvKeyChain) as AnyCell<ChainType>;
          const addr = instance.proxy.map(
            [chain, collection],
            (_chain, _coll) =>
              ({
                chain: _chain,
                addr: _coll,
                ty: "c"
              }) as ChainAddress
          );
          const abiRetrieved = instance._getABI(addr);
          const abi = instance.proxy.map(
            [chain, collection, abiRetrieved],
            (_chain, _coll, _abi) =>
              _abi === null
                ? makeABI(_chain, _coll, [...erc721, ...erc721Enumerable])
                : _abi
          );
          const parsedAbi = abi.map((_abi) => {
            if (_abi?.parsed) return _abi?.parsed;
            throw new Error("Abi not found");
          });
          const evm = abi.map((_abi) => _abi.addr);
          // @todo Abi can't be null
          return get_owned_nfts(instance.rpc, evm, parsedAbi, owner) || [];
        }
      ),
      doc: "Retrieves an array of owned nfts",
      // args: [
      //   { label: "collection", base: "string" },
      //   { label: "owner", base: "string" },
      // ],
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeAddress, typeString],
          returnType: { kind: "lst", elementType: typeAddress }
        }
      }
    },
    $pack: {
      f: libproxy.new(
        (
          env: Environment,
          t: AnyCell<string[]>,
          v: AnyCell<string[] | Address<Network>[]>
        ) => {
          const instance = getEnvInstance(env);
          const resolved = instance.proxy.map([t, v], (_t, _v) =>
            Promise.all(
              _v.map((x) =>
                isContract(x) || isToken(x)
                  ? // @todo remove proxy.new wrapping
                    instance._retrieveAddress(
                      env.value(EnvKeyChain) as AnyCell<ChainType>,
                      instance.proxy.new(x)
                    )
                  : x
              )
            )
          );
          return instance.proxy.map([t, resolved], (_t, _resolved) =>
            encodePacked(_t, _resolved)
          );
        }
      ),
      doc: "encode values",
      // args: [],
      // xargs: { label: "value", any: true },
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeList(typeString), typeList(typeAny)],
          returnType: typeBytes
        }
      }
    },
    $slip: {
      f: libproxy.new(
        (env: Environment, v: AnyCell<Rational>): AnyCell<Rational> => {
          const instance = getEnvInstance(env);
          return instance.proxy.map(
            [v, env.value(EnvKeySlippage) as AnyCell<Rational>],
            (_v, _slip) =>
              _v
                ?.multiply(new Rational(100).subtract(_slip))
                ?.divide(new Rational(100)) || new Rational(0)
          );
        }
      ),
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeNumber],
          returnType: typeNumber
        }
      },
      doc: "apply the default slippage parameter to any value"
    },
    $deadline: {
      f: libproxy.new((env: Environment): AnyCell<Rational> => {
        const validity = env.value(EnvKeyValidity) as AnyCell<Rational>;
        return validity.map((_v) =>
          new Rational(Date.now() / 1000).add(_v.multiply(new Rational(60)))
        );
      }),
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [],
          returnType: typeNumber
        }
      },
      doc: "computes a deadline that is valid for _v_ minutes from the current date"
    },
    $attestref: {
      f: libproxy.new(
        <T, U>(env: Environment, queryID: AnyCell<string>): AnyCell<string> =>
          queryID.map((_queryID) =>
            encodeAbiParameters(
              [{ name: "queryID", type: "string" }],
              [_queryID]
            )
          )
      ),
      doc: "Create an attestation reference",
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [typeString],
          returnType: typeString
        }
      }
    },
    $attest: {
      f: libproxy.new(
        <T, U>(env: Environment, wid: CellObject<T>): AnyCell<string> => {
          const reduced = reduceObject(
            env.proxy,
            wid,
            async (acc, k, v, vc) => {
              switch (k) {
                case "id":
                case "org":
                case "name":
                case "dom":
                case "ibc":
                case "tbc": {
                  return { ...acc, [k]: v };
                }
                case "own": {
                  return { ...acc, [k]: (v as Address<Network>).toString() };
                }
                case "ver": {
                  return { ...acc, [k]: (v as Rational).toBigInt() };
                }
                case "values": {
                  return { ...acc, [k]: await computeHash(v) };
                }
                case "st": {
                  return {
                    ...acc,
                    [k]: mapArray(
                      env.proxy,
                      vc as CellArray<unknown>,
                      async (v) => {
                        return computeHash(
                          await uncellify(v, {
                            getter: (cell) => cell.consolidatedValue
                          })
                        );
                      }
                    )
                  };
                }
                default: {
                  return acc;
                }
              }
            },
            {}
          );

          // get list of contract queries
          const cons = wid.map((_wid) => {
            return mapArray(
              env.proxy,
              _wid.st as unknown as CellArray<Step<OKWidgetStepType>>,
              (v) => computeHash(v.q)
            );
          });
          // add list of cons to eas schema
          const toSchema = env.proxy.map([reduced, cons], (_reduced, _cons) =>
            uncellify(
              {
                ..._reduced,
                cons: _cons
              },
              {
                getter: (cell) => cell.consolidatedValue
              }
            )
          );

          // @todo get from eas directly
          const easSchema = Object.entries({
            id: "string",
            org: "string",
            ver: "int256",
            name: "string",
            own: "address",
            dom: "string[]",
            ibc: "string",
            tbc: "string",
            values: "string",
            st: "string[]",
            cons: "string[]"
          });
          const optionals = [
            { key: "tbc", value: "" },
            { key: "ibc", value: "" },
            { key: "values", value: "" },
            { key: "dom", value: [] },
            { key: "org", value: "" }
          ];

          // list of solidity types
          const sTypes = easSchema.map((_i) => ({ name: _i[0], type: _i[1] }));

          // list of schema values
          const sKeys = easSchema.map((_i) => _i[0]);
          const sValues = env.proxy.map([toSchema], (_toSchema) =>
            sKeys.map((_k) =>
              _toSchema[_k]
                ? _toSchema[_k]
                : optionals.find((opt) => opt.key === _k).value
            )
          );

          // encode the schema
          return env.proxy.map([sValues], (values) =>
            encodeAbiParameters(sTypes, values)
          );
        },
        "$attest"
      ),
      doc: "Attest an interaction",
      t: {
        vars: [],
        type: {
          kind: NameLambda,
          argTypes: [
            { kind: NameObject, fields: {}, open: true, label: "interaction" }
          ],
          returnType: typeString
        }
      }
    }
  };
};

//@todo overload TypeFromJSValue with address and rational
export const address: FirstClassValue = {
  name: "Address",
  is: (expr: unknown) => isAddress(expr),
  type: typeAddress
};

export const AddressExtension: ParserExtension<unknown, string> = {
  elt: "StringValue",
  pat: (value: string) =>
    typeof value === "string" && isStringAddress(value.replaceAll('"', "")),
  rewrite: (value: string) =>
    new AddressEVM(value.replaceAll('"', "") as string | bigint),
  instance: (value: unknown) => isAddress(value),
  type: typeAddress
};

const bufferToHex = (buffer: ArrayBuffer): string =>
  Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

export const computeHash = async (obj: unknown): Promise<string> => {
  const jsonString = jsonStringify(obj);
  const uint8Array = new TextEncoder().encode(jsonString);
  const buf = await crypto.subtle.digest("SHA-256", uint8Array);
  return bufferToHex(buf);
};
