<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  import { WalletAddress, newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { SearchAddress } from "@scv/selector";

  export let instance = newOKPage("AddressInput");
  // optional one click address.
  export let oneClick: [string, string] = undefined;
  export let search: string = undefined;
  export let removable = false;
  export let input = "";
  export let placeholder: string = undefined;
  export let isValid: boolean = true;
  export let disabled = false;

  const theme = getTheme();
  const compiled = theme.compiled;

  let touched = false;
  const update = (ev) => {
    if (!search) {
      input = (ev.target as any)?.value || ev.detail;
    } else {
      input = ev.detail;
    }
    touched = true;
    dispatch("update", input);
  };
  // $: console.log("AddressInput", { input, search });
</script>

<!-- FIXME: handle address search -->
<!--  v={input} -->
{#if search && search !== "address"}
  <SearchAddress
    {instance}
    on:update={update}
    {oneClick}
    {placeholder}
    removable
  />
{:else}
  <div
    class="input input-bordered {theme.dark(
      $compiled,
      'input-white-alpha',
      'input-black-alpha',
      ''
    )} {!isValid && touched
      ? 'border-error'
      : ''} input-md flex items-center gap-2"
  >
    <WalletAddress
      {instance}
      addrStr={input}
      style={true}
      {oneClick}
      {removable}
      {placeholder}
      truncate={false}
      {disabled}
      on:update={update}
    />
  </div>
{/if}
