const DEV = false;

import type { TransactionReceipt } from "viem";
import { generatePrivateKey } from "viem/accounts";

import {
  type AnyCell,
  type MapCell,
  Sheet,
  SheetProxy,
  type ValueCell,
  flattenCellArray,
  logger,
  mapArray,
  uncellify
} from "@okcontract/cells";
import {
  AllChainsQuery,
  type CacheDataFromType,
  type CacheQuery,
  type CacheQueryFromType,
  type CachedData,
  type ChainQueryType,
  type TypeFromCacheQuery,
  UserForeignQuery,
  UserQuery,
  VirtualAllForeignQuery,
  type WritableDataType
} from "@okcontract/coredata";
import {
  Environment,
  isEqual,
  newTypeScheme,
  typeNumber
} from "@okcontract/lambdascript";
import {
  type Address,
  type Chain,
  type ChainType,
  EVMNetwork,
  LocalRPCSubscriber,
  MultiChainRPC,
  type Network,
  NewAddress,
  type StringAddress,
  defaultRPCOptions,
  nullAddr
} from "@okcontract/multichain";
import {
  ADMIN,
  APIUser,
  AuthClient,
  type ChannelType,
  type ForeignAccount,
  GUEST,
  type JWTUser,
  type Role,
  type SignedMessage,
  TimeSync,
  type User,
  type UserAuth,
  cookie,
  jwtRole,
  parseJwt
} from "@scv/auth";
import {
  APICache,
  type APICacheInterface,
  GlobalSubscriber,
  LocalSubscriber,
  QueryCache,
  convertToAddressesAndRationals,
  convertToNativeAddressesAndBigInt,
  write_message
} from "@scv/cache";
import wasm from "@scv/libcrypto";
import { stripDefaultValues } from "@scv/utils";

import {
  type AppID,
  LOGGED,
  type LogState,
  UNLOGGED,
  VERIFYING
} from "./constants";
import { DefaultContracts } from "./defaultContract";
import { newEVMAccount } from "./evm";
import { EnvKeySlippage, EnvKeyValidity } from "./keys";
import { Lighthouse } from "./lighthouse";
import { findName } from "./name";
import { $slippage, $validity } from "./settings";
import { SyncCell } from "./syncCell";
import type { SentTransaction } from "./tx";
import {
  LOADING_WALLET,
  type OKConnector,
  type Signer,
  SignerSource,
  type WalletConnector,
  type WalletDetail,
  connector,
  connectorChain,
  detectWallets,
  missingChain,
  newSigner
} from "./wallet";

export const OKCoreKey = Symbol();

export type CoreOptions = {
  id: AppID;
  connector?: OKConnector<Network>;
  chain?: ChainType;
  sheet: Sheet;
  endpoint: string;
  fail: (err: Error) => Error;
};

const AuthCookieName = "okid";

export const baseOptions = (
  id: AppID,
  chain?: ChainType,
  endpoint?: string,
  sheet?: Sheet
): CoreOptions => ({
  id,
  chain,
  sheet: sheet || new Sheet(isEqual),
  endpoint: endpoint || "https://cache.okcontract.com",
  fail: (err) => {
    console.error(err);
    return err;
  }
});

/**
 * Core is the current Viem-based implementation of Core.
 * @param conn viem Connector
 * @returns
 * @todo use W for Wallet and R for RPC types
 */
export class OKCore {
  readonly AppID: string;
  readonly Sheet: Sheet;

  readonly Api: APICacheInterface;

  readonly IsSwitchingChain: AnyCell<boolean>;
  readonly IsSwitchingAccount: AnyCell<boolean>;
  /** list of connected wallets */
  readonly ConnectedWallets: AnyCell<Record<string, WalletDetail>>;
  readonly IsVerified: MapCell<boolean, false>;
  readonly IsAuth: MapCell<boolean, false>;
  /** list of verified wallets (from datacache) */
  // VerifiedWallets: AnyCell<AnyCell<WalletDetail>[]>;
  /** the current walletID */
  readonly WalletID: AnyCell<Address<Network>>;
  /** the current wallet account */
  // readonly WalletAccount: AnyCell<WalletDetail>;
  /** wallet name if any */
  readonly WalletName: AnyCell<string | null>;

  // Datacache

  readonly Cache?: QueryCache;
  readonly CacheOnce: <Q extends CacheQuery>(
    q: Q
  ) => Promise<CachedData<TypeFromCacheQuery<Q>>["data"]>;

  // RPC
  // @todo higher-level functions like CacheOnce for datacache...
  readonly MultiRPC: MultiChainRPC;

  // @todo or keep the CellArray?
  readonly DetectedConnectors: AnyCell<OKConnector<Network>[]>;
  readonly CurrentConnector: ValueCell<number>;
  readonly Connector: MapCell<OKConnector<Network>, true>;
  readonly ConnectorAccount: AnyCell<StringAddress<Network>>;

  readonly CurrentChain: MapCell<ChainType, true>;
  readonly Chain: AnyCell<Chain>;
  readonly IsAdmin?: MapCell<boolean, true>;
  readonly IsGuest?: MapCell<boolean, true>;
  readonly Role: MapCell<Role, boolean>;
  /**
   * SentTransactions is a an array of all the sent txs
   * @todo persist
   */
  readonly SentTransactions: ValueCell<SentTransaction[]>;
  /**
   * Receipts is an array of all receipts
   */
  readonly Receipts: ValueCell<TransactionReceipt[]>;
  readonly Settings: Environment;
  readonly UserID: MapCell<string, false>; // @todo rename OUI?
  readonly User: MapCell<User, false>;
  // readonly Account: MapCell<string, boolean>;
  // client / connector state
  readonly WantedWalletAccount: ValueCell<StringAddress<Network>>;
  readonly IsConnectWallet: MapCell<boolean, boolean>;
  readonly IsNoWallet: MapCell<boolean, boolean>;
  readonly Chains: AnyCell<Record<ChainQueryType, AnyCell<Chain>>>;
  readonly Auth: AnyCell<UserAuth | null>;
  readonly AllForeign: AnyCell<string[], false>; // @todo keep the type?
  // readonly IsShowCode: MapCell<boolean, boolean>;
  readonly Code: ValueCell<string>;
  readonly LogState: ValueCell<LogState>;
  readonly DefaultContracts: DefaultContracts;
  readonly Lighthouse: Lighthouse;
  readonly Master: MapCell<boolean, true>;

  private readonly _proxy: SheetProxy;
  private readonly _options: CoreOptions;
  private readonly _apiUser: APIUser;
  private readonly _parsedJWT: AnyCell<JWTUser | null>;
  private readonly _local: LocalSubscriber<CacheQuery>;
  private readonly _global: GlobalSubscriber<CacheQuery>;
  private readonly _chains: AnyCell<Record<ChainType, Chain>>;
  private readonly _syncAuth: SyncCell<UserAuth>;
  private readonly _Token: MapCell<string, true>;
  private readonly _firstConnectedAccount: MapCell<number, false>; // @todo true?
  // private readonly _fail: (err:Error)=>Error;

  constructor(options: CoreOptions) {
    this.AppID = options.id;
    this._options = options;
    // @todo already done in all cases (widget, app, etc.)?
    wasm();
    this.Sheet = options.sheet;
    const proxy = new SheetProxy(options.sheet, "Core");
    this._proxy = proxy;

    this._syncAuth = new SyncCell(
      proxy,
      "@ifi",
      "token",
      undefined,
      "syncAuth"
    );
    this.Auth = this._syncAuth.cell;
    this._Token = this.Auth.map((auth) => auth?.token || undefined, "token"); // we wait
    this.Master = this._syncAuth.first;

    // Datacache

    const client = new AuthClient(this._Token);
    this._apiUser = new APIUser(options.endpoint, options.id);
    this.Api = new APICache(
      client,
      convertToAddressesAndRationals,
      options.endpoint
    );
    const ts = new TimeSync(options.endpoint);
    this.Cache = new QueryCache(
      new SheetProxy(options.sheet, "cache"),
      this.Api,
      {
        ts
      }
    );
    this._global = new GlobalSubscriber(this.Cache);
    this.CacheOnce = this._global.once.bind(this._global);
    this._local = new LocalSubscriber(
      options.sheet,
      this._global,
      proxy,
      "core"
    );

    this.Chains = this._local
      .staticQuery(AllChainsQuery())
      .map(
        (v) =>
          Object.fromEntries(
            v?.res.map((_ch) => [_ch, this._local.staticQuery(_ch)])
          ),
        "Core.chains"
      ) as AnyCell<Record<string, AnyCell<Chain>>>;
    const uncellifiedChains = this.Chains.map(
      (ch) => uncellify(ch),
      "uncellifiedChains"
    ) as unknown as MapCell<Record<ChainType, Chain>, false>;
    DEV && logger(uncellifiedChains);
    // @todo we shouldn't uncellify twice :)
    // @todo or reuse uncellifiedChains
    this._chains = this.Chains.map(
      async (obj) =>
        uncellify(
          Object.fromEntries(
            Object.entries(obj).map((_chain) => [
              _chain[0].substring(1),
              _chain[1]
            ])
          ),
          {
            getter: (cell) => cell.consolidatedValue
          }
        ),
      "core._chains"
    ) as unknown as AnyCell<{ [key: string]: Chain }>;

    // MULTICHAIN

    this.MultiRPC = new MultiChainRPC(
      proxy,
      proxy.new({
        ...defaultRPCOptions(proxy),
        chains: this._chains,
        convertToNative: convertToNativeAddressesAndBigInt,
        convertFromNative: convertToAddressesAndRationals
      })
    );
    const rpc = new LocalRPCSubscriber(proxy, this.MultiRPC);
    const ethereum = proxy.new("ethereum", "ethereum");

    this.WantedWalletAccount = proxy.new<StringAddress<Network>>(
      // @todo undefined before being set?
      null, // LOADING_WALLET,
      "Core.wantedWalletAccount"
    );
    logger(this.WantedWalletAccount);

    /// CONNECTORS

    // @todo not sure: maybe should be mapped?
    const onConnect = (account: StringAddress<Network>) => {
      this.WantedWalletAccount.set(account);
    };
    // @todo move to next account
    const onDisconnect = () => {
      this.WantedWalletAccount.set(null);
      // this.ConnectorAccount.set(null);
    };
    // @todo list of connectors
    const connIds = detectWallets(proxy, options.id);
    const connectors = mapArray(
      proxy,
      connIds,
      (id) =>
        connector(id, proxy, this._chains, {
          onConnect,
          onDisconnect,
          local: this._local
        }),
      "connectors"
    );
    this.DetectedConnectors = flattenCellArray(proxy, connectors);
    this._firstConnectedAccount = this.DetectedConnectors.map((l) =>
      this._proxy.mapNoPrevious(
        l.map((conn) => conn.Account),
        (...acc) => acc.findIndex((v) => v !== null)
      )
    );

    this.CurrentConnector = proxy.new(0, "CC");
    this.Connector = proxy.map(
      [connectors, this.CurrentConnector],
      (l, cur) => l?.[cur] || null
    );
    logger(this.Connector);

    this.ConnectorAccount = this.Connector.map(
      (conn) => conn?.Account || null,
      ":acc"
    );
    // @todo remove?
    this.IsNoWallet = this.Connector.map((conn) => !conn, "Core.isNoWallet");

    // const chainId = this.Connector.map(
    //   (conn) => conn?.ChainId || null,
    //   "core:cid"
    // );
    // @todo or default chain?
    this.Chain = this.Connector.map((conn) => conn?.Chain || null, "core:ch");
    this.CurrentChain = this.Chain.map((ch) => ch?.id || null, "core:cur");
    this.IsSwitchingChain = proxy.new(false);

    this.ConnectorAccount.subscribe((acc) => {
      if (!(acc instanceof Error)) this.WantedWalletAccount.set(acc);
    });

    // this.WantedChain = proxy.new<ChainType>(null, "Core.wantedChain");

    // authentication
    this.LogState = proxy.new<LogState>(LOADING_WALLET, "Core.logState");

    this.Code = proxy.new<string>(".apifi", "Core.code");

    this.IsAuth = this.Auth.map((auth) => !!auth, "Core.isAuth");

    this._parsedJWT = this.Auth.map(
      (v) => (v ? parseJwt(v.token) : undefined),
      "parsedJWT"
    );

    // we don't save here
    const authCell = proxy.map(
      [
        this.Connector,
        this.ConnectorAccount,
        // this.Code,
        this._syncAuth.first
        // this._parsedJWT
      ],
      async (
        conn,
        acc,
        //code,
        ifi,
        // jwt,
        prev: UserAuth | null
      ) => {
        // @todo we should use acc directly
        // @todo investigate `cells` bug: the _acc values lags behind
        // console.log("check");
        // const acc = await conn.Account.get();
        // if (acc instanceof Error) return prev;

        console.log("auth", { conn, acc, prev, ifi });
        // authCell is only computed once, will be shared in the channel for non-masters.
        if (!ifi) return prev;
        if (prev?.acc && prev.acc === acc) return prev; // defined, no change

        // if (account === LOADING_WALLET) return prev;
        // if (!acc) return prev;

        if (acc) {
          // if (prev?.acc) {
          //   console.log("checking", { acc, prev: prev?.acc });
          //   // Is the new account already associated to the same user?
          //   const q = proxy.new(VirtualForeignQuery(acc));
          //   const v = await this._local.unwrappedCell(q).get();
          //   const jwt = await this._parsedJWT.get();
          //   if (jwt instanceof Error || !jwt) return prev;
          //   const id = jwt.id;
          //   console.log("check", { v, id });
          //   if (v && !(v instanceof Error)) {
          //     // Do we match the current user?
          //     if (v === id) {
          //       return prev;
          //     }

          //     // // Ask to change association
          //     // const data: ForeignAccount = {
          //     //   ty: conn.Network as ChannelType,
          //     //   id,
          //     //   f: acc
          //     // };
          //     // await this.Write("uf", data);
          //     // return;
          //   }
          // }

          // 1. Reuse previous token when available.
          // @todo check expiration
          const _cookie = cookie.get(AuthCookieName);
          console.log("auth", { conn, acc, prev, ifi, _cookie });
          // If we have a cookie and the previous was not temporary.
          if (_cookie && !prev?._t) {
            console.log("cookie found", parseJwt(_cookie));
            const auth = { token: _cookie, New: false, acc };
            // this._syncAuth.set(auth);
            return auth;
          }
          const sign = newSigner(acc, conn.signMessage, SignerSource.Real);
          try {
            const auth = this._verify2(sign);
            // this._syncAuth.set(auth);
            return auth;
          } catch (err) {
            console.log("authCell", { err, acc });
            return prev;
          }
        }

        // Create new account and client.
        const auth = this._verify2(await newEVMAccount(), true);
        // this._syncAuth.set(auth);
        return auth;
      },
      "authCell"
    );
    // logger(authCell);

    // const newAuth = async () => {
    //   const auth = this._verify2(await newEVMAccount());
    //   this._syncAuth.set(auth);
    //   return auth;
    // };
    // // authCell starts with a new account
    // const authCell = proxy.new(newAuth());

    // @todo does not subscribe...
    this._syncAuth.subscribeTo(authCell);

    // DEV && logger(this.Auth);

    // proxy.map(
    //   [this.WantedWalletAccount, this.Auth],
    //   (_walletAccount, auth) =>
    //     // _auth === AUTHENTICATING
    //     //   ? AUTHENTICATING
    //     //   :
    //     parseJwt(auth !== CODE_REQUIRED && auth?.token),
    //   "Core.parsedJWT"
    // );

    // @todo walletID can be null ?
    this.WalletID = proxy.map(
      [this.WantedWalletAccount, this.ConnectorAccount],
      (_wanted, acc) => {
        return acc
          ? NewAddress(acc)
          : // _wanted === LOADING_WALLET ||
            // || jwt === AUTHENTICATING
            !_wanted
            ? nullAddr
            : _wanted;
      },
      "Core.walletID"
    ) as AnyCell<Address<Network> | undefined | null>;

    // // @todo remove
    // this.IsShowCode = this.Auth.map(
    //   (auth) => auth === CODE_REQUIRED,
    //   "Core.isShowCode"
    // );
    this.IsConnectWallet = proxy.map(
      [this.IsNoWallet, this.WantedWalletAccount],
      (_isNoWallet, _walletAccount) => !_isNoWallet && !_walletAccount,
      "Core.ConnectWallet"
    );

    this.UserID = this._parsedJWT.map(
      (jwt) =>
        // jwt === AUTHENTICATING ? null :
        jwt?.id || null,
      "Core.userID"
    );
    const userQuery = this.UserID.map(UserQuery, "Core.userQuery");
    this.User = this._local.unwrappedCell(userQuery, "Core.user");

    this.Role = this._parsedJWT.map(jwtRole, "Role");
    logger(this.Role);
    this.DefaultContracts = new DefaultContracts(proxy, this._local);
    this.IsAdmin = this.Role.map<boolean, true>(
      (role) => role === ADMIN,
      "Core.isAdmin"
    );
    this.IsGuest = this.Role.map<boolean, true>(
      (role) => role === GUEST,
      "Core.isGuest"
    );

    // @todo Currently we will use cleartext addresses for simplicity, but we
    // should switch to hashes or even better zk-SNARK hash.
    const addressDetail = (
      conn: WalletConnector,
      key: StringAddress<Network>,
      prev: Record<string, WalletDetail> | undefined
    ) => {
      if (!key) return [undefined, undefined] as [string, WalletDetail];
      if (prev?.[key]) return [key, prev[key]];
      // console.log("walletAccounts", { _addr });
      const addr = proxy.new(
        convertToAddressesAndRationals(key),
        "addr"
      ) as unknown as MapCell<Address<Network>, true>;
      // @todo names for non-EVMs
      const name =
        connectorChain[conn] === EVMNetwork
          ? findName(rpc, proxy, this.DefaultContracts, ethereum, addr)
          : proxy.new(null); // @todo nullCell
      // // Token value is not used but to force recomputation of role.
      // const role = this._Token.map((_) => {
      //   const cki = cookie.get(key);
      //   const jwt = parseJwt(cki);
      //   console.log({ key, jwt });

      //   return jwtRole(jwt);
      // });

      // // @todo reimplement by checking the token
      // // @todo make sure it's reactive
      // const isVerified = role.map(
      //   (_role) => !!_role,
      //   "walletAccounts.isVerified"
      // );
      const foreignQuery = this._proxy.mapNoPrevious(
        [
          // this.User,
          addr
        ],
        (
          // u,
          a
        ) =>
          // u.id,
          UserForeignQuery(a.network as ChannelType, a.toString())
      );
      // @todo keep as well?
      const uf = this._local.unwrappedCell(foreignQuery);
      const isVerified = uf.map((v) => v !== null);
      const aF = proxy.map(
        [uf, this.UserID],
        (foreign, id) => id && foreign?.id !== id,
        "aF"
      );

      // @todo Starknet
      const detail = {
        name,
        addr,
        // role,
        isVerified,
        aF,
        conn
      } as WalletDetail;
      return [key, detail] as [string, WalletDetail];
    };

    // UserForeign query

    const foreignQuery = this.User.map((user) =>
      VirtualAllForeignQuery(user.id)
    );
    const foreigns = this._local.unwrappedCell(foreignQuery) as MapCell<
      { l: CacheQueryFromType<"uf">[] } | null,
      false
    >;
    const last = <T>(l: T[]): T => (l?.length ? l[l.length - 1] : null);
    this.AllForeign = foreigns.map(
      (data) => data?.l?.map((q: string) => last(q.split(":"))) || null,
      "AllForeign"
    );
    logger(this.AllForeign);

    // @todo include allForeign addresses
    const allAddresses = this.DetectedConnectors.map(
      (l) =>
        // @todo collect
        // @todo even better, auto garbage collect at cells level
        proxy.mapNoPrevious(
          l.map((conn) => conn?.Addresses), //.filter((l) => l !== undefined),
          (...addr) =>
            Object.fromEntries(
              addr.map(
                (addr, i) =>
                  [l[i].ID, addr] as [WalletConnector, StringAddress<Network>[]]
              )
            ) as Record<WalletConnector, StringAddress<Network>[]>
        ),
      "allAddresses"
    );
    logger(allAddresses);

    this.ConnectedWallets = allAddresses.map(
      (m, prev: Record<string, WalletDetail>) =>
        Object.fromEntries(
          Object.entries(m)
            .flatMap(([id, l]) =>
              l.map((addr) => addressDetail(id as WalletConnector, addr, prev))
            )
            .filter(([key, _v]) => key)
        ) as Record<`0x${string}`, WalletDetail>,
      "ConnectedWallets"
    );
    logger(this.ConnectedWallets);

    // @todo move to cells?
    const cellFalse = proxy.new(false, "false") as unknown as MapCell<
      false,
      true
    >;
    this.IsVerified = proxy.map(
      [this.WalletID, this.ConnectedWallets],
      (id, acc) => acc[id?.toString()]?.isVerified || cellFalse
    );
    this.IsSwitchingAccount = proxy.map(
      [this.WantedWalletAccount, this.ConnectorAccount], // walletID?
      (w, c) => w !== c,
      "Core.isSwitchingAccount"
    );
    this.WalletName = proxy.map(
      [this.ConnectedWallets, this.ConnectorAccount],
      (all, acc) => all[acc]?.name || null,
      "WName"
    );

    this.SentTransactions = proxy.new(
      [] as SentTransaction[],
      "Core.sentTransactions"
    );
    this.Receipts = proxy.new([] as TransactionReceipt[], "Core.Receipts");
    this.Settings = new Environment(proxy, { id: "settings" });
    this.Settings.addValueType(
      EnvKeyValidity,
      $validity(proxy),
      newTypeScheme(typeNumber)
    );
    this.Settings.addValueType(
      EnvKeySlippage,
      $slippage(proxy),
      newTypeScheme(typeNumber)
    );

    this.Lighthouse = new Lighthouse(proxy, this.Connector);
  }

  // Methods

  // // @todo only once for all tabs if multiple tabs are opened in the same browser
  // // @todo | typeof CODE_REQUIRED
  // private _verify = async (
  //   sign: Signer<Network>,
  //   code: string = undefined
  // ): Promise<UserAuth | null> => {
  //   console.log("auth verify", { sign, code });
  //   // 2. Sign
  //   this.LogState.set(VERIFYING);
  //   // @todo @security we must find a better derived secret
  //   const msg = <SignedMessage>(
  //     await this._apiUser.AuthMessage({ username: sign.acc })
  //   );
  //   try {
  //     msg.Sig = await sign.fn(msg.Msg);
  //     // @todo update Signer
  //     msg.N = (await this.Connector.get())?.Network || EVMNetwork;
  //   } catch (error) {
  //     console.log("verify", { error, sign });
  //     if (sign.src === SignerSource.Generated) throw error;
  //     const newSigner = await newEVMAccount();
  //     return this._verify(newSigner, code);
  //   }
  //   // 3. Auth
  //   try {
  //     if (code) msg.code = await hash_to_base64(code);
  //     const walletAuth = await this._apiUser.Wallet(msg);
  //     // We create a derived session key, that should change for each session (ADDR2).
  //     // @todo @security use libcrypto.KeyPair
  //     const priv = generatePrivateKey();
  //     // @todo where does the account come from?
  //     cookie.set(`${sign.acc}.2`, priv);
  //     cookie.set(sign.acc, walletAuth.token);
  //     this._syncAuth.set(walletAuth);

  //     // @todo should be a .map
  //     this.LogState.set(LOGGED);
  //     return { ...walletAuth, acc: sign.acc };
  //   } catch (error) {
  //     console.log("verify", { error, step: 2 });
  //     this.LogState.set(UNLOGGED);
  //     errors.set(error);
  //     // if (error instanceof Error && error.message.includes("please wait"))
  //     //   return CODE_REQUIRED;
  //     return null;
  //   }
  // };

  private _afterAuth = (sign: Signer<Network>, auth: UserAuth) => {
    cookie.set(AuthCookieName, auth.token);

    // We create a derived session key, that should change for each session (ADDR2).
    // @todo @security use libcrypto.KeyPair
    // @todo Do we create a key for each account, or do we keep one
    // common session shared for all accounts under the same userID.
    const priv = generatePrivateKey();
    // @todo where does the account come from?
    cookie.set(`${sign.acc}.2`, priv);

    this._syncAuth.set(auth);

    // @todo should be a .map
    this.LogState.set(LOGGED);
    return { ...auth, acc: sign.acc };
  };

  // @todo only once for all tabs if multiple tabs are opened in the same browser
  // @todo | typeof CODE_REQUIRED
  private _verify2 = async (
    signer: Signer<Network>,
    isTmp = false
  ): Promise<UserAuth | null> => {
    console.log("auth verify2", { sign: signer });
    // 2. Sign
    this.LogState.set(VERIFYING);
    try {
      // @todo update Signer
      // msg.N = (await this.Connector.get())?.Network || EVMNetwork;
      const auth = await this._apiUser.Authenticate(signer);
      return { ...this._afterAuth(signer, auth), _t: isTmp };
    } catch (error) {
      try {
        const newSigner = await newEVMAccount();
        const auth = await this._apiUser.Authenticate(newSigner);
        return { ...this._afterAuth(newSigner, auth), _t: isTmp };
      } catch (error) {
        console.log("verify", { error, step: 2 });
        this.LogState.set(UNLOGGED);
        this._options.fail(error);
        // if (error instanceof Error && error.message.includes("please wait"))
        //   return CODE_REQUIRED;
        return null;
      }
    }
  };

  LocalRPC = (
    onDestroy?: (fn: () => unknown) => void,
    proxy?: SheetProxy,
    _name?: string
  ) => {
    const local = new LocalRPCSubscriber(proxy, this.MultiRPC);
    if (onDestroy !== undefined) onDestroy(() => local.destroy());
    return local;
  };

  /**
   * Retrieves the current connector, or the connector specified
   * by its index.
   * @todo replace list with ConnectorID keys?
   */
  private async _getConnector(connectorID?: WalletConnector) {
    if (connectorID === undefined) return this.Connector.get();
    const l = await this.DetectedConnectors.get();
    if (l instanceof Error) throw l;
    return l.find((conn) => conn.ID === connectorID);
  }

  /**
   * Connects a connector and listen for its events.
   * @note It does not change the WantedAccount.
   * @param connector
   * @returns
   */
  Connect = async (connectorID?: WalletConnector) => {
    const conn = await this._getConnector(connectorID);
    if (!conn) return;
    const res = await conn.connect();
    if (!res) return false;
    // @todo we should need that...
    // this.WantedWalletAccount.set(res?.account);
    return true;
  };

  Disconnect = async (connectorID?: WalletConnector) => {
    const conn = await this._getConnector(connectorID);
    if (!conn) return;
    await conn.disconnect();
    const next = await this._firstConnectedAccount.get();
    if (next instanceof Error) throw next;
    if (next === -1) return this.Drop();
    this.CurrentConnector.set(next);
  };

  /**
   * Local creates a new LocalSubscriber, with an optional onDestroy
   * which must be used when instantiated in Svelte components.
   * @returns
   * @todo take the local proxy or create a local proxy...
   */
  Local = (
    onDestroy?: (fn: () => unknown) => void,
    proxy?: SheetProxy,
    name?: string
  ): LocalSubscriber<CacheQuery> => {
    const local = new LocalSubscriber(
      this._options?.sheet,
      this._global,
      proxy,
      name
    );
    if (onDestroy !== undefined) onDestroy(() => local.destroy());
    return local;
  };

  // @todo check for multiple OKConnector, check that addresses match
  // @todo automatically switch chain?
  WalletChange = async (detail: WalletDetail) => {
    const conns = await this.DetectedConnectors.get();
    if (conns instanceof Error) throw conns;
    const conn = conns.findIndex((conn) => conn.ID === detail.conn);
    if (conn === undefined) {
      console.log("WalletChange", { detail, conns, conn });
      throw new Error("connector not found");
    }
    const addr = await detail.addr.get();
    this.CurrentConnector.set(conn);
    this.WantedWalletAccount.set(addr.toString());

    // const acc = await this.WalletID.get();
    // if (acc instanceof Error) return;

    // // no current wallet id, we assign the default account
    // if (!acc) {
    //   this.WantedWalletAccount.set(addr.toString());
    //   return;
    // }

    // if (addr.equals(acc)) return;
    // if (document.visibilityState === "hidden") return;
    // // @security we trust the provided wallet
    // this.WantedWalletAccount.set(addr.toString());
  };

  /**
   * switchChain switches the current chain, possibly adding the chain if not present.
   * @param ch
   * @see https://docs.metamask.io/guide/rpc-api.html#unrestricted-methods
   */
  SwitchChain = async (ch: ChainType): Promise<ChainType> => {
    const conn = await this.Connector.get();
    try {
      return conn.switchChain(ch);
    } catch (switchError) {
      console.log("SwitchChain", { switchError });
      if (missingChain(switchError)) return conn.addChain(ch);
      throw switchError;
    }
  };

  Drop = async () => {
    const conns = await this.DetectedConnectors.get();
    if (conns instanceof Error) throw conns;
    for (const conn of conns) {
      const acc = await conn.Account.get();
      if (acc instanceof Error || acc === null) continue;
      await conn.disconnect();
    }
    cookie.delete(AuthCookieName);
    // @todo LogState should be mapped
    this.LogState.set(UNLOGGED);
    // @todo should be done by subscribing already
    this.WantedWalletAccount.set(null);
    return true;
  };

  // @todo broken, repair
  PairForeignAccount = async (usr: User, fa: ForeignAccount) => {
    const userID = await this.UserID.get();
    if (userID instanceof Error) throw userID;
    if (!userID) throw new Error("userID not found");

    // console.log("pairForeignAccounts", { usr, fa, userID });
    // discord link already set

    // @todo this is a query...
    // if (usr?.f?.find((fa) => fa.ty === allChannels.Discord) !== undefined)
    //   return true;

    // generate KeyPair for discordID
    const msg = <SignedMessage>(
      await this._apiUser.AuthMessage({ username: fa.f })
    );
    // sign msg with client
    const conn = await this.Connector.get();
    msg.Sig = await conn.signMessage(msg.Msg);

    // call discordAuthVerify
    const walletAuth = await this._apiUser.VerifyDiscord(this._Token.value, {
      ID: userID,
      Key: fa.f,
      ESM: msg
    });
    return walletAuth !== undefined;
  };

  /**
   * Write a new OKcontract data.
   * @todo should we keep in the SDK?
   * @todo do not navigate from here
   */
  Write = async <T extends WritableDataType>(
    ty: T,
    data: CacheDataFromType<T>,
    /**
     * callback updates other cache data locally and immediately
     * (e.g. to display upvote counter update as the user clicks,
     * otherwise the previous value would be cached for 30/60s)
     */
    options: {
      connectorID?: WalletConnector;
      callback?: (cd: CachedData<T>) => void | Promise<void>;
      auth?: string;
      // stay?: boolean;
    } = {
      // stay: false
    }
  ) => {
    const clean = stripDefaultValues(data);
    const msg = write_message(ty, clean);
    // console.log("write_data", { msg });
    const conn = await this._getConnector(options.connectorID);
    const sig = await conn.signMessage(msg);
    try {
      const cd = await this.Cache.write(ty, msg, sig, options.auth);
      // console.log({ write: cd.q });
      // no need to wait?
      if (options.callback !== undefined) options.callback(cd);
      // const url = link_of_cache_query(cd.q);
      // if (!options.stay && url) setTimeout(() => navigate({ to: url }), 250);
    } catch (error) {
      return this._options.fail(error);
    }
  };

  /**
   * Add a UserForeign data.
   */
  AddForeign = async (
    addr: AnyCell<Address<Network>>,
    options: { connectorID?: WalletConnector } = {}
  ) => {
    // console.log("AddForeign", { addr });
    const v = await addr.get();
    const u = await this.User.get();
    if (u instanceof Error) throw u;
    if (v instanceof Error) throw v;
    const data: ForeignAccount = {
      ty: v.network as ChannelType,
      id: u.id,
      f: v.toString()
    };
    // console.log("AddForeign", { data });
    await this.Write("uf", data, { connectorID: options.connectorID });
  };
}
