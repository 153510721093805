export type VirtualQueryType<
  Key extends string,
  Value extends string
> = `χ${Key}:${Value}`;

export const VirtualQuery = <Key extends string>(
  key: Key,
  value: string
): VirtualQueryType<Key, string> => `χ${key}:${value}`;

export type hex =
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "f"
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F";
export type FunctionSelector = `0x${hex}${string}${hex}`;
// `0x${hex}${hex}${hex}${hex}${hex}${hex}${hex}${hex}`;

export const SignaturesQuery = (...sig: FunctionSelector[]) =>
  VirtualQuery("sigs", sig.join("|"));

export const ABIUpdateQuery = (org: string, id: string) =>
  VirtualQuery("abup", `${org}/${id}`);
