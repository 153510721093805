import type { AbiFunction } from "abitype";
import { type Abi, parseAbi } from "viem";

import type { Environment } from "@okcontract/lambdascript";
import {
  type Chain,
  type EVMType,
  type StringAddress,
  chainToViem
} from "@okcontract/multichain";

import { inputsPosition } from "./abi";

const DEV = false;

export type CompiledContract = {
  abi: string[];
  bytecode: string;
  parsed: Abi;
};

export const parse_compiled_contract_file = async (file: File) => {
  const text = await file.text();
  try {
    const json = JSON.parse(text);
    if (
      json &&
      "abi" in json &&
      "bytecode" in json &&
      "object" in json.bytecode
    )
      return {
        abi: json.abi,
        parsed: parseAbi(json?.abi) as Abi,
        bytecode: json.bytecode.object
      } as CompiledContract;
  } catch (e) {
    DEV && console.log("parse_compiled_contract_file", e);
  }
  return;
};

/**
 * deploy a smart contract.
 * @param comp
 * @param env
 * @todo type for params should match CompiledContract ABI.
 * @todo non EVMs
 * @todo rewrite as Cell maps
 */
export const deployParameters = async (
  // walletId: Address<Network>|Error,
  account: StringAddress<EVMType>,
  comp: CompiledContract, // @todo for all Networks
  deploy: AbiFunction, // @todo for all Networks
  env: Environment,
  ch: Chain
) => {
  // const walletId = await core.WalletID.get();
  // if (walletId instanceof Error) throw walletId;
  // if (walletId.network !== EVMNetwork) throw "@todo: deploy non-EVM";

  // const wc = await core.WalletClient.get();
  // const ch = await core.Chain.get();
  // @todo Environment
  const values = inputsPosition(deploy, env);

  if (
    values.indexOf(null) >= 0 ||
    // || wc instanceof Error
    ch instanceof Error
  )
    return;

  const args = await Promise.all(values.map((v) => v.get()));

  return {
    abi: comp.abi,
    bytecode: comp.bytecode as `0x${string}`,
    account,
    chain: chainToViem(ch),
    args
  };
};
