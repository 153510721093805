import type { Connector, WindowProvider } from "@wagmi/connectors";
import {
  type AbiFunction,
  type WalletClient,
  createWalletClient,
  custom,
  getAddress,
  hexToNumber,
  toHex
} from "viem";

import {
  type AnyCell,
  type MapCell,
  type SheetProxy,
  type ValueCell,
  logger
} from "@okcontract/cells";
import type { CacheQuery } from "@okcontract/coredata";
import { type Environment, Rational } from "@okcontract/lambdascript";
import {
  type Chain,
  type ChainType,
  EVMNetwork,
  type EVMType,
  type StringAddress,
  chainToViem
} from "@okcontract/multichain";
import type { SignedMessage } from "@scv/auth";
import type { LocalSubscriber } from "@scv/cache";

import { type CompiledContract, deployParameters } from "./deploy";
import type {
  ConnectorOptions,
  OKConnector,
  Transaction,
  WalletConnector
} from "./wallet";

const ErrNoChains = new Error("no chains");

// @todo @test Changing account from Wallet
// @todo @test Changing account from app (MetaMask only)
// @todo @test Wallet extension locked
export class ViemConnector implements OKConnector<EVMType> {
  readonly ID: WalletConnector;
  readonly Network = EVMNetwork;
  readonly Account: MapCell<StringAddress<EVMType>, true>;
  readonly ChainId: MapCell<number, true>; // @todo bigint
  readonly Chain: AnyCell<Chain>;
  readonly Chains: AnyCell<Record<string, Chain>>;
  readonly Addresses: AnyCell<StringAddress<EVMType>[]>;

  private readonly _conn: Connector<WindowProvider, unknown>;
  private readonly _prov: ValueCell<WindowProvider | null>;
  private readonly _wc: MapCell<WalletClient, false>;
  private readonly _local: LocalSubscriber<CacheQuery>;
  private readonly _options: ConnectorOptions;

  constructor(
    proxy: SheetProxy,
    chains: AnyCell<Record<string, Chain>>,
    id: WalletConnector,
    conn: Connector<WindowProvider, unknown>,
    options?: ConnectorOptions
  ) {
    this._options = options;
    this.ID = id;
    this._conn = conn;
    this.Chains = chains.map(
      (all) =>
        Object.fromEntries(
          Object.entries(all).filter(([key, ch]) => ch.net === EVMNetwork)
        ),
      "viem:chains"
    );
    if (options?.local) this._local = options.local;
    const account = proxy.new<StringAddress<EVMType>>(undefined, "vc:acc"); //conn.getAccount()); // @todo init at null?
    conn
      .getAccount()
      .then((v) => account.set(getAddress(v)))
      .catch((err) => {
        account.set(null);
      });
    logger(account);
    const chainId = proxy.new(undefined, "vc:chId"); // conn.getChainId());
    conn
      .getChainId()
      .then((v) => chainId.set(v))
      .catch((err) => {
        chainId.set(null);
      });
    logger(chainId);
    const chain = proxy.map(
      [chainId, this.Chains],
      (id, ch) => {
        if (id === null) return null;
        const bid = new Rational(id);
        // console.log("VC.", { id, ch });
        return (
          Object.values(ch || {}).find((c) =>
            // @todo update type?
            (c.numid as unknown as Rational).equals(bid)
          ) || null
        );
      },
      "vc:chain"
    );
    logger(chain);
    this._wc = proxy.map(
      [account],
      async (account) => {
        // console.log({ connector, account });
        // if (account === LOADING_WALLET || !account || !conn) return null;
        return createWalletClient({
          account,
          transport: custom(await conn.getProvider())
        });
      },
      "vc:wc"
    );

    const addresses = this._wc.map(
      (client) => client.getAddresses(),
      "vc:addr"
    );
    // proxy.new(wcGet().getAddresses());

    conn.on("disconnect", this._disconnectFn);

    this._prov = proxy.new(conn.getProvider(), "vc:prov");
    // Update Account, ChainId, Addresses
    // const prov = await conn.getProvider();
    this._prov.subscribe((prov) => {
      prov.on("chainChanged", (chain: `0x{$string}`) =>
        chainId.set(hexToNumber(chain))
      );
      prov.on("accountsChanged", (accounts: `0x{$string}`[]) => {
        console.log("accountsChanged", { accounts });
        const next = accounts?.[0];
        if (!next) return;
        account.set(getAddress(next));
        // @todo
        // addresses.set((await wcGet()).getAddresses()); // @todo check if works
      });
    });
    this.Account = account as unknown as MapCell<StringAddress<EVMType>, true>;
    this.ChainId = chainId as unknown as MapCell<number, true>;
    this.Chain = chain;
    this.Addresses = addresses;
  }

  private _disconnectFn = () => {
    console.log("DISCONNECT");
    // @ts-expect-error ValueCell
    this.Account.set(null);
    // @ts-expect-error ValueCell
    this.ChainId.set(null);
    if (this._options?.onDisconnect) this._options.onDisconnect();
  };

  connect = async () => {
    const res = await this._conn.connect();
    // @ts-expect-error ValueCell
    this.Account.set(res.account);
    // @ts-expect-error ValueCell
    this.ChainId.set(res.chain.id);
    return res;
  };

  disconnect = async () => {
    await this._conn.disconnect();
    this._disconnectFn();
  };

  private _wantedChain = async (id: ChainType): Promise<Chain> => {
    const all = await this.Chains.get();
    if (all instanceof Error) throw all;
    const wanted = Object.values(all).find((ch) => ch.id === id);
    if (!wanted) throw new Error("chain not found");
    return wanted;
  };

  switchChain = async (id: ChainType) => {
    const current = await this.Chain.get();
    if (current instanceof Error) throw current;
    if (current.id === id) return id; // nothing to do
    const wanted = await this._wantedChain(id);
    const prov = await this._prov.get();
    const req = await prov.request({
      method: "wallet_switchEthereumChain",
      params: [
        { chainId: toHex((wanted.numid as unknown as Rational).toBigInt()) }
      ]
    });
    // @todo standardize... do we want the chain id?
    return id;
  };

  signMessage = async (message: string) => {
    const client = await this._wc.get();
    if (client instanceof Error) throw client;
    const account = await this.Account.get();
    return client.signMessage({ account, message });
  };

  signedMessage = async (Msg: string): Promise<SignedMessage> => {
    const Sig = await this.signMessage(Msg);
    return { Msg, Sig, N: this.Network };
  };

  // @todo check gas type
  // @todo retrieve Chain
  sendTransaction = async (tx: Transaction<EVMType>) => {
    const cv = await this.Chains.get();
    if (cv instanceof Error) throw ErrNoChains;
    const chain = cv[tx.chain];
    if (!chain) throw new Error(`chain not found: ${tx.chain}`);
    const client = await this._wc.get();
    if (client instanceof Error) throw client;
    return client.sendTransaction(
      // @ts-expect-error check viem types
      { ...tx, chain: chainToViem(chain) }
    );
  };

  // @todo move to @multichain/chainToViem
  private _convertChain = async (wanted: Chain) => {
    if (!this._local) throw new Error("no local");
    let cur = await this._local.staticQuery(wanted.currency)?.get();
    if (cur instanceof Error || !cur) {
      const id = wanted.currency.replace("tok:", "").toUpperCase();
      cur = {
        id: wanted.currency,
        act: true,
        name: id,
        symbol: id,
        decimals: 18, // @todo not always
        addr: []
      };
    }
    return {
      id: (wanted?.numid as unknown as Rational).toNumber(),
      rpcUrls: { default: { http: wanted.rpc || [] } },
      name: wanted.name,
      nativeCurrency: {
        name: cur.name,
        symbol: cur.symbol,
        decimals: cur.decimals
      },
      blockExplorers: {
        default: { name: "main", url: wanted?.explorer[0] }
      }
    };
  };

  addChain = async (id: ChainType) => {
    const wanted = await this._wantedChain(id);
    const client = await this._wc.get();
    if (client instanceof Error) throw client;
    const chain = await this._convertChain(wanted);
    await client.addChain({ chain }).catch((err) => {
      alert(
        `Please add the following Network directly to your wallet:
          Name: ${chain.name}
          ID: ${toHex(chain.id)}
          Currency: ${chain.nativeCurrency.name}
          RPC: ${chain.rpcUrls.default.http}
          Explorer: ${chain.blockExplorers.default.url}`
      );
    });
    return id;
  };

  // @todo cells: transaction read
  deployContract = async (
    comp: CompiledContract,
    fn: AbiFunction,
    env: Environment
  ) => {
    const client = await this._wc.get();
    if (client instanceof Error) throw client;
    const ch = await this.Chain.get();
    if (ch instanceof Error) throw ErrNoChains;
    const account = await this.Account.get();
    const params = await deployParameters(account, comp, fn, env, ch);
    return client.deployContract(
      // @ts-expect-error check viem types
      params
    );
  };

  signTransaction = async (tx: Transaction<EVMType>) => {
    const client = await this._wc.get();
    if (client instanceof Error) throw client;
    const cv = await this.Chains.get();
    if (cv instanceof Error) throw ErrNoChains;
    const chain = cv[tx.chain];
    if (!chain) throw new Error(`chain not found: ${tx.chain}`);
    const req = await client.prepareTransactionRequest(
      // @ts-expect-error check viem types
      {
        ...tx,
        chain: chainToViem(chain)
      }
    );
    return client.signTransaction(req);
  };
}
