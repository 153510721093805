import type { AnyCell, SheetProxy } from "@okcontract/cells";
import {
  type EditorMode,
  type EditorNode,
  type GroupDefinition,
  type LabelledTypeDefinition,
  mapTypeDefinitions,
  objectDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";
import { ADMIN, GUEST, MANAGER, USER } from "@scv/auth";

import type { OKPage } from "./instance";

export const userGroups = (proxy: SheetProxy): AnyCell<GroupDefinition>[] => [
  proxy.new({ id: "id", l: "User Information" }),
  proxy.new({ id: "data", l: "User Data" })
];

// export const userIDSchema =
//   (instance: OKPage, mode?: EditorMode) =>
//   (node?: EditorNode, env?: Environment) =>
//     objectDefinition(
//       instance.proxy,
//       {
//         from: {
//           label: "Submitter",
//           locked: true,
//           base: "string",
//           gr: "id",
//           isAddress: true
//         },
//         id: {
//           label: "Unique chain ID",
//           locked: mode === EDIT,
//           base: "string",
//           pattern: defaultPatterns.orgid,
//           gr: "id",
//           pl: "ethereum",
//           search: mode === EDIT ? "chain" : undefined,
//           hint: mode === NEW ? "Unique chain identifier" : undefined,
//           help:
//             mode === NEW
//               ? "Should be a unique identifier for the chain"
//               : undefined,
//           validator: async (v: string) => {
//             // TODO: refactor with org / contract / token
//             if (!v) return;
//             const ch = await instance.core.CacheOnce(ChainQuery(v));
//             if (ch?.id && mode === NEW) {
//               return `Unique ID ${v} already exists`;
//             }
//           }
//         }
//       },
//       "Chain"
//     );

export const keyPairSchema = (instance: OKPage, mode?: EditorMode) =>
  mapTypeDefinitions(instance.proxy, {
    n: { label: "Nonce", base: "string", locked: true },
    p: { label: "Private Key (encrypted)", base: "string", hidden: true },
    u: { label: "Public Key", base: "string", locked: true },
    // h:{label:"Nonce", base:"string", locked:true},
    a: {
      label: "Address",
      base: "string",
      optional: true,
      locked: true,
      isAddress: true
    },
    v: { label: "Version", base: "number", locked: true }
  });

export const roleSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance.proxy, {
      u: { label: "User ID", base: "string" },
      role: { label: "Role", enum: [ADMIN, MANAGER, USER, GUEST] },
      from: {
        label: "Granter",
        base: "string",
        isAddress: true,
        optional: true
      },
      v: { label: "Value", base: "string", optional: true }
    });

export const userForeignSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance.proxy, {
      ty: { label: "Type", base: "string" },
      id: { label: "User ID", base: "string" },
      f: { label: "Foreign ID", base: "string" },
      p: { label: "Proof", base: "string", optional: true }
    });

/**
 * schema for coredata.User
 */
export const userSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(instance.proxy, {
      id: {
        label: "ID",
        base: "string",
        gr: "id"
      },
      u: {
        label: "Username",
        base: "string",
        gr: "id",
        optional: true
      },
      i: {
        label: "Image",
        base: "string",
        isImg: true,
        pl: "https://... or ipfs://...",
        gr: "id",
        optional: true
      },
      kp: {
        label: "Keys",
        gr: "id",
        object: keyPairSchema(instance),
        optional: true
      }

      // @deprecated
      // role: {
      //   label: "Role",
      //   object: mapTypeDefinitions(instance.proxy, {
      //     role: { label: "Role", enum: [ADMIN, MANAGER, USER, GUEST] },
      //     from: { label: "Granter", base: "string", isAddress: true },
      //     v: { label: "Value", base: "string", optional: true }
      //   }),
      //   optional: true,
      //   locked: true
      // },
      // roles: {
      //   label: "",
      //   gr: "id",
      //   array: () =>
      //     instance.proxy.new({
      //       label: "Role",
      //       base: "string"
      //     })
      // },
      // from: {
      //   label: "Creator",
      //   gr: "id",
      //   base: "string",
      //   optional: true,
      //   isAddress: true,
      //   locked: true
      // },
      // f: {
      //   label: "Foreign IDs",
      //   gr: "id",
      //   array: () =>
      //     instance.proxy.new({
      //       label: "ID",
      //       locked: true,
      //       object: mapTypeDefinitions(instance.proxy, {
      //         ty: { label: "Type", base: "string" },
      //         u: { label: "User", base: "string" },
      //         sig: { label: "Signature", base: "string", hidden: true }
      //       })
      //     })
      // },
      // sto: {
      //   label: "Storage",
      //   gr: "data",
      //   dict: () =>
      //     instance.proxy.new({ label: "Encrypted Data", base: "string" })
      // }
    });

export const submitter = (
  gr = "id",
  label = "Submitter",
  hint = "Data owner",
  locked = true,
  hidden = true
): LabelledTypeDefinition => ({
  label,
  base: "string",
  hint,
  gr,
  locked,
  hidden,
  search: "user"
  // isAddress: true,
  // pl: "0x...",
  // optional: true
});
