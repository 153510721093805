<!-- @todo rename to EditorGroups -->
<script lang="ts">
  import {
    DataEditor,
    type EditorNode,
    type NodeObject
  } from "@okcontract/fred";
  import type { OKPage } from "@okcontract/sdk";
  import { AlertCellError } from "@scv/dcuic";

  import type { EditorOptions } from "../types";
  import EditorGroup from "./EditorGroup.svelte";

  export let instance: OKPage;
  export let editor: DataEditor;
  export let node: EditorNode & NodeObject; // AnyCell<EditorNode>;
  export let options: EditorOptions = {};

  // @todo reactive?
  const groups = editor.groups(node);

  // const def = node.definition;
  // $: console.log("StoreEdit", { node });
  // $: console.log("StoreEdit", { $groups });
</script>

{#if $groups instanceof Error}
  <AlertCellError cell={groups} where="ObjectStore" />
{:else}
  {#each $groups || [] as group}
    <!-- @todo keyed? (group[0].id) -->
    <!-- {@const _ = console.log({ group })} -->
    <EditorGroup
      page={instance}
      {editor}
      {node}
      {group}
      {options}
      groups={$groups.length}
    />
  {/each}
{/if}
