<script lang="ts">
  import type { Network } from "@okcontract/multichain";
  import type { OKCore } from "@okcontract/sdk";
  import type { OKConnector } from "@okcontract/sdk/src/wallet";
  import { getTheme, Hoverable, Icon, Tooltip } from "@okcontract/uic";
  import { LogoMap } from "@scv/dcuic";

  export let core: OKCore;
  export let connector: OKConnector<Network>;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const account = connector.Account;
</script>

<Hoverable let:hovering={hover}>
  <div class="relative flex w-8 h-8">
    <div
      class="w-8 h-8 {theme.dark(
        $compiledTheme,
        'bg-white-alpha',
        'bg-black-alpha',
        'bg-base-200'
      )} rounded-full avatar ring ring-offset-2 ring-offset-base-100 {!$account
        ? 'ring-transparent'
        : 'ring-success'}"
    >
      <LogoMap name={connector.ID} size="lg" />
    </div>
    {#if hover && $account}
      <div class="absolute top-0 left-0">
        <Tooltip label="Disconnect" pos="right">
          <button
            title="Disconnect"
            class="z-100 btn btn-circle btn-sm p-1 {theme.dark(
              $compiledTheme,
              'btn-neutral',
              '',
              ''
            )}"
            on:click={() => core.Disconnect(connector.ID)}
          >
            <Icon name="link-slash" size="sm" />
          </button>
        </Tooltip>
      </div>
    {/if}
    {#if hover && !$account}
      <div class="absolute top-0 left-0">
        <Tooltip label="Connect" pos="right">
          <button
            title="connect"
            class="z-100 btn btn-circle btn-sm p-1 {theme.dark(
              $compiledTheme,
              'btn-neutral',
              '',
              ''
            )}"
            on:click={() => core.Connect(connector.ID)}
          >
            <Icon name="link" size="sm" />
          </button>
        </Tooltip>
      </div>
    {/if}
  </div>
</Hoverable>

<!-- <button
  class="w-8 {theme.dark(
    $compiledTheme,
    'bg-white-alpha',
    'bg-black-alpha',
    'bg-base-200'
  )} rounded-full avatar ring ring-offset-base-100 ring-offset-2 {!$account
    ? 'offline'
    : 'online'} {!$account ? 'ring-' : 'ring-success'}"
  on:click={() =>
    !$account ? core.Connect(connector.ID) : core.Disconnect(connector.ID)}
>
  <LogoMap name={connector.ID} size="lg" />
</button> -->

<!-- connector.ID -->

<!-- 
    <div class="stat p-3">
    <div class="flex items-center gap-2 justify-between">
    <div class="flex items-center gap-2">
    <Button
      icon={!$account ? "plus-o" : "close"}
      label={!$account ? "Connect" : "Disconnect"}
      action={() =>
        !$account ? core.Connect(connector.ID) : core.Disconnect(connector.ID)}
      size="xs"
    />
    </div>
  </div>
  </div>
  -->

<!-- <span class="badge badge-{!$account ? 'default' : 'success'}  badge-xs"
      ></span> -->
