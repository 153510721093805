import type { Abi, AbiFunction, AbiParameter } from "abitype";

import type { AnyCell, MapCell, SheetProxy } from "@okcontract/cells";
import type { Pivot } from "@okcontract/coredata";
import type {
  AbiOfNetwork,
  ChainAddress,
  LocalRPCSubscriber,
  Network
} from "@okcontract/multichain";

/**
 * PivotData is cached pivot data, served as an optimization to clients.
 * Especially if the range is big (30k for QuickSwap pools!), this data
 * should only stay server side and be queryable.
 */
export interface PivotData {
  /** contract id */
  con: string;
  /** range value */
  range: number;
  /** labels */
  labels: { [key: number]: string };
  /** display values (e.g. address => name) */
  display: { [key: string]: string };
}

/**
 * pivot_allowance generates allowances for a pivot.
 * @param pivot
 * @param extra
 * @param inputs
 * @param values
 * @returns
 * @todo compute in environment, not position
 * @note not used atm
 */
// export const pivot_allowances = (
//   pivot: PivotView,
//   m: ABIExtra,
//   inputs: ParamType[], // input definitions
//   values: any[] // input values
// ): [string, any][] => {
//   if (!(pivot && extra && extra.allowance && "parameter" in extra.allowance))
//     return;
//   const pos = input_position(inputs, extra.allowance.parameter);
//   if (pos < 0) return;
//   return [[pivot.labels[pivot.current], values[pos]]];
// };

/**
 * PivotFunction defines a pivoted function.
 */
export interface PivotFunction {
  /** function name */
  name: string;
  /** function definition */
  fn: AbiFunction;
  /** position of the pivot argument */
  pos: number;
  /** arg (value) name */
  arg: string;
  /** remaining arguments */
  rem: AbiParameter[];
}

/**
 * pivotAbi builds a pivot-view of an ABI.
 * @param abi
 * @param p
 */
export const pivotAbi = (abi: Abi, p: Pivot): PivotFunction[] =>
  p &&
  abi.reduce((acc, fn) => {
    if (fn.type === "function") {
      acc.push({
        name: fn.name,
        fn,
        pos: fn.inputs.reduce(
          (acc, input, i) => (acc || input.name === p.val ? i : acc),
          undefined as number
        ),
        arg: p.val,
        rem: fn.inputs.filter((input) => input.name !== p.val)
      });
      return acc;
    }
    return acc;
  }, []);

export interface PivotView {
  funcs: PivotFunction[];
  /** current pivot ID */
  current: number;
  /** max should be the length of labels? */
  max: number;
  /** labels for each pivot */
  labels: string[];
}

/**
 * pivot_values gives a list of the pivot values.
 * @param abi
 * @param p
 * @todo pre-compute labels, and retrieve pages from datacache.
 */
export const getPivotView = <N extends Network>(
  rpc: LocalRPCSubscriber,
  addr: AnyCell<ChainAddress<N>>,
  abi: AnyCell<AbiOfNetwork<N>>,
  p: AnyCell<Pivot>,
  page = BigInt(10)
): MapCell<PivotView, false> => {
  const proxy = rpc._proxy;
  return proxy.map([addr, abi, p], (_addr, _abi, _p) => {
    if (!_addr || !_abi || !_p) return rpc._nullCell;
    const range = p.map((p) => p?.range || null, "getPivotView.range");
    const maxRangeCell = rpc.call<AnyCell<unknown>[], Network>(
      addr,
      abi,
      range,
      proxy.new([], "getPivotView.maxRangeCell.args"),
      proxy.new({ name: "getPivotView.maxRangeCell" })
    ) as unknown as MapCell<bigint, false>;
    const maxRangePage = maxRangeCell.map(
      (maxRange) => (maxRange < page ? maxRange : page) || 0,
      "getPivotView.maxRangePage"
    );

    const label = p.map((p) => p?.label || null, "getPivotView.label");
    // @todo investigate `cells` primitive to map arrays
    const labelCells = proxy.map(
      [maxRangePage, p],
      async (max, p) => {
        const labels: AnyCell<string>[] = [];
        for (let i = 0; i < max; i++) {
          const cell = rpc.call(
            addr,
            abi,
            label,
            proxy.new([proxy.new(BigInt(i))], "getPivotView.cell.args"),
            proxy.new({ name: "getPivotView.labelCell" })
          ) as unknown as MapCell<string, false>;
          labels.push(cell);
        }
        return labels;
      },
      "getPivotView.labelCells"
    );

    const labels = labelCells.map(
      async (_labelCells) =>
        await Promise.all(
          _labelCells.map(async (_labelCell) => {
            const label = await _labelCell.get();
            if (label instanceof Error) return null;
            return label;
          })
        ),
      "getPivotView.labels"
    );
    return proxy.map(
      [maxRangeCell, abi, labels, p],
      (max, abi, labels, p) => {
        const funcs = pivotAbi(abi, p);
        if (!funcs && !labels?.length) return null;
        return {
          funcs: funcs,
          current: 0,
          max: Number(max),
          labels
        };
      },
      "getPivotView.res"
    );
  });
};
