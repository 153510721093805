<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  import { newOKPage } from "@okcontract/svelte-sdk";
  import {
    Button,
    getTheme,
    Icon,
    ThemeBackground,
    type DropdownStyle,
    type InputStyle
  } from "@okcontract/uic";
  import { SearchInput } from "@scv/selector";

  import { SearchSelector } from "./SearchSelector";

  // @todo infer type
  interface $$Slots {
    default: {
      value: any;
    };
    selected: {
      value: any;
      selected: any;
    };
    split: {};
  }

  /** the core instance */
  export let instance = newOKPage("SearchSelector");
  /** the selected value */
  export let selected = instance.proxy.new("", "selected");
  // $: console.log({ $selected });
  /** the function used on search (returns the results)*/
  export let searchFn: (input: any) => Promise<any[]>;

  /** the label that the user sees when adding a new value */
  export let createLabel: string | undefined = "Create";
  /** the function that checks if a user can create new value */
  export let canCreateFn:
    | ((searchSelector: SearchSelector<string>) => Promise<boolean>)
    | undefined = undefined;

  // styles
  /** sizes of the components */
  export let size: "sm" | "md" | "lg" = "md";
  /** placeholder of the search input */
  export let placeholder: string = "Search...";
  /** quick action displayed on the right side of the input */
  export let oneClick: [string, string] | undefined = undefined;
  /** display a remove button on the input field */
  export let removable: boolean = false;
  /** the input style*/
  export let inputStyle: InputStyle = "bordered";
  /** the toolbar style */
  export let toolBarStyle: DropdownStyle = "bottom";
  /** option to extend the toolbar */
  export let extendDropDown = false;
  /** view only mode */
  export let disabled: boolean = false;
  /** display results on empty input */
  export let showEmpty: boolean = false;

  // @fixme
  // const loading = instance.proxy.working;
  const loading = instance.proxy.new(false);
  const theme = getTheme();
  const compiledTheme = theme.compiled;

  const searchSelector = new SearchSelector(
    instance,
    dispatch,
    selected,
    searchFn,
    {
      size,
      placeholder,
      createLabel,
      inputStyle,
      isRemovable: removable,
      toolBarStyle,
      toolBarExtension: extendDropDown,
      canCreateFn: canCreateFn
    }
  );
  const input = searchSelector.input;
  const open = searchSelector.isOpen;
  const results = searchSelector.results;
  const can_create = searchSelector.canCreate;
  const activeIndex = searchSelector.activeIndex;

  input.subscribe(
    (_input) => _input.length === 0 && dispatch("empty", searchSelector)
  );

  onMount(() => {
    document.addEventListener("click", handleClickOutside, true);
  });
  onDestroy(() =>
    document.removeEventListener("click", handleClickOutside, true)
  );

  // close drop down on outside click
  let buttonElement: HTMLElement;
  const handleClickOutside = (event) => {
    if (
      buttonElement &&
      !buttonElement.contains(event.target) &&
      !event.defaultPrevented
    ) {
      searchSelector.close();
      dispatch("clickoutside");
    }
  };
</script>

{#if $results instanceof Error}
  Error at results {$results}
{:else}
  <div bind:this={buttonElement} class="search-selector grow">
    {#if $selected && !$open}
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        on:click={() => {
          if (disabled) return;
          searchSelector.open();
        }}
        on:keypress|stopPropagation={(ev) => dispatch("keypress", ev)}
        class="flex w-full cursor-default items-center overflow-hidden {size ===
        'sm'
          ? 'badge py-1'
          : 'px-4 py-3'} border rounded-btn {theme.dark(
          $compiledTheme,
          'input-white-alpha',
          'input-black-alpha',
          'bg-base-100 border-base-300'
        )}"
      >
        <!--style={theme.apply($compiledTheme, [ThemeText])}-->
        <slot value={$selected} />
        {#if removable}
          <div class="relative inset-y-0 right-0 z-20 ml-auto flex">
            <button
              class="btn btn-sm btn-circle btn-ghost"
              on:click|stopPropagation={() => searchSelector.clear()}
              ><Icon name="close-o" /></button
            >
          </div>
        {/if}
      </div>
    {:else}
      <div
        class="dropdown w-full {$open && $results?.length
          ? 'dropdown-open'
          : ''}"
      >
        <SearchInput
          {input}
          {instance}
          {size}
          {placeholder}
          {disabled}
          style={inputStyle}
          on:click={async () => {
            const _results = await results.get();
            if (_results instanceof Error) throw _results;
            if (_results?.length) searchSelector.open();
            dispatch("click");
          }}
          on:delete={() => searchSelector.clear()}
          on:keydown={(ev) => searchSelector.handleKeyboard(ev.detail)}
          on:focus={async () => {
            const _results = await results.get();
            if (_results instanceof Error) throw _results;
            if (_results?.length) searchSelector.open();
          }}
        />
        {#if oneClick && !$open && $input}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            class="z-20 flex items-center"
            on:keypress|stopPropagation={undefined}
          >
            <button
              class="btn btn-sm {theme.dark(
                $compiledTheme,
                'btn-white-alpha',
                'btn-black-alpha',
                'btn-ghost'
              )} font-semibold"
            >
              <!--style={theme.apply($compiledTheme, [ThemeText])}-->
              {oneClick[0]}</button
            >
          </span>
        {/if}
        {#if $open && (showEmpty || $input)}
          <div
            class="dropdown-content mt-1 z-[1] ring shadow-lg rounded-btn w-full min-h-full max-h-52 flex flex-col overflow-y-auto {theme.dark(
              $compiledTheme,
              'bg-black ring-white/40',
              'bg-white ring-black/20',
              'bg-base-100 ring-base-300'
            )}"
            style={theme.apply($compiledTheme, [ThemeBackground])}
          >
            {#if $results?.length > 0}
              <ul class="options menu">
                {#each $results as q, i (q)}
                  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
                  <li
                    class={theme.dark(
                      $compiledTheme,
                      "text-white",
                      "text-black",
                      "text-base-content"
                    )}
                    class:active={$activeIndex === i}
                    on:mousedown={() => {
                      searchSelector.select(q, i);
                      searchSelector.create(q);
                    }}
                    on:keypress={undefined}
                    on:mouseover={() => ($activeIndex = i)}
                    on:focus={() => ($activeIndex = i)}
                    on:mouseout={() => ($activeIndex = -1)}
                    on:blur={() => ($activeIndex = -1)}
                  >
                    <!--style={theme.apply($compiledTheme, [ThemeText])}-->
                    <span class="flex break-all w-full justify-between">
                      <slot value={q}></slot>
                      {#if $selected !== null && q === $selected}
                        <span
                          class="flex-none justify-end {theme.dark(
                            $compiledTheme,
                            'text-white',
                            'text-black',
                            'text-primary'
                          )}"
                        >
                          <Icon name="check" size="sm" />
                        </span>
                      {/if}
                    </span>
                    {#if $$slots.selected}
                      <slot name="selected" value={q} selected={$selected} />
                    {/if}
                  </li>
                {/each}
                {#if $can_create}
                  <span class="mt-2">
                    <Button
                      style="default"
                      outline={true}
                      size="sm"
                      block={true}
                      iconPrepend={true}
                      icon="add-o"
                      label={createLabel}
                      action={() => searchSelector.create($input)}
                    />
                  </span>
                {/if}
              </ul>
            {:else if $can_create}
              <span class="p-2">
                <Button
                  style="default"
                  outline={true}
                  size="sm"
                  block={true}
                  iconPrepend={true}
                  icon="add-o"
                  label={createLabel}
                  action={() => searchSelector.create($input)}
                />
              </span>
            {:else}
              <ul class="menu">
                <li
                  class="p-2 {theme.dark(
                    $compiledTheme,
                    'text-white',
                    'text-black',
                    'text-base-content'
                  )}"
                >
                  <!--style={theme.apply($compiledTheme, [ThemeText])}-->
                  {$loading
                    ? "Searching..."
                    : !$results?.length
                      ? "No results"
                      : ""}
                </li>
              </ul>
            {/if}
          </div>
        {/if}
        {#if !!($results?.length && extendDropDown)}
          <ul class="w-full">
            <li>
              {!!($results?.length && extendDropDown)}
            </li>
          </ul>
        {/if}
      </div>
    {/if}
  </div>
{/if}
