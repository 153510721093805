import type { Address, Network } from "@okcontract/multichain";
import type { ChannelValue } from "@scv/auth";

import type { ContractQueryType } from "./contract";
import type { ForeignID } from "./foreign";
import type { OKWidgetPageQueryType } from "./page";
import type { TokenQueryType } from "./token";
import type { OUI } from "./user";

export type OrgQueryType = `org:${string}`;

export const OrgQuery = (id: string): OrgQueryType => `org:${id}`;

/**
 * Organization holds data from DataCache
 */
export interface Organization {
  id: string;
  name?: string;
  from: OUI;
  /** later editors for larger orgs */
  mem?: Address<Network>[];
  rugged?: boolean;
  logo?: string;
  /** description */
  desc?: string;

  /** app URL */
  app?: string;
  banner?: string;
  /** list of communication channels */
  chans: ChannelValue[];
  foreign: ForeignID[];
  /** investments in other orgs */
  invest?: string[];
  /** org homepage */
  page?: OKWidgetPageQueryType;

  /** list of org tokens IDs (including pools, etc.) */
  toks?: TokenQueryType[];
  /** list of contracts IDs - reverse computed */
  cons?: ContractQueryType[];
  /** investors in this org (both orgs and people) - reverse computed */
  investors?: (`org:${string}` | `pp:${string}`)[];
  /** people belonging to this org - reverse computed */
  people?: `pp:${string}`[];
}
