import type { AnyCell, SheetProxy } from "@okcontract/cells";
import { anonContractURL } from "@okcontract/coredata";
import { type Environment, Rational } from "@okcontract/lambdascript";
import { type ChainAddress, isAddress } from "@okcontract/multichain";

export const valueToDefinition = (
  proxy: SheetProxy,
  value: AnyCell<unknown>,
  env: AnyCell<Environment>,
  label = "",
  css?: string
) => {
  return proxy.map([value, env], (_value, _env) => {
    const type = typeof _value;
    switch (type) {
      case "string": {
        return {
          label,
          base: "string",
          css
        };
      }
      case "bigint":
      case "number": {
        return {
          label,
          base: "number",
          css
        };
      }
      case "boolean": {
        return {
          label,
          base: "boolean",
          css
        };
      }
      case "object": {
        if (isAddress(_value)) {
          console.log("values", { env });
          return {
            label,
            base: "string",
            isAddress: true,
            // @todo use constant for $chain
            link: _env?.value("$chain")
              ? anonContractURL({
                  chain: _env.value("$chain").value,
                  addr: _value,
                  ty: "c" // @todo check
                } as ChainAddress)
              : undefined,
            css
          };
        }
        if (_value instanceof Rational) {
          return {
            label,
            base: "number",
            css
          };
        }
        if (Array.isArray(_value)) {
          return {
            label,
            array: () => valueToDefinition(proxy, _value[0], env),
            css
          };
        }
        const object = Object.fromEntries(
          Object.entries(_value).map(([k, v]) => [
            [k],
            () => valueToDefinition(proxy, v, env, k)
          ])
        );
        return {
          label,
          object: proxy.new(object),
          css
        };
      }
      // @todo The function case is not handled.
      // case "function":
      //   return null;
      default: {
        return { label: "", base: "string", css };
      }
    }
  });
};
