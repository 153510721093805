<script lang="ts">
  import { GasQuery } from "@okcontract/coredata";
  import { Rational } from "@okcontract/lambdascript";
  import type {
    AnyContractQuery,
    OKTransaction,
    TXRequest
  } from "@okcontract/sdk";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Button, ThemeButton, ThemeText, getTheme } from "@okcontract/uic";

  import NoWalletWidget from "./NoWalletWidget.svelte";
  import TxFees from "./TXFees.svelte";

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  export let instance = newOKPage("SendTX");
  const core = instance.core;
  const proxy = instance.proxy;
  const local = instance?.local;

  const isConnectWallet = core.IsConnectWallet;
  const noWallet = core.IsNoWallet;
  const conn = core.Connector;
  const connChains = proxy.map([conn], (conn) => conn.Chains);

  export let okTX: OKTransaction<AnyContractQuery>;
  const isSending = okTX.isSending;
  const tokenPriceData = okTX.tokenPriceData;
  const currency = okTX.currency;
  const chain = okTX.chain;
  const contractChain = okTX.contractChain;

  const connHasChain = proxy.map(
    [connChains, contractChain],
    (sup, ch) => !!sup[ch]
  );

  /** transaction_data with estimated gas and tx to be sent */
  export let tx_data: { tx: TXRequest; gas_amount: bigint };
  /** button label */
  export let label: string;
  /** button disabled */
  export let disabled: boolean;

  /** current gas price */
  const qGas = proxy.map([contractChain], GasQuery, "SendTX.qGas");
  const gas = local.unwrappedCell(qGas, "gas");

  const SwitchChain = proxy.mapNoPrevious(
    [core.IsConnectWallet, core.CurrentChain, okTX.contractChain],
    (w, cur, con) => !w && cur !== con,
    "SwitchChain"
  );
  // @todo Catching cells...
  const Label = proxy.mapNoPrevious(
    [noWallet, isConnectWallet, SwitchChain, chain, connHasChain],
    (n, w, s, ch, cch) =>
      n || w
        ? "Connect Wallet"
        : !cch
          ? "Change Wallet"
          : s && ch
            ? `Switch to ${ch?.name}`
            : label,
    "Label"
  );

  const getCurrencyPrice = (input: Rational) => input?.toNumber();

  const showNoWallet = proxy.new(false, "showNoWallet");
</script>

<!-- {$contractChain}
{$isConnectWallet}
{$SwitchChain}
{disabled} -->
<Button
  label={!($Label instanceof Error) && $Label ? $Label : label}
  disabled={$isConnectWallet instanceof Error ||
    $SwitchChain instanceof Error ||
    $contractChain instanceof Error ||
    (!$SwitchChain && !$isConnectWallet && disabled) ||
    !$connHasChain}
  working={isSending}
  asyncAction={async () => {
    if ($noWallet === true) {
      showNoWallet.set(true);
      return;
    }
    if ($isConnectWallet) {
      await core.Connect();
      return;
    }
    if ($SwitchChain && !($contractChain instanceof Error)) {
      await core.SwitchChain($contractChain);
      return;
    }
    await okTX.sendTX();
  }}
  size="md"
  block={true}
  addAsyncSpin={true}
  thParts={[ThemeText, ThemeButton]}
  style={theme.dark(
    $compiledTheme instanceof Error ? null : $compiledTheme,
    "default",
    "neutral",
    "neutral"
  )}
/>

<div class="clear-left">
  {#if !($tokenPriceData instanceof Error) && !($currency instanceof Error) && !($gas instanceof Error) && tx_data?.gas_amount && !disabled}
    <TxFees
      {instance}
      currency={$currency?.symbol}
      gas_amount={tx_data.gas_amount}
      gas_price={Number($gas?.ProposeGasPrice)}
      currency_price={getCurrencyPrice($tokenPriceData?.current_price)}
      {chain}
    />
  {/if}
</div>

{#if $showNoWallet}
  <NoWalletWidget {theme} {compiledTheme} {showNoWallet} pos={true} />
{/if}
