import {
  type ABIExtra,
  ABIExtraQuery,
  type ABIExtraQueryType,
  type OKWidgetStep,
  type OKWidgetStepType
} from "@okcontract/coredata";
import { mergeObjectList, mergeObjects } from "@scv/utils";

import type { OKCore } from "./coreExecution";

/**
 * abixOrId accepts an ABI reference without abix:
 * @param id
 * @returns
 * @todo: We should probably remove this and ensure we always use abix:
 */
export const abixOrId = (id: ABIExtraQueryType | string): ABIExtraQueryType =>
  id?.startsWith("abix:") ? (id as ABIExtraQueryType) : ABIExtraQuery(id);

/**
 * getABIX returns an ABIExtra
 * @param id
 * @returns
 * @todo do we want to return an empty ABIExtra if not found?
 */
export const getABIX = (core: OKCore, id: ABIExtraQueryType | string) => {
  if (id) {
    return core.CacheOnce(abixOrId(id));
  }
  return null;
};

/**
 * getABIXFromRefs builds the ABIExtra from the reference list.
 * @param xr
 * @returns
 * @todo
 */
export const getABIXFromRefs = async (
  core: OKCore,
  xr: ABIExtraQueryType[]
): Promise<ABIExtra> =>
  xr?.length
    ? {
        ...mergeObjectList(
          await Promise.all(xr.map((id) => getABIX(core, id)))
        ),
        id: "abix:@merged"
      }
    : null;

/**
 * mergeOKStepInABIX merges okstep ABIMethod with ABIExtra methods
 * NB: okstep overwrites any existing methods of ABIExtra
 * @param okstep
 * @param abix
 * @returns
 */
export const mergeOKStepInABIX = (
  okstep: Partial<OKWidgetStep<OKWidgetStepType>>,
  abix: ABIExtra,
  req_inputs?: string[]
) => {
  if (!okstep?.m || (!abix?.methods && !abix?.methods)) return abix;
  return {
    ...abix,
    methods: mergeObjects(abix.methods, {
      [okstep.m]: Object.fromEntries(
        Object.entries(
          mergeObjectList([okstep?.xm, abix.methods[okstep.m], abix.values])
        ).filter(([k, v]) => (req_inputs ? req_inputs.includes(k) : true))
      )
    })
  };
};
