<script lang="ts">
  import {
    isContract,
    isToken,
    is_contract_addr,
    link_of_cache_query
  } from "@okcontract/coredata";
  import { isAddress, type ChainType } from "@okcontract/multichain";
  import { isKnownAddress, type OKPage } from "@okcontract/sdk";
  import { WalletAddress } from "@okcontract/svelte-sdk";
  import { PrettyDate } from "@okcontract/uic";
  import {
    DataCacheSnippet,
    ExternalLink,
    ResultSnippet,
    SmartContractSnippet,
    TokenSnippet,
    TruncatedText
  } from "@scv/dcuic";
  import { Link } from "@scv/routing";
  import { isDate, remove_quotes } from "@scv/utils";

  import ObjectViewUrl from "./ObjectViewURL.svelte";

  export let instance: OKPage;

  export let value: unknown;
  export let size: "sm" | "smb" | "md" = "sm";

  export let chain: ChainType = undefined;
  export let details: boolean = false;
  export let snippetType: boolean = true;

  $: unquotedValue =
    value !== undefined && typeof value === "string"
      ? remove_quotes(value)
      : value.toString();
  $: isEmbedded =
    typeof unquotedValue === "string" &&
    unquotedValue.startsWith("data:application/json;");
  $: isURL =
    (typeof unquotedValue === "string" &&
      unquotedValue?.startsWith("https://ipfs.io/")) ||
    (typeof unquotedValue === "string" &&
      unquotedValue?.startsWith("https://"));
  $: isIPFS =
    typeof unquotedValue === "string" && unquotedValue?.startsWith("ipfs://");
  $: isDataImage =
    typeof unquotedValue === "string" &&
    unquotedValue?.startsWith("data:image/");
</script>

<!-- @todo merge with next case? -->
{#if isAddress(value)}
  {#await isKnownAddress(instance.core, value.toString(), chain)}
    ...
  {:then cacheQuery}
    {#if isContract(cacheQuery) || isToken(cacheQuery)}
      <ResultSnippet
        link={link_of_cache_query(cacheQuery)}
        style="default"
        size="xs"
      >
        <DataCacheSnippet dq={cacheQuery} {chain} displayType={snippetType} />
      </ResultSnippet>
    {:else}
      <!-- @todo deprecated  -->
      {#await is_contract_addr(unquotedValue?.toString(), chain) then isContract}
        {@const ty = isContract ? "c" : "w"}
        {@const addr = { addr: value, chain, ty }}
        <div class="flex items-center justify-between">
          <WalletAddress {instance} address={addr} link={true} />
          {#if details && chain && ty === "c"}
            <ExternalLink {addr} />
          {/if}
        </div>
      {/await}
    {/if}
  {/await}
{:else if isDataImage}
  <img alt="inlined" src={unquotedValue} />
{:else if isEmbedded || isURL || isIPFS}
  <!-- @todo remove proxy.new -->
  <ObjectViewUrl
    {instance}
    url={instance.proxy.new(unquotedValue)}
    type={instance.proxy.new(
      isEmbedded ? "embedded" : isURL ? "url" : isIPFS ? "ipfs" : null
    )}
  ></ObjectViewUrl>
{:else if isDate(unquotedValue)}
  <PrettyDate date={unquotedValue} />
{:else if isContract(unquotedValue)}
  {@const link = link_of_cache_query(unquotedValue)}
  <ul class="flex items-center">
    <ResultSnippet {link} size="xs" style="default">
      <SmartContractSnippet {instance} q={unquotedValue} small={true} />
    </ResultSnippet>
  </ul>
{:else if isToken(unquotedValue)}
  {@const link = link_of_cache_query(unquotedValue)}
  <ul class="flex items-center">
    <ResultSnippet {link} size="xs" style="default">
      <TokenSnippet {instance} q={unquotedValue} small={true} />
    </ResultSnippet>
  </ul>
{:else if unquotedValue?.startsWith("@link:")}
  {@const l = unquotedValue.split(":")}
  {#if l.length === 3}
    <Link to={l[2]}>
      {l[1]}
    </Link>
  {:else}
    Invalid link
  {/if}
{:else}
  <div
    class={size === "sm"
      ? "text-sm opacity-70"
      : size === "smb"
        ? "text-sm font-semibold"
        : "text-md font-semibold leading-6"}
  >
    {#if unquotedValue?.length < 20 || unquotedValue?.split(" ").length > 1}
      {unquotedValue}
    {:else}
      <TruncatedText text={unquotedValue} />
    {/if}
  </div>
{/if}
