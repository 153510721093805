<script lang="ts">
  import { AlertBanner, getTheme, Icon } from "@okcontract/uic";
  import { config_store, detectDevice, FRONT_URL } from "@scv/app";
  import { Logo } from "@scv/dcuic";

  //export let isOpen: ValueCell<boolean>;
  let condition = true;
  const theme = getTheme();
  const compiledTheme = theme?.compiled;
</script>

<!-- if no wallet -->

{#await detectDevice() then device}
  {#if device.includes("mobile")}
    <AlertBanner>
      You're using a mobile device. Best is to open <b
        >{$config_store.Env[FRONT_URL]}</b
      > directly in your wallet browser.
    </AlertBanner>
  {/if}
{/await}
<ul class="menu px-0">
  <li>
    <a href="https://metamask.io/" target="_blank" rel="noreferrer">
      <Logo logo="metamask" size="md" /><span>MetaMask</span>
      <div
        class="btn btn-sm {theme.dark(
          $compiledTheme,
          'btn-white-alpha',
          'btn-black-alpha',
          'btn-primary btn-outline'
        )}"
      >
        Get
      </div>
    </a>
  </li>
  <li>
    <a href="https://rabby.io/" target="_blank" rel="noreferrer">
      <Logo logo="rabbywallet" size="md" /><span>Rabby</span>
      <div
        class="btn btn-sm {theme.dark(
          $compiledTheme,
          'btn-white-alpha',
          'btn-black-alpha',
          'btn-primary btn-outline'
        )}"
      >
        Get
      </div>
    </a>
  </li>
  <li>
    <a href="https://www.argent.xyz/" target="_blank" rel="noreferrer">
      <Logo logo="argentwallet" size="md" /><span>Argent</span>
      <div
        class="btn btn-sm {theme.dark(
          $compiledTheme,
          'btn-white-alpha',
          'btn-black-alpha',
          'btn-primary btn-outline'
        )}"
      >
        Get
      </div>
    </a>
  </li>
  <li>
    <a href="https://www.ledger.com/" target="_blank" rel="noreferrer">
      <Logo logo="ledgerwallet" size="md" /><span>Ledger</span>
      <div class="badge badge-warning">Coming soon</div>
    </a>
  </li>
</ul>

<a
  href="https://ethereum.org/en/wallets/find-wallet/"
  target="_blank"
  rel="noreferrer"
  class="p-2 link link-primary flex gap-2 items-center justify-center"
>
  Explore more wallets <Icon name="external" size="xs" /></a
>
