export type JWTUser = {
  id: string; // datacache id of the user
  account?: string;
  at?: number;
  username?: string;
  admin?: boolean;
  guest?: boolean;
  role?: string;
};

/**
 * parseJwt parses a JWT.
 * @param token string representation of the token
 */
export const parseJwt = (token: string): JWTUser => {
  if (!token) return null;
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join("")
  );
  return JSON.parse(jsonPayload);
};
