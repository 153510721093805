<script lang="ts">
  import Config from "../../config.json";

  import type { LogEntry } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme, InlineHelpText } from "@okcontract/uic";

  import Titles from "./Titles.svelte";

  // @todo should a cell
  export let log: LogEntry;

  const instance = newOKPage("TXLog");

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const decode = instance.analyzeLog(
    instance.proxy.new(log),
    instance.core.CurrentChain
  );
  const title = instance.logTitle(instance.core.WalletID, decode);
</script>

{#if $title instanceof Error}
  {#if Config.dev}
    <InlineHelpText style="error">Error at title {$title}</InlineHelpText>
  {/if}
{:else if $decode instanceof Error}
  {#if Config.dev}
    <InlineHelpText style="error">Error at decode {$decode}</InlineHelpText>
  {/if}
{:else if $title && $decode}
  <div
    title={JSON.stringify($title)}
    class="flex flex-wrap items-center {$decode && 'nftcd' in $decode
      ? 'justify-center gap-2'
      : $decode && 'tcd' in $decode
        ? 'gap-2 justify-between'
        : 'gap-3'} {theme.dark(
      $compiledTheme,
      'text-white',
      'text-gray-900 ',
      'text-base-content'
    )}"
  >
    <!-- style={theme.apply($compiledTheme, [ThemeText])} -->
    <Titles {instance} item={title} size="sm" />
  </div>

  <!-- {:else} -->
  <!-- TEMP: DEACTIVATED FOR UI -->
  <!-- {#if DEV}
      {JSON.stringify(log)}
    {/if} -->
  <!-- <DataCacheSnippet dq={log.address} displayType={false} /> -->
{/if}
