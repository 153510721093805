// @todo move to new file depending on Network
import { getAddress } from "viem";

import type {
  AnyCell,
  CellArray,
  MapCell,
  SheetProxy
} from "@okcontract/cells";
import {
  AddressLookupQuery,
  type CacheQuery,
  isContract,
  isToken
} from "@okcontract/coredata";
import {
  type Address,
  AddressEVM,
  type ChainAddress,
  type ChainType,
  type LocalRPCSubscriber,
  type Network,
  isAddress
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";

import { contractAddress } from "./contract";
import type { OKCore } from "./coreExecution";
import { findName } from "./name";
import { token_address } from "./token";
import type { AnyAddress, ContractQueryType, TokenQueryType } from "./types";

export const AddressToChainAddress = <N extends Network>(
  core: OKCore,
  local: LocalRPCSubscriber,
  proxy: SheetProxy,
  chain: AnyCell<ChainType>,
  addr: AnyCell<Address<N>>
) => {
  // @ts-ignore @todo @starknet names
  const n = findName(local, proxy, core.DefaultContracts, chain, addr);
  return proxy.map([addr, chain, n], (addr, chain, n) => {
    return {
      addr,
      n,
      chain
    } as ChainAddress<N>;
  });
};

/**
 * isKnownAddress returns a query from any given address.
 * @deprecated cellify
 */
export const isKnownAddress = async (
  core: OKCore,
  str: string,
  chain: ChainType
) => {
  if (
    !str ||
    typeof str !== "string" ||
    !str.startsWith("0x") ||
    str.length !== 42
  )
    return str;
  const addr = getAddress(str);
  const query = AddressLookupQuery(str, chain);
  const lookup = await core.CacheOnce(query);
  if (lookup?.length > 0) return lookup[0];
  return addr;
};

/**
 * retrieveAddress retrieves an address (as string) or keeps the existing value.
 * @param ch chain
 * @param addr
 * @returns
 */
export const retrieveAddress = <T>(
  proxy: SheetProxy,
  local: LocalSubscriber<CacheQuery>,
  ch: AnyCell<ChainType>,
  value: AnyCell<T>
): MapCell<T extends AnyAddress ? Address<Network> : T, false> =>
  proxy.mapNoPrevious([value, ch], (_v, _ch) =>
    isAddress(_v)
      ? _v
      : isToken(_v)
        ? token_address(
            proxy,
            ch,
            // @todo check proxy
            local.unwrappedCell(value as AnyCell<TokenQueryType>)
          )
        : isContract(_v)
          ? contractAddress(
              proxy,
              // @todo check proxy
              local.unwrappedCell(value as AnyCell<ContractQueryType>),
              ch
            )
          : typeof _v === "string" && _v.startsWith("0x")
            ? (new AddressEVM(_v) as Address<Network>)
            : _v
  ) as MapCell<T extends AnyAddress ? Address<Network> : T, false>;

export const retrieveAllAddresses = (
  proxy: SheetProxy,
  local: LocalSubscriber<CacheQuery>,
  ch: AnyCell<ChainType>,
  l: CellArray<unknown>
) =>
  l?.map((_l) => {
    // console.log({ _l });
    return (
      _l?.map((x) => proxy.new(retrieveAddress(proxy, local, ch, x) || x)) || []
    );
  });
