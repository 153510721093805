import type { ChannelType } from "@scv/auth";

import { VirtualQuery } from "./virtual";

/**
 * OKcontract User ID (OUI)
 */
export type OUI = string;

export type UserQueryType = `user:${string}`;
export const UserQuery = (id: string): UserQueryType =>
  id ? `user:${id}` : null;

export type RoleQueryType = `role:${string}`;
export const RoleQuery = (id: string): RoleQueryType =>
  id ? `role:${id}` : null;

export type UserForeignQueryType = `->${ChannelType}:${string}`; // ${string}:
export const UserForeignQuery = (
  // id: string,
  ty: ChannelType,
  f: string
): UserForeignQueryType => `->${ty}:${f}`; // ${id}:

export type UserForeignSearchType = `χuf:${string}`;

/** query that retrieves the user ID associated to a foreign ID */
export const VirtualForeignQuery = (f: string): UserForeignSearchType =>
  VirtualQuery("uf", f);

export type VirtualAllForeignQueryType = `χauf:${string}`;

/** query that retrieves all UserForeign data related to the given user ID */
export const VirtualAllForeignQuery = (
  id: string
): VirtualAllForeignQueryType => VirtualQuery("auf", id);
