export const EnvKeySelf = "$self";
export const EnvKeyChain = "$chain";
export const EnvKeyWalletID = "$wallet";
export const EnvKeyABI = "$abi";
export const EnvKeyValue = "$value";
export const EnvKeyValidity = "$validity";
export const EnvKeySlippage = "$slippage";

export const EnvKeyOKPage = "@instance";

export const EnvKeyMissingKeys = "@missing";

// logs
export const EnvKeyPrettyLogs = "$pretty";
export const EnvKeyRawLogs = "$raw";
export const EnvKeyAnalyzedLogs = "$analyzed";

export const EnvKeyPivot = "$pivot";

export const EnvKeyCurrentAllowance = "@currentAllowance";
export const EnvKeyWantedAllowance = "@wantedAllowance";
export const EnvKeyAllowanceSpender = "@allowanceSpender";
