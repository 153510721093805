import type { WidgetQueryType } from "./widget";

export type OKWidgetPageQueryType = `pg:${string}`;

export const OKWidgetPageQuery = (id: string): OKWidgetPageQueryType =>
  `pg:${id}`;

export const isPage = (q: unknown) =>
  typeof q === "string" && q.startsWith("pg:");

// Page combining multiple interactions and accompanying descriptions.
export type OKWidgetPage = {
  id: string;
  /** owner */
  own: string;
  /** title */
  t: string;
  /** active */
  act: boolean;
  /** page introduction text */
  txt?: string;
  /** list of interactions */
  l: Interaction[];
};

// Interaction and accompanying description.
export type Interaction = {
  /** widget ID */
  wid: WidgetQueryType;
  /** description */
  dsc?: string;
};
