import type { AccountInterface } from "starknet";

export interface StarknetWindowObject {
  icon: string; // Base64-encoded SVG or other image data
  id: string; // Wallet ID, e.g., "argentX"
  name: string; // Wallet name, e.g., "Argent X"
  starknetJsVersion?: string; // Version of the Starknet.js library
  version?: string; // Wallet version, e.g., "5.21.1"

  enable: (options?: { starknetVersion?: "v4" | "v5" }) => Promise<void>;
  isConnected: boolean;
  isPreauthorized: () => Promise<boolean>;

  account: AccountInterface;
  // {
  //   provider: unknown; // Replace with the specific provider type if known
  //   address: `0x${string}`; // Hexadecimal address string
  //   signer?: unknown; // Replace with specific signer type if known
  // };

  selectedAddress?: `0x${string}`;
  chainId?: string;
  on: (
    event: "accountsChanged" | "networkChanged",
    handler: () => void
  ) => void;
  off: (
    event: "accountsChanged" | "networkChanged",
    handler: () => void
  ) => void;
  request: (options: {
    type: string;
    [key: string]: unknown;
  }) => Promise<unknown>;
}

interface ConnectorData {
  account: `0x${string}`;
  chainId: bigint;
}

interface ConnectResult {
  wallet: StarknetWindowObject;
  connectorData: ConnectorData;
}

export const chainIdToBigInt = (id: string) => {
  if (id.startsWith("0x")) {
    return BigInt(id);
  }
  // Convert string to BigInt using TextEncoder
  const hex = Array.from(new TextEncoder().encode(id))
    .map((byte) => byte.toString(16).padStart(2, "0")) // Convert to hex
    .join("");
  return BigInt(`0x${hex}`);
};

export const connect = async (): Promise<ConnectResult | null> => {
  if (typeof window === "undefined") {
    console.error("Window object is not available.");
    return null;
  }

  const { starknet } = window as unknown as { starknet: StarknetWindowObject };

  if (!starknet) {
    console.error("No injected Starknet wallet found.");
    return null;
  }

  try {
    await starknet.enable({ starknetVersion: "v4" });
    if (starknet.isConnected) {
      console.log({ starknet });
      const connectorData: ConnectorData = {
        account: starknet.selectedAddress,
        chainId: chainIdToBigInt(starknet.chainId || "SN_MAIN")
      };
      return {
        wallet: starknet as StarknetWindowObject,
        connectorData
      };
    }
    console.error("Failed to connect to the Starknet wallet.");
    return null;
  } catch (error) {
    console.error("Error connecting to the Starknet wallet:", error);
    return null;
  }
};

// Clear any application-level references to the wallet
// For example, reset state in your application
export function disconnect(): void {
  console.log("Disconnected from the Starknet wallet.");
}
