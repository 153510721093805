// @todo move to new file depending on Network
import type { AbiFunction } from "abitype";
import {
  bytesToString,
  getAbiItem,
  parseAbi,
  toBytes,
  toFunctionSelector
} from "viem";

import type { AnyCell, MapCell, SheetProxy } from "@okcontract/cells";
import type { ABI, ABIExtra, CacheQuery } from "@okcontract/coredata";
import { Rational } from "@okcontract/lambdascript";
import {
  type Address,
  type Chain,
  type ChainAddress,
  type ChainType,
  ContractType,
  type EVMType,
  type LocalRPCSubscriber,
  type Network,
  balanceOf,
  isEVMAddr,
  nativeBalance
} from "@okcontract/multichain";
import type { LocalSubscriber } from "@scv/cache";
import { convertToAddressesAndRationals } from "@scv/cache";

import { retrieveAddress } from "./address";
import { findObjectValue } from "./cellObject";
import type { OKCore } from "./coreExecution";
import type { TokenQueryOrAddress } from "./types";

export const ERC20 = "20";

export const balance_of = "balanceOf";
export const approve_method = "approve";
export const approve_method_signature =
  "function approve(address _spender, uint256 _value) public returns (bool success)";

export const erc20 = [
  // Read-Only Functions
  "function name() public view returns (string)",
  "function symbol() public view returns (string)",
  "function decimals() public view returns (uint8)",
  "function totalSupply() public view returns (uint256)",
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  // Write Functions
  "function transfer(address _to, uint256 _value) public returns (bool success)",
  "function transferFrom(address _from, address _to, uint256 _value) public returns (bool success)",
  "function approve(address _spender, uint256 _value) public returns (bool success)"
];

export const erc20AbiEVM = parseAbi(erc20);
export const erc20AbiStarknet = [
  {
    name: "openzeppelin::token::erc20::interface::IERC20CamelOnly",
    type: "interface",
    items: [
      {
        name: "totalSupply",
        type: "function",
        inputs: [],
        outputs: [
          {
            type: "core::integer::u256"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "balanceOf",
        type: "function",
        inputs: [
          {
            name: "account",
            type: "core::starknet::contract_address::ContractAddress"
          }
        ],
        outputs: [
          {
            type: "core::integer::u256"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "transferFrom",
        type: "function",
        inputs: [
          {
            name: "sender",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "recipient",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "amount",
            type: "core::integer::u256"
          }
        ],
        outputs: [
          {
            type: "core::bool"
          }
        ],
        state_mutability: "external"
      }
    ]
  },
  {
    name: "openzeppelin::token::erc20::interface::IERC20",
    type: "interface",
    items: [
      {
        name: "name",
        type: "function",
        inputs: [],
        outputs: [
          {
            type: "core::felt252"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "symbol",
        type: "function",
        inputs: [],
        outputs: [
          {
            type: "core::felt252"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "decimals",
        type: "function",
        inputs: [],
        outputs: [
          {
            type: "core::integer::u8"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "total_supply",
        type: "function",
        inputs: [],
        outputs: [
          {
            type: "core::integer::u256"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "balance_of",
        type: "function",
        inputs: [
          {
            name: "account",
            type: "core::starknet::contract_address::ContractAddress"
          }
        ],
        outputs: [
          {
            type: "core::integer::u256"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "allowance",
        type: "function",
        inputs: [
          {
            name: "owner",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "spender",
            type: "core::starknet::contract_address::ContractAddress"
          }
        ],
        outputs: [
          {
            type: "core::integer::u256"
          }
        ],
        state_mutability: "view"
      },
      {
        name: "transfer",
        type: "function",
        inputs: [
          {
            name: "recipient",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "amount",
            type: "core::integer::u256"
          }
        ],
        outputs: [
          {
            type: "core::bool"
          }
        ],
        state_mutability: "external"
      },
      {
        name: "transfer_from",
        type: "function",
        inputs: [
          {
            name: "sender",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "recipient",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "amount",
            type: "core::integer::u256"
          }
        ],
        outputs: [
          {
            type: "core::bool"
          }
        ],
        state_mutability: "external"
      },
      {
        name: "approve",
        type: "function",
        inputs: [
          {
            name: "spender",
            type: "core::starknet::contract_address::ContractAddress"
          },
          {
            name: "amount",
            type: "core::integer::u256"
          }
        ],
        outputs: [
          {
            type: "core::bool"
          }
        ],
        state_mutability: "external"
      }
    ]
  }
];

const approveSelector = toFunctionSelector("approve(address,uint256)");

/**
 * generated abix from any contract that is erc20.
 * @param chain
 * @param addr
 * @param tcd
 * @returns
 * @todo move is_erc20 check here
 * @todo extend to other interfaces (erc721, etc.)
 */
export const abix_erc20 = (abi: ABI): ABIExtra | null => {
  const approve = getAbiItem({
    abi: abi?.parsed || [],
    name: approveSelector
  }) as AbiFunction;
  if (!approve) return null;
  const spender = approve.inputs[0]?.name || "spender";
  const value = approve.inputs[1]?.name || "value";
  return {
    id: `gen:${abi.addr.chain}:${abi.addr.addr}`,
    name: "Generated ABIX",
    values: {
      // @todo _owner address book (could be overridden)
      [spender]: { ty: "contract" }, // @todo could be overridden
      [value]: { a: `$address("${abi.addr.addr.toString()}")`, ty: "number" }
    },
    methods: {}
  };
};

export type TokenContractData = {
  decimals: AnyCell<number>;
  symbol: AnyCell<string>;
};

const BALANCE_VALIDITY = 60; // seconds
export const valid_timestamp = (ts: number) =>
  Math.floor(Date.now() / 1000) - ts < BALANCE_VALIDITY;

/**
 * get_allowance retrieves an allowance using the contract cache (shared with balance).
 * @param ch
 * @param addr
 * @param spender
 * @returns
 */
export const getAllowanceForWallet = <N extends Network>(
  core: OKCore,
  local: LocalRPCSubscriber,
  wallet: AnyCell<Address<N> | "none">,
  addr: AnyCell<ChainAddress>,
  spender: AnyCell<Address<N>>
): AnyCell<bigint | null> => {
  const proxy = local._proxy;
  const args = proxy.map(
    [wallet, spender],
    (_wallet, _spender) =>
      // (isAddress(_wallet.toString()) &&
      (_wallet !== "none" && [proxy.new(_wallet), proxy.new(_spender)]) || [],
    "allowance.args"
  );

  return local.call(
    addr,
    core.DefaultContracts.erc20AbiEVM,
    proxy.new("allowance", "'allowance'"),
    args
  ) as unknown as MapCell<bigint, false>;
};

// @todo use from @scv/utils
const regexpSignificant = /^[0]*(\d*[1-9])0*$/;
const numFormat = (x: string) =>
  new Intl.NumberFormat("en-US").format(Number.parseFloat(x));

/**
 * formatBig is an alternative to formatUnits from ethers.
 * @param v
 * @param keep significant digits to keep
 * @see https://docs.ethers.io/v5/api/utils/display-logic/
 * @todo duplicate in uic
 */
export const formatBig = (
  v: bigint | number | string | null,
  dec: number | bigint,
  keep = 3
) => {
  if ((typeof v === "bigint" || typeof v === "number") && v < 0)
    return `-${formatBig(-v, dec, keep)}`;
  if (v === undefined || v === null) return "-";
  const str = v.toString();
  if (str === "0") return "0";

  const magnitude =
    str.length - Number(dec) - (str.length > Number(dec) ? 0 : 1);
  // @ts-ignore possibly null
  const significant = regexpSignificant.exec(str)[1];
  // @ts-ignore possibly null
  const kept = regexpSignificant.exec(significant.substring(0, keep))[1];
  // console.log({ kept, magnitude, str, significant });
  return significant.length < magnitude
    ? numFormat(significant + "0".repeat(magnitude - significant.length))
    : magnitude > 0
      ? magnitude >= keep
        ? numFormat(str.substring(0, magnitude))
        : numFormat(
            `${kept.substring(0, magnitude)}.${kept.substring(magnitude)}`
          )
      : `0.${"0".repeat(-magnitude - 1)}${kept}`;
};

/**
 * formatAmount returns a string representation of an amount for a given token.
 * @param v value
 * @param tok token (as hex address or "tok:" query)
 * @returns
 * @todo maybe Lambdascript evaluation should automatically enrich
 *       token queries, addresses, etc. to OKToken objects which
 *       stdlib functions such as `formatAmount` could use directly
 */
export const formatAmount = (
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  v: AnyCell<number | string | bigint | Rational>,
  tok: AnyCell<TokenQueryOrAddress>,
  chain: AnyCell<ChainType>,
  showSymbol: AnyCell<boolean> = proxy.new(true)
) =>
  proxy.map([v, tok, chain, showSymbol], (_v, _tok, _chain, _show) =>
    !_tok
      ? rpc._nullCell
      : aux_amount(core, proxy, rpc, local, v, tok, chain, showSymbol)
  );

const aux_amount = <T extends TokenQueryOrAddress>(
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  v: AnyCell<number | string | bigint | Rational>,
  tok: AnyCell<T>,
  chain: AnyCell<ChainType>,
  show_symbol: AnyCell<boolean>
) => {
  // @todo maybe retrieveAddress should directly return ChainAddress
  const addr = retrieveAddress(proxy, local, chain, tok);
  const evm = proxy.map(
    [addr, chain],
    (_addr, _ch) =>
      ({
        addr: _addr,
        chain: _ch,
        ty: ContractType
      }) as ChainAddress
  );

  const decimals = getDecimals(local, core, proxy, rpc, evm);
  const symbol = getSymbol(local, core, proxy, rpc, evm);
  return proxy.map(
    [v, decimals, symbol, show_symbol],
    (_v, _dec, _symb, _show) =>
      `${formatBig(
        _v instanceof Rational ? _v.toBigInt() : _v || 0,
        _dec?.toBigInt() || 0n
      )} ${_show ? (_symb || "??").toUpperCase() : ""}`
  );
};

/**
 * raw_balance
 * @param ch
 * @param tok (use nativeAddr for native chain balances)
 * @returns
 */
export const raw_balance = (
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  wallet: AnyCell<Address<Network>>,
  ch: AnyCell<ChainType>,
  tok: AnyCell<TokenQueryOrAddress>
): AnyCell<[Rational, Rational, string]> => {
  const addr = retrieveAddress(proxy, local, ch, tok);
  const evm = proxy.map(
    [addr, ch],
    (_addr, _ch) =>
      ({
        addr: _addr,
        chain: _ch,
        ty: ContractType
      }) as ChainAddress
  );
  const decimals = getDecimals(local, core, proxy, rpc, evm);
  const balance = getBalance(core, proxy, rpc, evm, wallet);
  const symbol = getSymbol(local, core, proxy, rpc, evm);
  return proxy.map(
    [balance, decimals, symbol],
    (_balance, _decimals, _symbol) => [
      new Rational(_balance),
      _decimals,
      _symbol
    ]
  );
};

/**
 * formatBalance returns the formatted balance for the current user.
 * @param ch chain
 * @param addr token address
 * @returns
 */
export const formatBalance = (
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  local: LocalSubscriber<CacheQuery>,
  wallet: AnyCell<Address<Network>>,
  ch: AnyCell<ChainType>,
  tok: AnyCell<TokenQueryOrAddress>,
  show_symbol = true
) => {
  const balData = raw_balance(core, proxy, rpc, local, wallet, ch, tok);
  return balData.map(
    (_data) =>
      `${formatBig(_data[0].toBigInt(), _data[1].toBigInt())} ${
        show_symbol ? _data[2].toUpperCase() : ""
      }`
  );
};

export const getDecimals = <N extends Network>(
  local: LocalSubscriber<CacheQuery>,
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  addr: AnyCell<ChainAddress<N>>
): MapCell<Rational, false> => {
  const decimals = proxy.new("decimals", "decimals");
  const args = proxy.new([]);
  return addr.map((_addr) => {
    if (!_addr?.addr) return rpc._nullCell;
    if (_addr.addr.isNative() && isEVMAddr(_addr)) {
      const chain = addr.map((_addr) =>
        findObjectValue(proxy, core.Chains, (v) => v.id === _addr.chain)
      ) as AnyCell<Chain>;
      const tokenQuery = chain.map((_chain) => _chain.currency);
      const token = local.unwrappedCell(tokenQuery);

      return token.map((_token) => {
        // @todo consider 18 by default?
        return (_token?.decimals as unknown as Rational) || new Rational(18);
      });
    }

    return rpc
      .call<AnyCell<unknown>[], Network>(
        addr,
        isEVMAddr(_addr)
          ? core.DefaultContracts.erc20AbiEVM
          : core.DefaultContracts.erc20AbiStarknet,
        decimals,
        args,
        proxy.new({
          name: "erc20.getDecimals",
          convertFromNative: convertToAddressesAndRationals
        })
      )
      .map((_decimals) =>
        isEVMAddr(_addr) ? _decimals : _decimals[0]
      ) as unknown as MapCell<Rational, false>;
  });
};

export const getBalance = <N extends Network>(
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  addr: AnyCell<ChainAddress<N>>,
  owner: AnyCell<Address<N> | null>
): MapCell<bigint | null, false> =>
  addr.map((_addr) => {
    if (_addr === null || !_addr?.addr) return rpc._nullCell;
    // console.log("getBalance", { _addr });
    if (_addr.addr.isNative() && isEVMAddr(_addr)) {
      const chain = addr.map((_addr) => _addr.chain);
      return nativeBalance(
        proxy,
        rpc,
        chain,
        // @todo owner should be EVM too...
        owner as AnyCell<Address<EVMType>>
      );
    }
    return balanceOf(
      proxy,
      rpc,
      addr,
      isEVMAddr(_addr)
        ? core.DefaultContracts.erc20AbiEVM
        : core.DefaultContracts.erc20AbiStarknet,
      owner.map(
        (_owner) => (_owner && [proxy.new(_owner, "balanceOf.owner")]) || [],
        "balanceOf.args"
      )
    ).map((balance) => (isEVMAddr(_addr) ? balance : balance[0]));
  }, "getBalance");

export const getSymbol = <N extends Network>(
  local: LocalSubscriber<CacheQuery>,
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  addr: AnyCell<ChainAddress<N>>,
  _where?: string
) => {
  const symbol = proxy.new("symbol", "'symbol'");
  const args = proxy.new<[]>([], "[]");
  return addr.map(async (_addr) => {
    if (!_addr?.addr) return rpc._nullCell;
    // console.log("getSymbol", { _addr, where });
    if (_addr.addr.isNative() && isEVMAddr(_addr)) {
      const chain = addr.map((_addr) =>
        findObjectValue(proxy, core.Chains, (v) => v.id === _addr.chain)
      ) as AnyCell<Chain>;
      const tokenQuery = chain.map((_chain) => _chain.currency);
      const token = local.unwrappedCell(tokenQuery);
      return token.map((_token) => _token.symbol || null);
    }

    return rpc
      .call<[AnyCell<Address<N>>] | [], Network>(
        addr,
        isEVMAddr(_addr)
          ? core.DefaultContracts.erc20AbiEVM
          : core.DefaultContracts.erc20AbiStarknet,
        symbol,
        args,
        proxy.new({
          name: "rpc.getSymbol",
          noFail: true,
          convertFromNative: (v) => bytesToString(toBytes(v as bigint))
        })
      )
      .map((_symbol) =>
        isEVMAddr(_addr) ? _symbol : _symbol[0]
      ) as unknown as MapCell<string, false>;
  }, "getSymbol");
};

export const getName = <N extends Network>(
  local: LocalSubscriber<CacheQuery>,
  core: OKCore,
  proxy: SheetProxy,
  rpc: LocalRPCSubscriber,
  addr: AnyCell<ChainAddress<N>>
) => {
  const name = proxy.new("name", "'name'");
  const args = proxy.new([]);
  return addr.map(async (_addr) => {
    if (!_addr?.addr) return rpc._nullCell;
    if (_addr.addr.isNative() && isEVMAddr(_addr)) {
      const chain = addr.map((_addr) =>
        findObjectValue(proxy, core.Chains, (v) => v.id === _addr.chain)
      ) as AnyCell<Chain>;
      const tokenQuery = chain.map((_chain) => _chain.currency);
      const token = local.unwrappedCell(tokenQuery);
      return token.map((_token) => _token.name || null);
    }

    return rpc
      .call<AnyCell<unknown>[], Network>(
        addr,
        isEVMAddr(_addr)
          ? core.DefaultContracts.erc20AbiEVM
          : core.DefaultContracts.erc20AbiStarknet,
        name,
        args,
        proxy.new({
          name: "erc20.getName",
          convertFromNative: (v) => bytesToString(toBytes(v as bigint))
        })
      )
      .map((_name) =>
        isEVMAddr(_addr) ? _name : _name[0]
      ) as unknown as MapCell<string, false>;
  });
};
