import { Rational } from "@okcontract/lambdascript";
import {
  type Address,
  type Network,
  NewAddress,
  isAddress,
  isStringAddress
} from "@okcontract/multichain";

type ConvertToNative<T> = T extends Array<infer R>
  ? ConvertToNative<R>[]
  : T extends Address<Network>
    ? string
    : T extends Rational
      ? bigint
      : T extends object
        ? { [K in keyof T]: ConvertToNative<T[K]> }
        : T;

export const convertToNativeAddressesAndBigInt = <T>(
  v: T
): ConvertToNative<T> => {
  const aux = (v: unknown): unknown =>
    Array.isArray(v)
      ? v.map((_v) => convertToNativeAddressesAndBigInt(_v))
      : isAddress(v)
        ? v.toString()
        : v instanceof Rational
          ? v.toBigInt()
          : typeof v === "object" && v !== null
            ? Object.fromEntries(
                Object.entries(v).map(([k, _v]) => [
                  k,
                  convertToNativeAddressesAndBigInt(_v)
                ])
              )
            : v;
  return aux(v) as ConvertToNative<T>;
};

/**
 * convertToAddressesAndRationals converts all addresses in datacache to native Address objects.
 * @param obj
 * @returns
 * @todo @security we should verify the signature and only convert the addresses
 * if signature is verified
 */
type ConvertToAddressesAndRationals<T> = T extends (infer R)[]
  ? ConvertToAddressesAndRationals<R>[]
  : T extends Address<Network> | Rational
    ? T
    : T extends string
      ? Address<Network> | string
      : T extends number | bigint
        ? Rational
        : T extends object
          ? { [K in keyof T]: ConvertToAddressesAndRationals<T[K]> }
          : T;

export const isNumeric = (str) => /^\d+$/.test(str);

export const convertToAddressesAndRationals = <T>(
  obj: T
): ConvertToAddressesAndRationals<T> => {
  const aux = (v: unknown): unknown =>
    Array.isArray(v)
      ? v.map((vv) => aux(vv))
      : isAddress(v) || v instanceof Rational
        ? v
        : typeof v === "string" && isStringAddress(v)
          ? NewAddress(v)
          : typeof v === "bigint" ||
              typeof v === "number" ||
              (typeof v === "string" && v.length > 8 && isNumeric(v))
            ? new Rational(v)
            : typeof v === "object" && v !== null
              ? Object.fromEntries(
                  Object.entries(v).map(([k, vv]) => [k, aux(vv)])
                )
              : v;
  return aux(obj) as ConvertToAddressesAndRationals<T>;
};
