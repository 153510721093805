<script lang="ts">
  import * as Config from "../../config.json";
  const DEV = Config.dev;

  import type { AnyCell } from "@okcontract/cells";
  import { AlertBanner, Icon } from "@okcontract/uic";

  export let cell: AnyCell<unknown>;
  export let where: string = undefined;

  const rewrite = (msg: unknown) =>
    msg
      .toString()
      .replaceAll("Error: ", "")
      .replace("no response", "no RPC response");

  const rePrefix = (prefix: string) => new RegExp(`^${prefix}`);

  const rewriteMsg = (msg: unknown) =>
    rewrite(msg)
      .replaceAll("has an error: ", "")
      .replace(rePrefix("Cell "), "");

  let isOpen = false;
</script>

{#if $cell instanceof Error}
  <AlertBanner style="warning">
    <div class="break-all">
      {#if where}
        <div class="badge badge-neutral badge-outline">{where}</div>
      {/if}
      {#if "reason" in $cell && "source" in $cell}
        <div>
          {#if DEV}
            <b>Cell {$cell.source}</b>:
          {/if}
          {rewrite($cell.reason)}
        </div>

        {#if "message" in $cell}
          <button
            class="flex items-center btn btn-xs btn-ghost"
            title={`Details`}
            on:click={() => {
              isOpen = !isOpen;
            }}
          >
            <Icon name="chevron-d" size="xs" opacity="70" />
          </button>
          {#if isOpen}
            <div class="z-20 flex gap-1 pt-1 items-center text-sm">
              {rewriteMsg($cell.message)}
            </div>
          {/if}
        {/if}
      {:else}
        <b>Cell {cell.id}</b>: {rewrite($cell.message)}
      {/if}
    </div>
  </AlertBanner>
{/if}
