import {
  type EditorMode,
  type EditorNode,
  objectDefinition
} from "@okcontract/fred";
import type { Environment } from "@okcontract/lambdascript";

import { submitter } from "./data-user";
import type { OKPage } from "./instance";

export const pageSchema =
  (instance: OKPage, mode?: EditorMode) =>
  (node?: EditorNode, env?: Environment) =>
    objectDefinition(
      instance.proxy,
      {
        from: submitter(),
        // @todo ensure ID is node-generated
        id: {
          label: "Unique page ID",
          locked: true,
          base: "string",
          gr: "id"
        },
        t: {
          label: "Title",
          base: "string",
          gr: "id",
          pl: "Compound Essentials",
          hint: "Could be linked to your organization name or anything else"
          // optional: true
        },
        act: {
          label: "Active page",
          base: "boolean",
          gr: "id"
        },
        txt: {
          label: "Introduction Text",
          hint: "A description of your page",
          pl: "A description of your page...",
          base: "string",
          long: true,
          gr: "id",
          optional: true,
          icon: "bars-left-o"
        },
        l: {
          label: "Interactions",
          hint: "List of described interactions in the page",
          array: () =>
            objectDefinition(
              instance.proxy,
              {
                wid: {
                  label: "Interaction ID",
                  base: "string",
                  search: "widget"
                },
                dsc: {
                  label: "Description",
                  base: "string"
                  // optional: true
                }
              },
              "Interactions",
              { min: 1 }
            ),
          // @todo fix second schema validation on array parent which is not required
          gr: "int",
          icon: "users-o"
        }
      },
      "Page"
    );
