<script lang="ts">
  import { Cell, ValueCell, type AnyCell } from "@okcontract/cells";
  import {
    isContract,
    isToken,
    is_contract_addr,
    link_of_cache_query,
    myWallet
  } from "@okcontract/coredata";
  import type { DataEditor, EditorNode } from "@okcontract/fred";
  import { Rational } from "@okcontract/lambdascript";
  import {
    isAddress,
    nullAddr,
    type Address,
    type Network
  } from "@okcontract/multichain";
  import {
    EnvKeyChain,
    EnvKeySelf,
    isKnownAddress,
    type OKPage
  } from "@okcontract/sdk";
  import { WalletAddress } from "@okcontract/svelte-sdk";
  import { Calendar, Checkbox, Loading, getTheme } from "@okcontract/uic";
  import DataCacheSnippet from "@scv/dcuic/DataCacheSnippet.svelte";
  import ResultSnippet from "@scv/dcuic/ResultSnippet.svelte";

  import { type EditorOptions } from "../types";
  import AddressInput from "./AddressInput.svelte";
  import AdvancedInputEditor from "./AdvancedInputEditor.svelte";
  import EditorNumber from "./EditorNumber.svelte";
  import ObjectViewUrl from "./ObjectViewURL.svelte";
  import ValidInput from "./ValidInput.svelte";

  export let editor: DataEditor;
  const env = editor?.env;

  const self = env?.value(EnvKeySelf) as AnyCell<Address<Network>> | undefined;
  const chain = env?.value(EnvKeyChain) as
    | AnyCell<string, boolean, true>
    | undefined;

  // @todo remove
  export let instance: OKPage;
  const proxy = instance.proxy;

  export let node: EditorNode;
  const definition = node.definition;
  const value = proxy.get(node.value);
  const valid = node.valid;

  export let options: EditorOptions = {};
  $: placeholder =
    ($definition && "pl" in $definition && $definition.pl) || null;
  $: disabled =
    !!options?.view ||
    !(value instanceof ValueCell) ||
    ("locked" in $definition && $definition?.locked);

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  // @todo move to `@okcontract/fred`
  const type = proxy.mapNoPrevious(
    [value, definition],
    (v, def) => {
      console.log({ v, def });
      if (def) {
        return "dict" in def
          ? "dict"
          : "object" in def
            ? "object"
            : "array" in def
              ? "array"
              : "enum" in def
                ? "enum"
                : "isAddress" in def && def.isAddress
                  ? "address"
                  : "base" in def
                    ? def.base
                    : null;
      }
      return v instanceof Rational
        ? "rational"
        : isAddress(v)
          ? "address"
          : v instanceof Date
            ? "date"
            : Array.isArray(v)
              ? "array"
              : typeof v;
    },
    "EL:type"
  );

  const isEmbedded = value.map(
    (_uq) =>
      typeof _uq === "string" && _uq.startsWith("data:application/json;"),
    "isEmbedded"
  );
  const isURL = value.map(
    (_uq) =>
      (typeof _uq === "string" && _uq?.startsWith("https://ipfs.io/")) ||
      (typeof _uq === "string" && _uq?.startsWith("https://")),
    "isURL"
  );
  const isIPFS = value.map(
    (_uq) => typeof _uq === "string" && _uq?.startsWith("ipfs://"),
    "isIPFS"
  );

  const onInput = (ev: Event) =>
    editor.update(node, (ev.target as HTMLInputElement).value);

  // $: console.log("LEAF=>", $definition, { node, $type, $value });
</script>

<!-- <div class={options?.view && "cssView" in $definition
    ? $definition.cssView
    : "css" in $definition
      ? $definition.css
      : ""}> -->

<!-- {#if !(value instanceof ValueCell)}
  MAP:{value.id}
{/if} -->
{#if $type instanceof Error}
  Error in type: {$type}
{:else if $definition instanceof Error}
  Error in definition: {$definition}
{:else if $definition === undefined}
  <Loading />
{:else if $type === "string"}
  {#if "isBinary" in $definition && $definition?.isBinary}
    {$definition.label}
    <ValidInput
      {value}
      {placeholder}
      pattern={/0x[0-9a-fA-F]*/}
      {valid}
      {disabled}
      on:input={onInput}
    />
  {:else if ("isImg" in $definition && $definition.isImg) || ("isExpr" in $definition && $definition.isExpr) || ("search" in $definition && $definition.search)}
    <AdvancedInputEditor
      {instance}
      {editor}
      {node}
      {options}
      {disabled}
      on:update={(ev) => editor.update(node, ev.detail)}
    />
  {:else if "long" in $definition && $definition.long && typeof $value === "string"}
    {#if options?.view}
      <div class="px-1 font-medium">
        {$definition.long?.[$value] || $value}
      </div>
    {:else}
      <textarea
        value={$value}
        {disabled}
        on:input={onInput}
        {placeholder}
        class="textarea textarea-bordered min-h-48 w-full {theme.dark(
          $compiledTheme,
          'input-white-alpha',
          'input-black-alpha',
          ''
        )}"
      />
    {/if}
  {:else if "isColor" in $definition && $definition.isColor}
    {#if options?.view}
      <input
        {disabled}
        type="color"
        value={$value}
        class="bg-transparent w-10"
        on:input={onInput}
      />
    {:else}
      <label
        class="input input-bordered flex items-center {disabled
          ? 'input-disabled'
          : ''} {theme.dark(
          $compiledTheme,
          'input-white-alpha',
          'input-black-alpha',
          ''
        )}"
      >
        <input
          {disabled}
          type="color"
          value={$value}
          class="bg-transparent w-10"
          on:input={onInput}
        />
      </label>
    {/if}
  {:else if "locked" in $definition && $definition?.locked}
    <div class="flex-none break-all font-medium {$definition.css || ''}">
      {$value}
    </div>
  {:else if options?.view}
    {#if $isURL || $isIPFS || $isEmbedded}
      <ObjectViewUrl
        {instance}
        url={value}
        type={instance.proxy.map(
          [isEmbedded, isURL, isIPFS],
          (_isEmbedded, _isURL, _isIPFS) =>
            _isEmbedded ? "embedded" : _isURL ? "url" : _isIPFS ? "ipfs" : null
        )}
      ></ObjectViewUrl>
    {:else}
      <div class="px-1 font-medium">
        {$value}
      </div>
    {/if}
  {:else}
    <ValidInput
      {value}
      {disabled}
      {placeholder}
      pattern={$definition && "pattern" in $definition
        ? $definition.pattern
        : undefined}
      {valid}
      on:input={onInput}
    />
  {/if}
{:else if $type === "enum" && "enum" in $definition}
  <!-- @todo uic/select with Cell -->
  {#if options?.view}
    <div class="px-1 font-medium">
      {$definition.enum?.[$value] || $value}
    </div>
  {:else}
    <select
      {disabled}
      value={$value || $definition?.default}
      on:input={onInput}
      class="select select-bordered select-md {theme.dark(
        $compiledTheme,
        'input-white-alpha',
        'input-black-alpha',
        ''
      )}"
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      <!-- @todo if enum is an object display the object -->
      {#if Array.isArray($definition.enum)}
        {#each $definition.enum as v}
          <!-- @todo reactive... -->
          <option value={v instanceof Cell ? v.value : v}>
            {v instanceof Cell ? v.value : v}
            <!-- @todo custom renderers -->
            <!--    {#if "renderer" in $definition}
              {v}
            {:else}
              <Titles {instance} item={v} />
            {/if} -->
          </option>
        {/each}
      {:else}
        {#each Object.entries($definition.enum) as [k, v]}
          <option value={k}>
            {(v && typeof v === "object" && v?.label) || v}
          </option>
        {/each}
      {/if}
    </select>
  {/if}
{:else if $type === "address"}
  {#if options?.view && isAddress($value)}
    {#await isKnownAddress(instance.core, $value.toString(), $chain)}
      ...
    {:then cacheQuery}
      {#if isContract(cacheQuery) || isToken(cacheQuery)}
        <ResultSnippet
          link={link_of_cache_query(cacheQuery)}
          style="default"
          size="xs"
        >
          <DataCacheSnippet dq={cacheQuery} chain={$chain} displayType={true} />
        </ResultSnippet>
      {:else}
        <!-- @todo deprecated  -->
        {#await is_contract_addr($value?.toString(), $chain) then isContract}
          {@const ty = isContract ? "c" : "w"}
          <div class="flex items-center px-1">
            <WalletAddress
              {instance}
              address={{ addr: $value, chain: $chain, ty }}
              link={true}
            />
          </div>
        {/await}
      {/if}
    {/await}
  {:else}
    {@const disableOneClick =
      "disableOneClick" in $definition && $definition.disableOneClick}
    <AddressInput
      {instance}
      isValid={true}
      {placeholder}
      search={"search" in $definition ? $definition?.search : null}
      input={$value?.toString()}
      {disabled}
      oneClick={disableOneClick || !self || !$self || $self instanceof Error
        ? undefined
        : [myWallet, $self.toString()]}
      on:update={(ev) => {
        $definition instanceof Error
          ? undefined
          : editor.update(node, ev.detail === "0x0" ? nullAddr : ev.detail);
      }}
      removable
    />
  {/if}
  <!-- 
  	@todo restore walletID from `editor.env`
  oneClick={("disableOneClick" in $definition &&
      $definition.disableOneClick) ||
    $walletID instanceof Error
      ? undefined
      : [myWallet, $walletID.toString()]} -->
{:else if $type === "number" || $type === "bigint" || $type === "rational"}
  <EditorNumber {instance} {node} {editor} {options} />
{:else if $type === "boolean"}
  {#if disabled}
    {$value}
  {:else}
    <Checkbox
      {disabled}
      value={$value}
      label=""
      on:toggle={(ev) => editor.update(node, ev.detail)}
    />
  {/if}
{:else if $type === "date"}
  <Calendar
    {disabled}
    time={$value}
    dayOnly={true}
    on:update={(ev) => editor.update(node, ev.detail.date)}
  />
  <!-- @todo restore -->
{/if}
<!-- </div> -->
