import type { AnyCell } from "@okcontract/cells";

import * as Config from "../../config.json";

const DEBUG_SDK = Config?.debug?.http;

/**
 * Authenticated client.
 * @todo default prefix URL?
 */
export class AuthClient {
  token: AnyCell<string>;

  constructor(token: AnyCell<string>) {
    this.token = token;
  }

  private _token() {
    return this.token.get();
  }

  /**
   * makeRequest performs an authenticated request to the API.
   * @param path
   * @param method GET by default
   * @returns
   */
  private makeRequest = async (
    url: string,
    method: "GET" | "POST" | "DELETE" = "GET"
  ) => {
    const token = await this._token();
    DEBUG_SDK && console.log("http", method, url, token);

    const r = await fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      },
      method
    });
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    return r;
  };

  /**
   * getJSON gets data from authenticated route, returns a JSON response.
   * @param path path to query (without domain)
   */
  public getJSON = async (url: string) => {
    const r = await this.makeRequest(url);
    return r?.json();
  };

  /**
   * deleteJSON performs a DELETE query on given path and returns a JSON response.
   * @param path
   */
  public deleteJSON = async (url: string) => {
    const r = await this.makeRequest(url, "DELETE");
    return r?.json();
  };

  /**
   * postJSON posts JSON data to authenticated route, returns a JSON response.
   * @param path path to query (without domain)
   * @param data data sent in body
   */
  public postJSON = async (url: string, data: unknown) => {
    const token = await this._token();
    DEBUG_SDK && console.log("http POST:", url, "- data:", data, token);
    const r = await fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      },
      method: "POST",
      body: JSON.stringify(data)
    });
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    // return await r.json();
    return r;
  };

  /**
   * postBODY posts body data to authenticated route
   * @param path path to query (without domain)
   * @param data data sent in body
   */
  public postBODY = async (url: string, data: string) => {
    const token = await this._token();
    DEBUG_SDK && console.log("http POST:", url, "- body:", data, token);
    const r = await fetch(url, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      },
      method: "POST",
      body: data
    });
    if (!r.ok) {
      throw new Error(r.statusText);
    }
    // return await r.json();
    return r;
  };
}
