<script lang="ts">
  import {
    UpvoteQuery,
    UpvotesQuery,
    type CacheQueryUpVotable,
    type CachedData
  } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Icon, ThemeAccent, getTheme, type IconName } from "@okcontract/uic";
  import { delete_data } from "@scv/app";

  export let q: CacheQueryUpVotable;

  /**
   * customizable action names and icon.
   */
  export let action: [string, string, string, IconName] = [
    "Watch",
    "Watching",
    "watch",
    "star"
  ];
  export let style: "sm" | "md" = "md";
  export let instance = newOKPage("Upvote");

  const core = instance.core;
  const OUI = core.UserID;
  const local = instance.local;
  const proxy = instance.proxy;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const qCell = proxy.new(q, "Upvote.qCell");
  $: qCell.set(q);

  // Did the user upvote
  const qUpVoted = proxy.mapNoPrevious([OUI, qCell], (id, q) =>
    UpvoteQuery(id, q)
  );
  const upVoted = local.unwrappedCell(qUpVoted, "upVoted");
  // Total upvotes
  const qUpvotes = qCell.map((_q) => UpvotesQuery(_q));
  const upVotes = local.unwrappedCell(qUpvotes, "upVotes");

  // $: console.log({ $upVotes, $upVoted });

  $: label = `${$upVoted ? action[1] : action[0]}`;

  const updateUpvotes = (delta: 1 | -1) => async (cd: CachedData<"upvote">) => {
    const now = await core.Cache._now();
    core.Cache._add_update({
      ty: "upvotes",
      q: UpvotesQuery(q),
      data: ($upVotes instanceof Error ? 0 : +$upVotes || 0) + delta,
      at: Date.now() / 1000,
      exp: now + 60 * 1000
    } as CachedData<"upvotes">);
  };

  const upvote = (q: CacheQueryUpVotable) => async () => {
    if ($OUI instanceof Error) return;
    // @todo rename Address?
    const data = { addr: $OUI, for: q };
    return core.Write("upvote", data, { callback: updateUpvotes(+1) });
  };

  const cancel = (q: CacheQueryUpVotable) => async () => {
    if ($OUI instanceof Error) return;
    const up = UpvoteQuery($OUI, q);
    await delete_data(core, "upvote", up, updateUpvotes(-1));
  };
</script>

{#if !($upVotes instanceof Error)}
  {#if style === "sm"}
    <div class="tooltip">
      <button
        on:click|stopPropagation={$upVoted ? cancel(q) : upvote(q)}
        disabled={!$OUI}
        class="btn btn-sm {theme.dark(
          $compiledTheme,
          'btn-white-alpha',
          'btn-black-alpha',
          'btn-default'
        )} {!$OUI ? 'opacity-50' : 'opacity-100'}"
      >
        <!--style={theme.apply($compiledTheme, [ThemeText])}-->
        <span
          class={$upVoted ? "text-warning" : `text-base-content`}
          style={theme.apply($compiledTheme, [ThemeAccent])}
        >
          <Icon
            name={$upVoted ? action[3] : `${action[3]}-o`}
            size="xs"
          /></span
        >
        <span class="hidden sm:block">{$upVoted ? action[1] : action[0]}</span>
        {#if $upVotes > 0}
          <div
            class="badge badge-accent"
            style={theme.apply($compiledTheme, [ThemeAccent])}
          >
            {$upVotes ? $upVotes + " " : "0"}
          </div>
        {/if}
      </button>
    </div>
  {/if}
  {#if style === "md"}
    <button
      on:click={$upVoted ? cancel(q) : upvote(q)}
      disabled={!$OUI}
      class="btn btn-md {theme.dark(
        $compiledTheme,
        'btn-white-alpha',
        'btn-black-alpha',
        'btn-default'
      )} {!$OUI ? 'opacity-50' : 'opacity-100'} join-item"
      title={!$OUI
        ? `Connect wallet to add to your ${action[2]}`
        : `${$upVotes ? "Remove from" : "Add to"} ${action[2]}`}
    >
      <!--style={theme.apply($compiledTheme, [ThemeText])}-->
      <span
        class={$upVoted ? "text-warning" : `text-base-content`}
        style={theme.apply($compiledTheme, [ThemeAccent])}
      >
        <Icon name={$upVoted ? action[3] : `${action[3]}-o`} size="sm" /></span
      >{label}
      {#if $upVotes > 0}
        <div
          class="badge badge-accent"
          style={theme.apply($compiledTheme, [ThemeAccent])}
        >
          {$upVotes ? $upVotes + "" : "0"}
        </div>
      {/if}
    </button>
  {/if}
  <!-- {:else}
  {JSON.stringify($upVotes)} -->
{/if}
