<script lang="ts">
  import { type AnyCell } from "@okcontract/cells";
  import {
    DataEditor,
    objectDefinition,
    type TypeDefinitionFn,
    type TypeScheme
  } from "@okcontract/fred";
  import { Environment } from "@okcontract/lambdascript";
  import { type OKPage, valueToDefinition } from "@okcontract/sdk";

  import DataEditorLight from "./DataEditorLight.svelte";

  export let instance: OKPage;
  const proxy = instance.proxy;

  export let value: AnyCell<Object>;
  export let env: AnyCell<Environment> = proxy.new(new Environment(proxy));
  export let cssFn: (k: string) => string = undefined;

  // @todo we could have used the Editor env
  const object = proxy.map([value, env], (obj, _env) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [
        k,
        (() =>
          valueToDefinition(
            instance.proxy,
            v,
            env,
            _env.case(k) || k,
            cssFn ? cssFn(k) : undefined
          )) as TypeDefinitionFn
      ])
    )
  );
  const definition = objectDefinition(proxy, object, "", {
    css: "flex"
  });
  const schema = {
    types: proxy.new(instance._coredataTypeScheme()),
    values: definition,
    gs: instance.null
  } as TypeScheme;

  const editor = new DataEditor(instance.proxy, value, schema);
</script>

<DataEditorLight
  {instance}
  {editor}
  options={{ noCollapsible: true, view: true }}
/>
