import {
  generatePrivateKey,
  privateKeyToAccount,
  signMessage
} from "viem/accounts";

import { cookie } from "@scv/auth";

import { SignerSource, newSigner } from "./wallet";

const WIDGET_PRIVATE_KEY = "$priv";

/**
 * newEVMAccount creates a new EVM account and signer, storing the
 * private key in a cookie for reuse.
 */
export const newEVMAccount = async () => {
  const privateKey = cookie.init(
    WIDGET_PRIVATE_KEY,
    generatePrivateKey,
    10_000
  ) as `0x${string}`;
  // console.log({ privateKey });
  const privAccount = privateKeyToAccount(privateKey);
  return newSigner(
    privAccount.address,
    (message: string) => signMessage({ privateKey, message }),
    SignerSource.Generated
  );
};
