<script lang="ts">
  import { Icon, Theme, ThemeBackground } from "@okcontract/uic";

  import type { MapCell, ValueCell } from "@okcontract/cells";
  import type { CompiledTheme } from "@okcontract/uic/src/theme/types";
  import NoWallet from "./NoWallet.svelte";

  export let theme: Theme;
  export let compiledTheme: MapCell<CompiledTheme, true>;
  export let showNoWallet: ValueCell<boolean>;

  export let pos: boolean = false;
</script>

<div
  class="absolute top-0 bottom-0 right-0 left-0 z-30 flex flex-col p-4 rounded-box {theme.dark(
    $compiledTheme,
    'bg-black text-white',
    'bg-white text-black',
    'bg-base-100 text-base-content'
  )} {pos ? '-mt-14' : ''}"
  style={theme.apply($compiledTheme, [ThemeBackground])}
>
  <header class="mb-2 flex justify-between items-center">
    <div class="flex gap-2 items-center">
      <Icon name="wallet-o" size="sm" opacity="50" />
      <div class="text-lg font-semibold">Get a Wallet</div>
    </div>
    <button
      class="btn btn-sm btn-circle {theme.dark(
        $compiledTheme,
        'btn-white-alpha',
        'btn-black-alpha',
        'btn-ghost'
      )}"
      on:click={() => {
        showNoWallet.set(false);
      }}><Icon name={"close"} size="sm" opacity="70" /></button
    >
  </header>
  <NoWallet />
</div>
