// This file was generated by lezer-generator. You probably shouldn't edit it.
export const terminator = 91,
  Identifier = 1,
  tripleStringContent = 92,
  stringContent = 93,
  commandStringContent = 94,
  immediateParen = 95,
  immediateBracket = 96,
  immediateDoubleQuote = 97,
  immediateBackquote = 98,
  immediateDot = 99,
  SourceFile = 2,
  UnaryOperatorExtra = 5,
  PowerOperator = 7,
  TimesOperator = 8,
  PlusOperator = 9,
  _in = 10,
  isa = 11,
  ComparisonOperator = 12,
  OrOperator = 13,
  AndOperator = 14,
  _for = 43,
  _if = 46,
  BooleanValue = 48,
  Character = 49,
  NumberValue = 50,
  SymbolValue = 51,
  StringValue = 52,
  TripleString = 53,
  CommandString = 54,
  PrefixedString = 55;
