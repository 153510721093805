<script lang="ts">
  import type { AnyCell } from "@okcontract/cells";
  import { toDataURL } from "@scv/utils";

  import type { OKPage } from "@okcontract/sdk";

  import Image from "./Image.svelte";
  import { Button } from "@okcontract/uic";

  export let instance: OKPage;
  export let url: AnyCell<string>;
  export let data: AnyCell<ArrayBuffer | string>;
  export let uploading: AnyCell<boolean>;
  export let input_elt: HTMLInputElement;

  let touched = false;

  const src = instance.proxy.map([url, data], (_url, _data) =>
    _data ? toDataURL(_data) : _url ? _url : null
  );

  $: console.log("ImageUpload", { $src, $data });
</script>

<div>
  {#if $src}
    <Image
      src={$src}
      loading={$uploading instanceof Error ? false : $uploading}
      style="sm"
    />
  {:else}
    <div class="p-2">
      <Button
        icon="add"
        style="neutral"
        outline={true}
        label="Upload image"
        working={uploading}
        action={() => {
          input_elt.click();
          touched = true;
        }}
      />
    </div>
  {/if}
</div>
