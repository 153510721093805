<script lang="ts">
  import type { ChainType } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { Logo } from "@scv/dcuic";

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  export let instance = newOKPage("SwitchChains");
  export let chain: ChainType;

  const core = instance.core;
  const isSwitchingChain = core.IsSwitchingChain;
  const noWallet = core.IsNoWallet;
</script>

<div
  class="flex gap-1 items-center btn btn-sm rounded-full {theme.dark(
    $compiledTheme,
    'btn-white-alpha',
    'btn-black-alpha',
    'btn-ghost'
  )}"
>
  {#if $isSwitchingChain && $noWallet !== true}
    <span class="loading loading-spinner loading-xs"></span>
  {:else}
    <Logo logo={chain} size="sm" remote={instance.core.AppID === "widget"} />
  {/if}
  <span class="font-medium opacity-80">{chain}</span>
</div>
