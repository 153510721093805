<script lang="ts">
  import { ThemeAccent, getTheme } from "@okcontract/uic";

  import CircleIcon from "./CircleIcon.svelte";

  export let message: string;
  const theme = getTheme();
  const compiledTheme = theme?.compiled;
</script>

<div
  class="w-full flex justify-center my-4"
  style={theme.apply($compiledTheme, [ThemeAccent])}
>
  <CircleIcon name="success-o" style="success" />
</div>
<h4
  class="w-full flex justify-center {theme.dark(
    $compiledTheme,
    'text-white',
    'text-black',
    'text-base-content'
  )}"
>
  <!--style={theme.apply($compiledTheme, [ThemeText])}-->
  {message}
</h4>
