<script lang="ts">
  import type { OKWidgetPageQueryType } from "@okcontract/coredata";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { getTheme } from "@okcontract/uic";
  import { Upvote } from "@scv/dcuic";

  export let instance = newOKPage("PageSnippet");
  export let q: OKWidgetPageQueryType;
  export let small: boolean = false;
  export let upvote: boolean = false;
  export let theme = getTheme();
  const compiledTheme = theme?.compiled;

  const local = instance.local;
  const proxy = instance.proxy;

  const qCell = proxy.new<OKWidgetPageQueryType>(q, "qCell");
  $: qCell.set(q);

  const page = local.unwrappedCell(qCell, "page");
</script>

<div class="flex gap-2 items-center justify-between overflow-hidden w-full">
  {#if !($page instanceof Error)}
    <div class="flex flex-col text-base-content">
      <div
        class="flex flex-wrap max-w-sm font-semibold {small
          ? 'text-sm'
          : 'text-base'}
        {theme.dark($compiledTheme, 'text-white', 'text-black', '')}"
      >
        {$page?.t || q.replace("pg:", "")}
      </div>
      {#if $page?.txt}
        <!-- @todo only beginning, CSS effect -->
        <div class="flex flex-wrap max-w-sm opacity-70 text-sm">
          {$page.txt}
        </div>
      {/if}
    </div>
    <div class="justify-end">
      <slot name="tag" />
      {#if upvote}
        <Upvote {q} style="sm" />
      {/if}
    </div>
  {/if}
</div>
