<script lang="ts">
  import { ValueCell, type AnyCell } from "@okcontract/cells";
  import { type Chain } from "@okcontract/multichain";
  import { newOKPage } from "@okcontract/svelte-sdk";
  import { Button, getTheme, Hoverable } from "@okcontract/uic";
  import { Logo } from "@scv/dcuic";
  import { Link } from "@scv/routing";

  export let instance = newOKPage("ChainListItem");
  export let chain: AnyCell<Chain>;
  export let open: ValueCell<boolean>;
  export let small: boolean = false;

  const core = instance.core;
  const isAdmin = core.IsAdmin;

  const theme = getTheme();
  const compiledTheme = theme?.compiled;

  const switch_action = (chain: Chain) => () => {
    open.set(false);
    core.SwitchChain(chain.id);
  };
</script>

{#if $chain instanceof Error}
  Chain Error
{:else if $chain}
  <Hoverable let:hovering={hover}>
    <button
      on:click={switch_action($chain)}
      class="flex w-full flex-row items-center px-2 py-1 rounded-btn {theme.dark(
        $compiledTheme,
        'text-white hover:bg-white/10',
        'text-black hover:bg-black/5',
        'text-base-content hover:bg-black/5'
      )}"
    >
      <Logo logo={$chain.logo || $chain.id} size="sm" />
      <div class="ml-2 font-medium">
        {$chain.name}
        {#if hover && $isAdmin && !small}
          <Link to={`/edit/§${$chain.id}`}
            ><Button size="xs" label="edit" /></Link
          >
        {/if}
      </div>
    </button>
  </Hoverable>
  <!-- {:else}
Not found -->
{/if}
